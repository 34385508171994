import { ChangeEvent } from 'react';

import { useInvoiceLinesStore } from '../../../../../store/invoiceLinesStore';
import { UseInvoiceLinesStore } from '../../../../../types/invoices/invoice-lines-store';
import { SearchInvoiceLine } from '../../../../../types/invoices/invoice';
import RangeFormField from '../../../../Common/RangeFormField/RangeFormField';
import {
	isMinValid,
	isMaxValid,
} from '../../../../Common/RangeFormField/rangeValidator';
import { FilterBlock, Title } from './styles/filter.styled';

type FieldDetails = {
	id: string;
	placeholder: string;
};

type FilterProps = {
	title: string;
	from: FieldDetails;
	to: FieldDetails;
};

const Filter = ({ title, from, to }: FilterProps) => {
	const keyFrom = from.id as keyof SearchInvoiceLine;
	const keyTo = to.id as keyof SearchInvoiceLine;

	const { filters, updateNumberRange } = useInvoiceLinesStore(
		(state: UseInvoiceLinesStore) => ({
			filters: state.filters,
			updateNumberRange: state.updateNumberRange,
		})
	);

	return (
		<FilterBlock>
			<Title>{title}</Title>
			<RangeFormField
				type="from"
				id={from.id}
				value={filters[keyFrom] || ''}
				placeholder={from.placeholder}
				onChangeHandler={(event: ChangeEvent<HTMLInputElement>) =>
					updateNumberRange(keyFrom, event.target.value)
				}
				isValid={isMinValid(filters[keyFrom] || '', filters[keyTo] || '')}
			/>

			<RangeFormField
				type="to"
				id={to.id}
				value={filters[keyTo] || ''}
				placeholder={to.placeholder}
				onChangeHandler={(event: ChangeEvent<HTMLInputElement>) =>
					updateNumberRange(keyTo, event.target.value)
				}
				isValid={isMaxValid(filters[keyFrom] || '', filters[keyTo] || '')}
			/>
		</FilterBlock>
	);
};

export default Filter;
