import { Pageable } from '../types/pageable';
import { CaseSort } from '../types/sort';
import { SearchPhvCase } from '../types/cases/phv-case';
import api from '../api/api';
import { API_URL_PHV_CASES } from '../api/constants/urls';
import { PHV_CASES_FILTER } from '../components/Common/Filter/constants/case-filter-data';
import { toPageParams, toQueryParams } from './toQueryParameters';
import { stripSearch } from './stripSearch';

export const getPhvCases = (
	searchPhvCase: SearchPhvCase,
	pageable: Pageable<CaseSort>
) => {
	const strippedSearchPhvCase = stripSearch(
		searchPhvCase as Record<string, string | string[]>,
		PHV_CASES_FILTER
	);
	const searchParams = toQueryParams(strippedSearchPhvCase);
	if (searchParams === null) {
		return Promise.reject('the filter is not valid');
	}
	const pageParams = toPageParams<Pageable<CaseSort>>(pageable);
	const url = `${API_URL_PHV_CASES}?${pageParams}&${searchParams}`;
	return api.get(url);
};
