import styled, { css } from 'styled-components';

const userFormFooterStyles = () => css`
	display: flex;
	justify-content: right;
	column-gap: 1rem;
	padding-bottom: 1rem;
`;

export const UserFormFooter = styled.div`
	${userFormFooterStyles}
`;
