import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import { FormState } from '@zama/types';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { useContractorsStore } from '../../../store/contractorsStore';
import { UseContractorsStore } from '../../../types/contractors/contractor-store';
import { Contractor } from '../../../types/contractors/contractor';
import { ContractorForm } from '../common/ContractorForm/ContractorForm';
import { formatContractor } from '../utils/formatContractor';
import { editContractor } from './utils/editContractor';

export const EditContractor = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedItem, setSelectedItem] = useState<Contractor>();
	const [showError, setShowError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const contractorId = location.pathname.split('/').pop();

	const { page, setNotification } = useContractorsStore(
		(state: UseContractorsStore) => ({
			page: state.page,
			setNotification: state.setNotification,
		})
	);

	const onCancelHandler = () => navigate(-1);

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		setShowError(false);
		const { valid, elementsData } = formData as FormState;

		if (valid && contractorId) {
			const changedContractor = formatContractor(elementsData);
			editContractor(contractorId, changedContractor)
				.then((data) => {
					console.info(data);
					setNotification({
						type: 'success',
						title: 'Relatie bijgewerkt',
						description: `${changedContractor.name} is succesvol bijgewerkt`,
					});
					navigate('/relatiebeheer');
				})
				.catch(() => {
					setShowError(true);
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!page.items.length) {
			// WV: in case items were erased by page refresh
			navigate('/relatiebeheer');
		}
		setSelectedItem(page.items.find((item) => item.id === contractorId));
	}, []);

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Relatie bewerken</ContentHeader>
				{showError && (
					<Notification
						dataTest="bad-response"
						variant="error"
						title="Door technische redenen is het niet gelukt de relatie te wijzigen. Probeer het opnieuw."
					/>
				)}
				{selectedItem && (
					<ContractorForm
						selectedItem={selectedItem}
						isLoading={isLoading}
						onCancelHandler={onCancelHandler}
						onSubmitHandler={onSubmitHandler}
					/>
				)}
			</Grid.Item>
		</Grid>
	);
};
