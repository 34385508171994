import styled, { css, DefaultThemeProps } from 'styled-components';

import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import Typography from '@anwb/poncho/components/typography';
import { Input } from '@anwb/poncho/components/forms';

const formContainerStyles = () => css`
	width: 100% !important;
`;

export const FormContainer = styled.div`
	${formContainerStyles}
`;

const SearchInputContainerStyles = ({ theme }: DefaultThemeProps) => css`
	width: 100%;
	display: flex;
	flex-direction: row;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
		flex-direction: column;
	}
`;

export const SearchInputContainer = styled.div`
	${SearchInputContainerStyles}
`;

const searchInputStyles = ({ theme }: DefaultThemeProps) => css`
	margin: 0 0 1.2rem 0 !important;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
		margin: 0 0 0.8rem 0 !important;
	}
`;

export const SearchInput = styled(Input)`
	${searchInputStyles}
`;

const clearFiltersStyles = ({ theme }: DefaultThemeProps) => css`
	white-space: nowrap;
	line-height: 44px;
	margin: 0 0 1.2rem 0.8rem !important;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
		margin: 0 0 0.8rem 0 !important;
		line-height: 30px;
	}
`;

export const ClearFilters = styled(Typography).attrs({
	variant: 'link-text',
})`
	${clearFiltersStyles}
`;
