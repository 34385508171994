export const PHV_RECORD_CATEGORIES = [
	{
		code: 'ADVIES',
		label: 'Advies',
	},
	{
		code: 'ELECTIEF',
		label: 'Electief',
	},
	{
		code: 'FAMILIEOMSTANDIGHEDEN',
		label: 'Familieomstandigheden',
	},
	{
		code: 'ADMINISTRATIEF',
		label: 'Administratief',
	},
	{
		code: 'REDDING',
		label: 'Redding',
	},
	{
		code: 'OVERLIJDEN',
		label: 'Overlijden',
	},
	{
		code: 'RAMP',
		label: 'Ramp',
	},
	{
		code: 'VERLIES_DIEFSTAL',
		label: 'Verlies/diefstal',
	},
	{
		code: 'ONGEVAL',
		label: 'Ongeval',
	},
	{
		code: 'ZIEKTE',
		label: 'Ziekte',
	},
	{
		code: 'FRAUDE',
		label: 'Fraude',
	},
	{
		code: 'UITVAL_BESTUURDER',
		label: 'Uitval bestuurder',
	},
	{
		code: 'MEDISCHE_GARANTIE',
		label: 'Medische garantie',
	},
] as const;
