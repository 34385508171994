import { useEffect, useState } from 'react';

import { UseCaseActionsStore } from '../../../../../types/cases/case-actions-store';
import { Pageable } from '../../../../../types/pageable';
import { CaseActionSort } from '../../../../../types/sort';
import { CaseActionsZustandStore } from '../../../../../types/zustand';
import { AnyAction } from '../../../../../types/cases/common-case';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../../constants/notifications';
import { PAGE_SIZE } from '../../../../RelationManagement/RelationsOverview/constants/page-size';
import { filterCaseActions } from '../utils/filterCaseActions';

export const useProcessCaseActions = (
	actions: AnyAction[],
	store: CaseActionsZustandStore
) => {
	const [showSpinner, setShowSpinner] = useState(true);

	const {
		filters,
		page,
		sorting,
		notification,
		clearFilters,
		setPage,
		updateSorting,
		setNotification,
	} = store((state: UseCaseActionsStore) => ({
		filters: state.filters,
		page: state.page,
		sorting: state.sorting,
		notification: state.notification,
		clearFilters: state.clearFilters,
		setPage: state.setPage,
		updateSorting: state.updateSorting,
		setNotification: state.setNotification,
	}));

	useEffect(() => {
		setShowSpinner(true);

		const pageable: Pageable<CaseActionSort> = {
			pageNumber: page.pageNumber,
			pageSize: PAGE_SIZE,
			sort: sorting,
		};

		filterCaseActions(actions, filters, pageable)
			.then((result) => {
				setPage(result);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	}, [filters, page.pageNumber, sorting, setPage]);

	return {
		updateSorting,
		showSpinner,
		page,
		setPage,
		clearFilters,
		notification,
	};
};
