import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';

import { FilterContainer } from '../../../styles/filter.styled';
import {
	ContentHeader,
	OverviewContainer,
	TablePartContainer,
} from '../../../styles/page-layout.styled';
import { UseCasesStore } from '../../../types/cases/cases-store';
import { AnyPage, AnySort } from '../../../types/unions';
import { Pageable } from '../../../types/pageable';
import { CasesZustandStore } from '../../../types/zustand';
import { CaseSort } from '../../../types/sort';
import { SearchCase } from '../../../types/cases/common-case';
import { getPhvCases } from '../../../utils/phvCaseApi';
import { getVhvCases } from '../../../utils/vhvCaseApi';
import { PreviousQuery } from '../../../utils/previousQuery';
import { CASE_SORTINGS } from '../../../constants/case-sortings';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../constants/notifications';
import Filter from '../../Common/Filter/Filter';
import { SearchBar } from '../../Common/SearchBar/SearchBar';
import { SortBar } from '../../Common/SortBar/SortBar';
import { CustomPagination } from '../../Common/CustomPagination/CustomPagination';
import {
	PHV_CASES_FILTER,
	VHV_CASES_FILTER,
} from '../../Common/Filter/constants/case-filter-data';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { NoItemsFound } from '../../Common/NoItemsFound/NoItemsFound';
import { CasesType } from '../types/cases';
import { CasesOverviewTable } from './CasesOverviewTable/CasesOverviewTable';

const PAGE_SIZE = 20;

type CasesOverviewProps = {
	casesType: CasesType;
	store: CasesZustandStore;
};

export const previousQuery = new PreviousQuery<SearchCase, CaseSort>();

export const CasesOverview = ({ casesType, store }: CasesOverviewProps) => {
	const navigate = useNavigate();
	const [showSpinner, setShowSpinner] = useState(true);

	const {
		activeCase,
		filters,
		notification,
		clearFilters,
		setPage,
		setActiveCase,
		page,
		sorting,
		updateSorting,
		setQuery,
		setNotification,
	} = store((state: UseCasesStore) => ({
		activeCase: state.activeCase,
		filters: state.filters,
		notification: state.notification,
		clearFilters: state.clearFilters,
		setPage: state.setPage,
		setActiveCase: state.setActiveCase,
		page: state.page,
		sorting: state.sorting,
		updateSorting: state.updateSorting,
		setQuery: state.setQuery,
		setNotification: state.setNotification,
	}));

	useEffect(() => {
		activeCase && navigate(activeCase);
	}, [activeCase]);

	useEffect(() => {
		if (!previousQuery.shouldUpdate(filters, sorting, page.pageNumber)) {
			setShowSpinner(false);
			return;
		}

		const timeout = setTimeout(() => {
			previousQuery.setPrevious(filters, sorting, page.pageNumber);
			setNotification(undefined);
			setShowSpinner(true);

			const pageable: Pageable<CaseSort> = {
				pageNumber: page.pageNumber,
				pageSize: PAGE_SIZE,
				sort: sorting,
			};

			const getCases = casesType === 'phv' ? getPhvCases : getVhvCases;

			getCases(filters, pageable)
				.then((result) => {
					setPage(result);
				})
				.catch(() => {
					setNotification({
						type: 'error',
						title: ERROR_API_CALL,
						description: ERROR_API_CALL_DESCRIPTION,
					});
				})
				.finally(() => setShowSpinner(false));
		}, 750);

		return () => clearTimeout(timeout);
	}, [filters, page.pageNumber, sorting, setPage, casesType]);

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Dossiers</ContentHeader>
				<OverviewContainer>
					<FilterContainer>
						<Filter
							filterData={
								casesType === 'vhv' ? VHV_CASES_FILTER : PHV_CASES_FILTER
							}
							store={store}
							filterType="cases"
							clearFilters={clearFilters}
						/>
					</FilterContainer>
					<TablePartContainer>
						<Grid.Item>
							<SearchBar
								query={filters.query}
								setQuery={setQuery}
								searchBarType={`${casesType}-case`}
								placeHolder={
									casesType === 'phv'
										? 'Polisnummer, achternaam, land, postcode klant, casenummer en geboortedatum'
										: 'Achternaam, land, postcode klant, casenummer, kenteken en VIN'
								}
							/>

							<SortBar
								value={sorting}
								sortings={CASE_SORTINGS}
								updateSorting={updateSorting as (sorting: AnySort) => void}
							/>

							{notification && (
								<Notification
									variant={notification.type}
									title={notification.title}
								>
									{notification.description}
								</Notification>
							)}

							<SpinnerContainer
								showSpinner={showSpinner}
								message="Dossiers worden geladen"
							>
								<CasesOverviewTable
									setActiveCase={setActiveCase}
									casesType={casesType}
									page={page}
								/>
								<NoItemsFound show={!page.items.length}>
									Geen dossiers gevonden
								</NoItemsFound>
								<CustomPagination
									page={page}
									setPage={setPage as (page: AnyPage) => void}
								/>
							</SpinnerContainer>
						</Grid.Item>
					</TablePartContainer>
				</OverviewContainer>
			</Grid.Item>
		</Grid>
	);
};
