export const PHV_ASSISTANCE_CATEGORIES = [
	{
		code: 'ONGEVALS_HULPVERLENING',
		label: 'Ongevalshulpverlening',
	},
	{
		code: 'PERSONEN_HULPVERLENING',
		label: 'Personenhulpverlening',
	},
	{
		code: 'PERSONEN_HULPVERLENING_OVERIG',
		label: 'Personenhulpverlening overig',
	},
	{
		code: 'HELPDESK_DIENSTEN',
		label: 'Helpdeskdiensten',
	},
] as const;
