import FormFieldDatePicker from '@anwb/poncho/components/form-field-datepicker';
import Form from '@anwb/poncho/components/form';
import { FormChange } from '@anwb/poncho/types/types';

import { DateRangeType } from '../../../../../types/filters';
import { SearchInvoice } from '../../../../../types/invoices/invoice';
import { SearchPhvCase } from '../../../../../types/cases/phv-case';
import { SearchVhvCase } from '../../../../../types/cases/vhv-case';
import { FieldWrapper } from './styles/date-range-section.styled';

type DateRangeSectionProps = {
	filters: SearchInvoice | SearchPhvCase | SearchVhvCase;
	updateDateRange: (
		fieldName: DateRangeType,
		minOrMaxDate: Date | undefined
	) => void;
};

const DateRangeSection = ({
	filters,
	updateDateRange,
}: DateRangeSectionProps) => {
	const onChangeHandler = (event: FormChange) => {
		const { name, value: newDate } = event;
		if (name && newDate) {
			const date = new Date(newDate);
			updateDateRange(name as DateRangeType, date);
		}
	};

	const formatDate = (input: Date | undefined) =>
		input?.toISOString().substring(0, 10);

	return (
		<FieldWrapper>
			<Form>
				<FormFieldDatePicker
					reference="minDate"
					name="minDate"
					size="xl"
					label="Van"
					max={filters.maxDate}
					value={formatDate(filters.minDate)}
					showDatepickerOnFocus
					handlerChange={onChangeHandler}
					required
				/>

				<FormFieldDatePicker
					reference="maxDate"
					name="maxDate"
					size="xl"
					label="Tot"
					min={filters.minDate}
					value={formatDate(filters.maxDate)}
					showDatepickerOnFocus
					handlerChange={onChangeHandler}
					required
				/>
			</Form>
		</FieldWrapper>
	);
};

export default DateRangeSection;
