export const VHV_INCIDENT_CATEGORIES = [
	{
		code: 'PECH_MECHANISCH',
		label: 'Pech - mechanisch',
	},
	{
		code: 'PECH_MECHANISCH_SELF_INFLICTED',
		label: 'Pech - self inflicted',
	},
	{
		code: 'SCHADE_AANRIJDING',
		label: 'Schade - aanrijding',
	},
	{
		code: 'SCHADE_DIEFSTAL',
		label: 'Schade - diefstal',
	},
	{
		code: 'SCHADE_RUITBREUK',
		label: 'Schade - ruitbreuk',
	},
	{
		code: 'UITVAL_BESTUURDER',
		label: 'Uitval bestuurder',
	},
	{
		code: 'NIET_VAN_TOEPASSING',
		label: 'Niet van toepassing',
	},
] as const;
