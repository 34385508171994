import { Dispatch, SetStateAction, useEffect } from 'react';

import {
	Contractor,
	Debtor,
} from '../../../../../types/contractors/contractor';

export const useFetchDebtors = (
	setDebtors: Dispatch<SetStateAction<Debtor[]>>,
	selectedItem?: Contractor
) =>
	useEffect(() => {
		selectedItem &&
			setDebtors(
				selectedItem.debtors.map((debtor) => ({
					relationNumber: debtor.relationNumber,
					name: debtor.name || 'Onbekende debiteur',
					contractNumbers: debtor.contractNumbers,
				}))
			);
	}, []);
