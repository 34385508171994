import {
	LinkProps,
	useNavigate,
	useLocation,
	useResolvedPath,
} from 'react-router-dom';

import { StyledLink } from './styles/menu-item.styled';

const MenuItem = ({ children, to }: LinkProps) => {
	const resolved = useResolvedPath(to);
	const location = useLocation();

	const match =
		(location.pathname === '/' && resolved.pathname === '/') ||
		(resolved.pathname !== '/' &&
			location.pathname.includes(resolved.pathname));

	const navigate = useNavigate();

	return (
		<StyledLink $isActive={match} onClick={() => navigate(to)}>
			{children}
		</StyledLink>
	);
};

export default MenuItem;
