import { jwtDecode } from 'jwt-decode';

import { CognitoRole, DecodedJwt, Tokens } from '../types/auth';
import { useCurrentUserStore } from '../../store/currentUserStore';
import { getUser } from '../../utils/userService';

export const useAllowAccess = () => {
	const { setCurrentUser } = useCurrentUserStore((state) => ({
		setCurrentUser: state.setCurrentUser,
	}));

	const getAllowedGroups = () => {
		const storedAccessToken = localStorage.getItem(Tokens.AccessToken);
		if (storedAccessToken) {
			try {
				const decodedToken: DecodedJwt = jwtDecode(storedAccessToken);
				if (decodedToken && decodedToken['cognito:groups'])
					getUser(decodedToken.sub!).then((resp) => setCurrentUser(resp.data));
				return decodedToken['cognito:groups'];
			} catch (error) {
				console.error(error);
			}
		}
		console.warn('Could not retrieve user rights from stored token...');
		return [];
	};

	const allowedGroups = getAllowedGroups();

	return (group: CognitoRole) => allowedGroups.indexOf(group) !== -1;
};
