const AmountRegExp =
	/^([+-]?)(0|[1-9][0-9]{0,2})([,.]{0,1}[0-9]{3})*(([,.])([0-9]{1,2}|-))?$/;

const HAS_DECIMALS_REGEX = /[,.]([0-9][1-9]|[1-9][0-9]|[1-9])$/;

const parse = (amount: string) =>
	parseInt(amount.replace(/[,.](-|\d\d)$/, '').replace(/[.,]/g, ''), 10);

export const parseAmount = (amount: string | undefined, max?: boolean) => {
	if (!amount || amount.length === 0 || !AmountRegExp.test(amount.trim())) {
		return NaN;
	}

	if (HAS_DECIMALS_REGEX.test(amount) && max) {
		return parse(amount) + 1;
	}

	return parse(amount);
};
