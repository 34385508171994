import { FormElementsData } from '@anwb/poncho/types/types';

import { ROLES_CATEGORIES } from '../../../constants/roles-categories';
import { User } from '../../../types/users/user';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

export const formatUser = <T>(
	elementsData: FormElementsData,
	currentUser: User
) => {
	const roles = ROLES_CATEGORIES.filter(
		(category) => elementsData[category.code]
	).map((category) => category.code);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { REPORTS, INVOICES, VHV, PHV, ...filteredUser } = elementsData;

	return {
		...filteredUser,
		phoneNumber: formatPhoneNumber(
			filteredUser.phoneNumber as unknown as string
		),
		roles,
		debtors: currentUser.debtors,
		relationId: currentUser.relationId,
		relationName: currentUser.relationName,
		adminUser: false,
		keyUser: false,
	} as T;
};
