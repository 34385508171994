import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getInvoiceLines } from '../../../../utils/invoiceService';
import { useInvoiceLinesStore } from '../../../../store/invoiceLinesStore';
import { Pageable } from '../../../../types/pageable';
import { InvoiceLineSort } from '../../../../types/sort';
import { UseInvoiceLinesStore } from '../../../../types/invoices/invoice-lines-store';
import { InvoiceLine } from '../../../../types/invoices/invoice';
import { Page } from '../../../../types/page';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';

const PAGE_SIZE = 20;

const pageable = (pageNumber: number, sorting: InvoiceLineSort) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
		sort: sorting,
	}) as Pageable<InvoiceLineSort>;

const useProcessInvoiceLines = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, sorting, clearFilters, setPage, setNotification } =
		useInvoiceLinesStore((state: UseInvoiceLinesStore) => ({
			filters: state.filters,
			sorting: state.sorting,
			clearFilters: state.clearFilters,
			setPage: state.setPage,
			setNotification: state.setNotification,
		}));

	const { invoiceId } = useParams();

	const loadPage = (pageNumber: number) => {
		if (invoiceId) {
			setNotification(undefined);
			setShowSpinner(true);
			getInvoiceLines(invoiceId, filters, pageable(pageNumber, sorting))
				.then((result) => setPage(result.data as Page<InvoiceLine>))
				.catch(() => {
					setNotification({
						type: 'error',
						title: ERROR_API_CALL,
						description: ERROR_API_CALL_DESCRIPTION,
					});
				})
				.finally(() => setShowSpinner(false));
		}
	};

	useEffect(() => clearFilters, []);

	useEffect(() => {
		const timeout = setTimeout(() => loadPage(1), 750);
		return () => clearTimeout(timeout);
	}, [filters, sorting]);

	return { showSpinner, loadPage };
};

export default useProcessInvoiceLines;
