import { ReactNode } from 'react';

import { IllustrativeIconsKey } from '@anwb/poncho/utilities/icon-utils';
import Typography from '@anwb/poncho/components/typography';
import { PanelHeader, PanelContent } from '@anwb/poncho/components/panel';

import {
	ActionStatus,
	ActionType,
	ContactDirection,
} from '../../../../../types/cases/common-case';
import { formatDate } from '../../../../../utils/formatDate';
import {
	DateContainer,
	DetailsContainer,
	PanelContainer,
} from './styles/case-action-panel.styled';
import Tag from './Tag/Tag';
import IconArea from './IconArea/IconArea';

export type CaseActionPanelProps = {
	children?: ReactNode;
	title: string;
	type: ActionType;
	variant: IllustrativeIconsKey;
	direction?: ContactDirection;
	date: Date;
	status: ActionStatus;
};

const CaseActionPanel = ({
	children,
	title,
	type,
	variant,
	direction,
	date,
	status,
}: CaseActionPanelProps) => (
	<PanelContainer>
		<IconArea type={type} variant={variant} direction={direction} />
		<DetailsContainer>
			<PanelHeader title={title} />
			{children && <PanelContent>{children}</PanelContent>}
		</DetailsContainer>
		<DateContainer>
			<Typography variant="content-subtitle">{formatDate(date)}</Typography>
			<Tag text={status} />
		</DateContainer>
	</PanelContainer>
);

export default CaseActionPanel;
