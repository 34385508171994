import { useMemo } from 'react';

import Form from '@anwb/poncho/components/form';
import FormBlock from '@anwb/poncho/components/form-block';
import FormFieldEmail from '@anwb/poncho/components/form-field-email';
import FormFieldSurname from '@anwb/poncho/components/form-field-surname';
import FormFieldTelephone from '@anwb/poncho/components/form-field-telephone';
import { FormContainerFieldLabel } from '@anwb/poncho/components/form-container-field';
import FormFieldCheckbox from '@anwb/poncho/components/form-field-checkbox';
import { ButtonPrimary, ButtonTertiary } from '@anwb/poncho/components/button';
import Spinner from '@anwb/poncho/components/spinner';

import { User, Roles } from '../../../../types/users/user';
import { useCurrentUserStore } from '../../../../store/currentUserStore';
import { listRestrictedCategories } from './utils/listRestrictedCategories';
import { UserFormFooter } from './styles/user-form.styled';

type UserFormProps = {
	selectedUser?: User;
	isLoading: boolean;
	disableEmail?: boolean;
	onCancelHandler: () => void;
	onSubmitHandler: (formData: unknown) => void;
};

const UserForm = ({
	selectedUser,
	isLoading,
	disableEmail,
	onCancelHandler,
	onSubmitHandler,
}: UserFormProps) => {
	const { currentUser } = useCurrentUserStore((state) => ({
		currentUser: state.currentUser,
	}));

	const memoizedTitle = selectedUser
		? useMemo(() => `${selectedUser.lastName}, ${selectedUser.firstName}`, [])
		: '';

	const isChecked = (roleCode: Roles) =>
		selectedUser ? selectedUser.roles.indexOf(roleCode) !== -1 : false;

	return (
		<Form onSubmit={onSubmitHandler}>
			<FormBlock label={memoizedTitle} border>
				<FormFieldSurname
					label="Voornaam"
					value={selectedUser?.firstName || ''}
					name="firstName"
					reference="first-name"
					required
				/>
				<FormFieldSurname
					label="Achternaam"
					value={selectedUser?.lastName || ''}
					name="lastName"
					reference="last-name"
					required
				/>
				<FormFieldEmail
					value={selectedUser?.email || ''}
					name="email"
					reference="email"
					dataTest="email"
					required
					disabled={disableEmail}
				/>
				<FormFieldTelephone
					phoneType="mobile"
					value={selectedUser?.phoneNumber || ''}
					name="phoneNumber"
					reference="phone-number"
					dataTest="phone-number"
					required
				/>
				<FormContainerFieldLabel>Rechten</FormContainerFieldLabel>
				{listRestrictedCategories(currentUser).map((category, index) => (
					<FormFieldCheckbox
						key={`option-${index}`}
						label={category.label}
						name={category.code}
						reference={`option-${index}`}
						value={category.code}
						checked={isChecked(category.code)}
					/>
				))}
			</FormBlock>
			<UserFormFooter>
				<ButtonTertiary onClick={onCancelHandler}>Annuleren</ButtonTertiary>
				{isLoading ? (
					<Spinner data-test="user-form-spinner" />
				) : (
					<ButtonPrimary type="submit">Opslaan</ButtonPrimary>
				)}
			</UserFormFooter>
		</Form>
	);
};

export default UserForm;
