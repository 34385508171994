import { Tokens } from '../types/auth';
import { URL_COGNITO_LOGOUT } from '../constants/cognito';
import { globalSignOut } from '../api/globalSignOut';

export const endSession = () => {
	globalSignOut(localStorage.getItem(Tokens.AccessToken)!).finally(() => {
		localStorage.removeItem(Tokens.IdToken);
		localStorage.removeItem(Tokens.AccessToken);
		localStorage.removeItem(Tokens.RefreshToken);
		window.location.href = URL_COGNITO_LOGOUT;
	});
};
