import { Invoice } from '../../../../../types/invoices/invoice';
import { DescriptionListItem } from '../../../../../types/poncho';
import { formatDate, formatDateTime } from '../../../../../utils/formatDate';
import { formatDotCode } from './formatDotCode';

export const createPhvInvoiceDetails = (
	invoice: Invoice
): DescriptionListItem[] => [
	{
		key: 'Hulpverleningsoort',
		value: invoice.assistance?.assistanceType,
	},
	{
		key: 'Datum melding',
		value: formatDateTime(invoice.assistance?.reportDateTime),
	},
	{ key: 'Locatie', value: invoice.assistance?.location },
	{ key: 'DOT-code', value: formatDotCode(invoice.dotCode) },
	{ key: 'DOT-opmerking', value: invoice.dotComment },
];

export const createPhvInsuredDetails = (
	invoice: Invoice
): DescriptionListItem[] => [
	{ key: 'Polisnummer', value: invoice.insured?.policyNumber },
	{ key: 'Contractnaam', value: invoice.contractName },
	{ key: 'Adres', value: invoice.insured?.address },
	{
		key: 'Geboortedatum',
		value: formatDate(invoice.insured?.dateOfBirth),
	},
];
