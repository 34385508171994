import Typography from '@anwb/poncho/components/typography';

import {
	HeaderContainer,
	TitleContainer,
} from './styles/detail-panel-header.styled';

type DetailPanelHeaderProps = {
	title: string;
};

export const DetailPanelHeader = ({ title }: DetailPanelHeaderProps) => (
	<HeaderContainer>
		<TitleContainer>
			<Typography variant="component-title">{title}</Typography>
		</TitleContainer>
		<div />
	</HeaderContainer>
);
