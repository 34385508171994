import Table from '@anwb/poncho/components/table';

import { TableContainer } from '../../../../styles/table.styled';
import { useInvoicesStore } from '../../../../store/invoicesStore';
import { UseInvoicesStore } from '../../../../types/invoices/invoices-store';
import { AnyPage } from '../../../../types/unions';
import { CustomPagination } from '../../../Common/CustomPagination/CustomPagination';
import { NoItemsFound } from '../../../Common/NoItemsFound/NoItemsFound';
import { InvoiceRow } from './InvoiceRow';
import { InvoiceHeader } from './InvoiceHeader';

type InvoiceTableProps = {
	loadPage: (pageNumber: number) => void;
};

export const InvoicesTable = ({ loadPage }: InvoiceTableProps) => {
	const { page, filters } = useInvoicesStore((state: UseInvoicesStore) => ({
		page: state.page,
		filters: state.filters,
	}));

	let description = 'Polisnummer/Omschrijving';
	if (filters.assistanceTypes?.length === 1) {
		description =
			filters.assistanceTypes[0] === 'VHV' ? 'Omschrijving' : 'Polisnummer';
	}

	return (
		<div data-test="invoices-table-container">
			<TableContainer>
				<Table>
					<InvoiceHeader description={description} />
					<Table.Body>
						{page.items.map((invoice, idx) => (
							<InvoiceRow
								invoice={invoice}
								key={`${invoice.invoiceNumber}-${idx}`}
							/>
						))}
					</Table.Body>
				</Table>
			</TableContainer>
			<CustomPagination
				page={page}
				setPage={(newPage: AnyPage) => loadPage(newPage.pageNumber)}
			/>
			<NoItemsFound show={!page.items.length}>
				Geen facturen gevonden
			</NoItemsFound>
		</div>
	);
};
