import { ChangeEvent, useState } from 'react';

import { FormContainerFieldLabel } from '@anwb/poncho/components/form-container-field';
import FormFieldCheckbox from '@anwb/poncho/components/form-field-checkbox';

import { ROLES_CATEGORIES } from '../../../../../constants/roles-categories';
import { Debtor } from '../../../../../types/relations/relation';
import { Roles, User } from '../../../../../types/users/user';
import { ContractSelector } from './ContractSelector/ContractSelector';
import { LabelTags } from './LabelTags/LabelTags';

type RoleSelectorProps = {
	selectedItem?: User;
	debtors: Debtor[];
};

export const RoleSelector = ({ selectedItem, debtors }: RoleSelectorProps) => {
	const getRolesCheckedState = (role: Roles) =>
		selectedItem
			? selectedItem?.roles.find((right) => right === role) !== undefined
			: false;

	const [vhvIsSelected, setVhvIsSelected] = useState<boolean>(
		getRolesCheckedState('VHV')
	);
	const [phvIsSelected, setPhvIsSelected] = useState<boolean>(
		getRolesCheckedState('PHV')
	);

	const roleChangeHandler = (
		event: ChangeEvent<HTMLInputElement>,
		role: Roles
	) => {
		const isChecked = event.target.checked;
		role === 'VHV' && setVhvIsSelected(isChecked);
		role === 'PHV' && setPhvIsSelected(isChecked);
	};

	return (
		<>
			<FormContainerFieldLabel>Rechten</FormContainerFieldLabel>
			{ROLES_CATEGORIES.map((category, index) => (
				<FormFieldCheckbox
					key={`option-${index}`}
					label={category.label}
					name={category.code}
					reference={`option-${index}`}
					value={category.code}
					checked={getRolesCheckedState(category.code)}
					onChange={(event) => roleChangeHandler(event, category.code)}
				/>
			))}
			{vhvIsSelected && <LabelTags debtors={debtors} />}
			{phvIsSelected && <ContractSelector debtors={debtors} />}
		</>
	);
};
