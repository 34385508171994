import { useEffect, useState } from 'react';

import { useRelationsStore } from '../../../../store/relationsStore';
import { UseRelationsStore } from '../../../../types/relations/relation-store';
import { Page } from '../../../../types/page';
import { RelationPageable } from '../../../../types/relations/relation';
import { User } from '../../../../types/users/user';
import { getRelations } from '../../../../utils/relationService';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';
import { PAGE_SIZE } from '../constants/page-size';

const pageable = (pageNumber: number) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
	}) as RelationPageable;

const useProcessRelations = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, setPage, setNotification, page } = useRelationsStore(
		(state: UseRelationsStore) => ({
			filters: state.filters,
			setNotification: state.setNotification,
			page: state.page,
			setPage: state.setPage,
		})
	);

	const loadPage = (pageNumber: number) => {
		setNotification(undefined);
		setShowSpinner(true);

		getRelations(filters, pageable(pageNumber))
			.then((response) => {
				setPage(response.data as Page<User>);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			loadPage(page.pageNumber);
		}, 750);
		return () => clearTimeout(timeout);
	}, [filters]);

	return { showSpinner, loadPage };
};

export default useProcessRelations;
