import { PersonNamePhv } from '../../../types/cases/phv-case';
import { PersonName } from '../../../types/cases/common-case';

export const formatPersonName = (
	personName?: PersonNamePhv | PersonName
): string => {
	if (!personName) return '-';

	const { suffix = '', surname = '', initials = '' } = personName;
	let formattedName = `${suffix.trim()} ${surname.trim()}`.trim();

	if (initials.trim()) {
		if (suffix.trim() || surname.trim()) {
			formattedName += `, ${initials.trim()}`;
		} else {
			formattedName = initials.trim();
		}
	}

	return formattedName.replace(/\s{2,}/g, ' ').trim();
};
