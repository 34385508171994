import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import { Panel } from '@anwb/poncho/components/panel';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { ReturnLink } from '../../../styles/return-link';
import { DetailsContainer } from '../../../styles/details-page';
import { AnyAction } from '../../../types/cases/common-case';
import { PhvCase } from '../../../types/cases/phv-case';
import { VhvCase } from '../../../types/cases/vhv-case';
import { DetailPanelHeader } from '../../Common/DetailPanelHeader/DetailPanelHeader';
import { formatStatus } from '../utils/general';
import { CasesType } from '../types/cases';
import CaseActions from './CaseActions/CaseActions';
import { CaseDetailsPhv } from './CaseDetailsPhv/CaseDetailsPhv';
import { CaseDetailsVhv } from './CaseDetailsVhv/CaseDetailsVhv';
import { useSelectedCase } from './hooks/useSelectedCase';

type CasesDetailsProps = {
	casesType: CasesType;
};

export const CasesDetails = ({ casesType }: CasesDetailsProps) => {
	const selectedCase = useSelectedCase(casesType);
	const navigate = useNavigate();
	const navigateToOverview = () => navigate(`/dossiers-${casesType}`);

	return (
		(selectedCase && (
			<Grid constrainedWidth>
				<Grid.Item>
					<ReturnLink onClick={navigateToOverview}>
						Terug naar dossiers
					</ReturnLink>
					<ContentHeader>{`Dossier ${
						selectedCase.caseSummary.caseNumber
					} (${formatStatus(selectedCase.caseSummary.status)})`}</ContentHeader>
					<DetailsContainer>
						<Panel hasOuterSpacing={false}>
							<Panel.Content>
								<DetailPanelHeader title="Samenvatting" />
								{casesType === 'phv' && (
									<CaseDetailsPhv selectedCase={selectedCase as PhvCase} />
								)}
								{casesType === 'vhv' && (
									<CaseDetailsVhv selectedCase={selectedCase as VhvCase} />
								)}
							</Panel.Content>
						</Panel>
					</DetailsContainer>
					{casesType === 'vhv' && (
						<CaseActions
							casesType={casesType}
							actions={selectedCase.actions as AnyAction[]}
						/>
					)}
				</Grid.Item>
			</Grid>
		)) || <div />
	);
};
