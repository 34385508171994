import { Route, Routes, Navigate } from 'react-router-dom';

import { CasesDetails } from './CasesDetails/CasesDetails';
import { CasesOverview } from './CasesOverview/CasesOverview';
import { CasesType } from './types/cases';

type CasesProps = {
	casesType: CasesType;
};

export const Cases = ({ casesType }: CasesProps) => (
	<Routes>
		<Route index element={<CasesOverview casesType={casesType} />} />

		<Route path=":caseId" element={<CasesDetails casesType={casesType} />} />
		<Route path="/*" element={<Navigate to={`/dossiers-${casesType}`} />} />
	</Routes>
);
