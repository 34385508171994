import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getInvoiceLines } from '../../../../utils/invoiceService';
import { useInvoiceLinesStore } from '../../../../store/invoiceLinesStore';
import { Pageable } from '../../../../types/pageable';
import { InvoiceLineSort } from '../../../../types/sort';
import { UseInvoiceLinesStore } from '../../../../types/invoices/invoice-lines-store';
import { InvoiceLine } from '../../../../types/invoices/invoice';
import { Page } from '../../../../types/page';
import { endSession } from '../../../../auth/utils/endSession';

const PAGE_SIZE = 20;

const pageable = (pageNumber: number, sorting: InvoiceLineSort) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
		sort: sorting,
	}) as Pageable<InvoiceLineSort>;

const useProcessInvoiceLines = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, sorting, clearFilters, setPage } = useInvoiceLinesStore(
		(state: UseInvoiceLinesStore) => ({
			filters: state.filters,
			sorting: state.sorting,
			clearFilters: state.clearFilters,
			setPage: state.setPage,
		})
	);

	const { invoiceId } = useParams();

	const loadPage = (pageNumber: number) => {
		if (invoiceId) {
			setShowSpinner(true);
			getInvoiceLines(invoiceId, filters, pageable(pageNumber, sorting))
				.then((result) => setPage(result.data as Page<InvoiceLine>))
				.catch((error) => {
					if (error.response && error.response.status === 403) {
						endSession();
					}
					console.error(error);
				})
				.finally(() => setShowSpinner(false));
		}
	};

	useEffect(() => clearFilters, []);

	useEffect(() => {
		const timeout = setTimeout(() => loadPage(1), 750);
		return () => clearTimeout(timeout);
	}, [filters, sorting]);

	return { showSpinner, loadPage };
};

export default useProcessInvoiceLines;
