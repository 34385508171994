import { Outcome } from '../../../../../../../types/cases/common-case';

export const getOutcomeListItems = (outcome: Outcome) =>
	[
		{
			key: 'Component',
			value: outcome.component,
		},
		{
			key: 'Storing',
			value: outcome.failure,
		},
		{
			key: 'Resultaat',
			value: outcome.result,
		},
		{
			key: 'Toelichting',
			value: outcome.description,
		},
	].filter((item) => item.value !== undefined && item.value !== null);
