import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPrimary } from '@anwb/poncho/components/button';
import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';

import { revokeUserTokens } from '../../../auth/api/revokeUserTokens';
import {
	ContentHeader,
	OverviewContainer,
	TablePartContainer,
} from '../../../styles/page-layout.styled';
import { FilterContainer } from '../../../styles/filter.styled';
import { useUsersStore } from '../../../store/usersStore';
import { useCurrentUserStore } from '../../../store/currentUserStore';
import { UseUsersStore } from '../../../types/users/users-store';
import { AnyPage, AnySort } from '../../../types/unions';
import { FormState } from '../../../types/form';
import { UpdatedUser, User } from '../../../types/users/user';
import { USER_SORTINGS } from '../../../constants/user-sorting';
import { RELOAD_USERS_TIME } from '../../../constants/reloadUsersTime';
import { putUser, deleteUser } from '../../../utils/userService';
import { USER_MANAGEMENT_FILTER_DATA } from '../../Common/Filter/constants/user-management-filter-data';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { CustomPagination } from '../../Common/CustomPagination/CustomPagination';
import { DeleteModal } from '../../Common/DeleteModal/DeleteModal';
import { NoItemsFound } from '../../Common/NoItemsFound/NoItemsFound';
import Filter from '../../Common/Filter/Filter';
import { SearchBar } from '../../Common/SearchBar/SearchBar';
import { SortBar } from '../../Common/SortBar/SortBar';
import { formatUser } from '../utils/formatUser';
import UserForm from '../common/UserForm/UserForm';
import UserPanel from './UserPanel/UserPanel';
import useProcessUsers from './hooks/useProcessUsers';

export const UsersOverview = () => {
	const { showSpinner, loadPage } = useProcessUsers();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User>();
	const [obsoleteUser, setObsoleteUser] = useState<User>();
	const [modalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	const {
		page,
		filters,
		clearFilters,
		sorting,
		notification,
		setQuery,
		updateSorting,
		setNotification,
	} = useUsersStore((state: UseUsersStore) => ({
		page: state.page,
		filters: state.filters,
		clearFilters: state.clearFilters,
		sorting: state.sorting,
		notification: state.notification,
		setQuery: state.setQuery,
		updateSorting: state.updateSorting,
		setNotification: state.setNotification,
	}));

	const { currentUser } = useCurrentUserStore((state) => ({
		currentUser: state.currentUser,
	}));

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		const { valid, elementsData } = formData as FormState;
		let timeoutId: string | number | NodeJS.Timeout | undefined;

		if (valid && selectedUser) {
			const { userId } = selectedUser;
			const updatedUser = {
				...formatUser<UpdatedUser>(elementsData, currentUser),
				userId,
			};
			delete updatedUser.email;

			putUser(updatedUser)
				.then(() => {
					setNotification({
						type: 'success',
						title: 'Gebruiker gewijzigd',
						description: `${updatedUser.firstName} ${updatedUser.lastName} is succesvol gewijzigd`,
					});

					revokeUserTokens(userId);

					return new Promise<void>((resolve) => {
						timeoutId = setTimeout(() => {
							loadPage(page.pageNumber);
							resolve();
						}, RELOAD_USERS_TIME);
					});
				})
				.catch(() => {
					setNotification({
						type: 'error',
						title: 'Gebruiker wijzigen mislukt',
						description: `Door een technische fout is ${updatedUser.firstName} ${updatedUser.lastName} niet gewijzigd. Probeer het later nog eens.`,
					});
				})
				.finally(() => {
					setIsLoading(false);
					setSelectedUser(undefined);
					clearTimeout(timeoutId);
				});
		} else {
			setIsLoading(false);
		}
	};

	const onDeleteHandler = () => {
		if (!obsoleteUser) return;

		let timeoutId: string | number | NodeJS.Timeout | undefined;

		deleteUser(obsoleteUser.userId)
			.then(() => {
				setNotification({
					type: 'success',
					title: 'Gebruiker verwijderd',
					description: `${obsoleteUser.firstName} ${obsoleteUser.lastName} is succesvol verwijderd`,
				});

				revokeUserTokens(obsoleteUser.userId);

				return new Promise<void>((resolve) => {
					timeoutId = setTimeout(() => {
						loadPage(page.pageNumber);
						resolve();
					}, RELOAD_USERS_TIME);
				});
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: 'Gebruiker verwijderen mislukt',
					description: `Door een technische fout is ${obsoleteUser.firstName} ${obsoleteUser.lastName} niet verwijderd. Probeer het later nog eens.`,
				});
			})
			.finally(() => {
				setObsoleteUser(undefined);
				clearTimeout(timeoutId);
			});
	};

	useEffect(() => () => setNotification(undefined), []);

	return (
		<>
			<DeleteModal
				obsoleteItem={obsoleteUser}
				modalOpen={modalOpen}
				setDeleteModalOpen={setDeleteModalOpen}
				onDeleteHandler={onDeleteHandler}
			/>
			<Grid columns={1} constrainedWidth>
				<Grid.Item>
					<ContentHeader>
						<span>Gebruikersbeheer</span>
						<ButtonPrimary
							icon="plus"
							onClick={() => navigate('/gebruikersbeheer/gebruiker-toevoegen')}
						>
							Gebruiker toevoegen
						</ButtonPrimary>
					</ContentHeader>
					<OverviewContainer>
						<FilterContainer>
							<Filter
								store={useUsersStore}
								filterData={USER_MANAGEMENT_FILTER_DATA}
								filterType="userManagement"
								clearFilters={clearFilters}
							/>
						</FilterContainer>
						<TablePartContainer>
							<Grid.Item>
								<SearchBar
									query={filters.query}
									placeHolder="Zoek op Gebruikersnaam, Voornaam, Achternaam, E-mailadres of Mobiele nummer"
									searchBarType="user"
									setQuery={setQuery}
								/>
								<SortBar
									sortings={USER_SORTINGS}
									updateSorting={updateSorting as (sorting: AnySort) => void}
									value={sorting}
								/>

								{notification && (
									<Notification
										variant={notification.type}
										title={notification.title}
									>
										{notification.description}
									</Notification>
								)}

								<SpinnerContainer
									showSpinner={showSpinner}
									message="Gebruikers worden geladen"
								>
									{page.items.map((user, index) =>
										user.userId === selectedUser?.userId ? (
											<UserForm
												key={`user-${index}`}
												selectedUser={selectedUser}
												isLoading={isLoading}
												disableEmail
												onCancelHandler={() => setSelectedUser(undefined)}
												onSubmitHandler={onSubmitHandler}
											/>
										) : (
											<UserPanel
												dataTest={`panel-${index}`}
												key={`user-${index}`}
												user={user}
												onSelectHandler={() => setSelectedUser({ ...user })}
												onDeleteHandler={() => {
													setObsoleteUser(user);
													setDeleteModalOpen(true);
												}}
											/>
										)
									)}
									<NoItemsFound show={!page.items.length}>
										Geen gebruikers gevonden
									</NoItemsFound>
								</SpinnerContainer>
								<CustomPagination
									page={page}
									setPage={(newPage: AnyPage) => loadPage(newPage.pageNumber)}
								/>
							</Grid.Item>
						</TablePartContainer>
					</OverviewContainer>
				</Grid.Item>
			</Grid>
		</>
	);
};
