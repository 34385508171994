import { DescriptionListItem } from '../../../types/poncho';
import { formatDate } from '../../../utils/formatDate';
import { PhvCase } from '../../../types/cases/phv-case';
import { formatStatus, lookupPhvRecordCategory } from './general';

export const createPhvDescriptionListGeneral = (
	currentCase: PhvCase
): DescriptionListItem[] => {
	const descriptionList = [
		{
			key: 'Verzekerd bij',
			value: currentCase.caseSummary.label.name,
		},
		{
			key: 'Status',
			value: formatStatus(currentCase.caseSummary.status),
		},
		{
			key: 'Meldingsmoment',
			value: formatDate(currentCase.caseSummary.reportDateTime),
		},
	];

	if (currentCase.caseSummary.caseNumber) {
		descriptionList.push({
			key: 'Hoofddossier',
			value: currentCase.caseSummary.caseNumber,
		});
	} else {
		// deze straks als de prop bestaat alleen uitvoeren als ie aanwezig is...
		descriptionList.push({
			key: 'Nagekomen cases',
			value: '',
		});
	}

	return descriptionList;
};

export const createPhvDescriptionListAssistanceRequester = (
	currentCase: PhvCase
): DescriptionListItem[] => [
	{
		key: 'Naam',
		value: currentCase.caseSummary.assistanceRequester.personName.surname,
	},
	{
		key: 'Geboortedatum',
		value: '',
	},
	{
		key: 'Straatnaam',
		value: '',
	},
	{
		key: 'Postcode',
		value: '',
	},
	{
		key: 'Stad',
		value: '',
	},
	{
		key: 'Land',
		value: '',
	},
];

export const createPhvDescriptionListInsuredData = (
	currentCase: PhvCase
): DescriptionListItem[] => [
	{
		key: 'Polisnummer',
		value: currentCase.caseSummary.contract.policyNumber,
	},
	{
		key: 'Contractnaam',
		value: currentCase.caseSummary.contract.policyHolder.personName.surname,
	},
	{
		key: 'Dossiersoort',
		value: lookupPhvRecordCategory(currentCase.caseSummary.recordCategory),
	},
	{
		key: 'Land incident',
		value: currentCase.caseSummary.country,
	},
];

export const createPhvDescriptionListSecondaryInsurances =
	(): // currentCase: PhvCase
	DescriptionListItem[] => [
		{
			key: 'Polisnummer',
			value: '',
		},
		{
			key: 'Contractnaam',
			value: '',
		},
	];
