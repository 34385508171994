import DescriptionList from '@anwb/poncho/components/description-list';
import { Panel } from '@anwb/poncho/components/panel';
import { PanelSpacer } from '@zama/panel-spacer';

import { User } from '../../../../types/users/user';
import PanelTitleWithMenu from '../../../Common/PanelTitleWithMenu/PanelTitleWithMenu';
import { mapUserDescriptionListItems } from './utils/mapUserDescriptionListItems';

type UserPanelProps = {
	user: User;
	dataTest: string;
	onSelectHandler: () => void;
	onDeleteHandler: () => void;
};

const UserPanel = ({
	user,
	dataTest,
	onSelectHandler,
	onDeleteHandler,
}: UserPanelProps) => (
	<>
		<Panel dataTest={dataTest} hasOuterSpacing={false}>
			<Panel.Content>
				<PanelTitleWithMenu
					title={`${user.lastName}, ${user.firstName}`}
					onSelectHandler={onSelectHandler}
					onDeleteHandler={onDeleteHandler}
				/>
				<PanelSpacer />
				<DescriptionList items={mapUserDescriptionListItems(user)} />
			</Panel.Content>
		</Panel>
		<PanelSpacer />
	</>
);

export default UserPanel;
