import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import Typography from '@anwb/poncho/components/typography';

const tagBodyStyles = ({ theme }: DefaultThemeProps) => css`
	height: 34px;
	border-radius: 0.8rem;
	display: inline-flex;
	background-color: ${theme.colors.base.textTitles};
`;

export const TagBody = styled.span`
	${tagBodyStyles}
`;

const tagTextStyles = () => css`
	padding: 0.5rem 0.666667rem;
	color: white !important;
`;

export const TagText = styled(Typography).attrs({ variant: 'tag-text' })`
	${tagTextStyles}
`;
