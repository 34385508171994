import styled, { css } from 'styled-components';

const descriptionListContainerStyles = () => css`
	display: flex;
`;

export const DescriptionListContainer = styled.div`
	${descriptionListContainerStyles};
`;

const descriptionListColumnStyles = () => css`
	width: 50%;
`;

export const DescriptionListColumn = styled.div`
	${descriptionListColumnStyles};
`;
