const DateTimeFormatter_DD_MM_YYYY_HH_mm = {
	timeZone: 'Europe/Amsterdam',
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
} as Intl.DateTimeFormatOptions;

const DateFormatter_DD_MM_YYYY = {
	timeZone: 'Europe/Amsterdam',
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
} as Intl.DateTimeFormatOptions;

const dateTimeFormatter_DD_MM_YYYY_HH_mm = new Intl.DateTimeFormat(
	'nl-NL',
	DateTimeFormatter_DD_MM_YYYY_HH_mm
);

const dateFormatter_DD_MM_YYYY = new Intl.DateTimeFormat(
	'nl-NL',
	DateFormatter_DD_MM_YYYY
);

/**
 * Needs to work for both string (json dates) and Date (mocked BE) types at the moment
 */
const processDate = (date?: Date | string, time = false) => {
	if (!date) return '';
	const formatDate = typeof date === 'string' ? new Date(date) : date;
	return time
		? dateTimeFormatter_DD_MM_YYYY_HH_mm.format(formatDate).replace(',', '')
		: dateFormatter_DD_MM_YYYY.format(formatDate);
};

export const formatDate = (date?: Date | string) => processDate(date);
export const formatDateTime = (date?: Date | string) => processDate(date, true);
export const formatDate_YMD = (date?: Date | string) =>
	processDate(date).split('-').reverse().join('-');
