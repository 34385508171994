import { Dispatch, SetStateAction } from 'react';

import { Debtor } from '../../../../../../types/relations/relation';
import { getLabel } from '../../../../../../utils/relationService';

export const useValidateAnwbRelationNumber = (
	debtors: Debtor[],
	setDebtors: Dispatch<SetStateAction<Debtor[]>>
) => {
	return (
		anwbRelationNumber: string,
		input?: HTMLInputElement
	): Promise<string> =>
		new Promise((resolve, reject) => {
			const index = input?.name.split('-').pop() as unknown as number;
			const newData = [...debtors];

			const updateRelationItems = (labels?: string[]) => {
				newData.splice(index, 1, {
					anwbRelationNumber,
					labels: labels || [''],
				});
				setDebtors(newData);
			};

			if (anwbRelationNumber.length !== 9) {
				updateRelationItems();
				return reject('Voer svp 9 tekens in');
			}

			const occurrences = debtors.filter(
				(relationObj) => relationObj.anwbRelationNumber === anwbRelationNumber
			).length;

			if (occurrences > 0) {
				return reject('Relatienummer komt al voor in deze relatie');
			}

			return getLabel(anwbRelationNumber)
				.then((resp) => {
					updateRelationItems([resp.data]);
					return resolve('');
				})
				.catch(() => reject('Debiteurnaam ophalen mislukt'));
		});
};
