import { jwtDecode } from 'jwt-decode';

import { DecodedJwt } from '../types/auth';

export const tokenIsExpired = (storedJwt: string) => {
	const decodedToken: DecodedJwt = jwtDecode(storedJwt);
	const now = Date.now() / 1000;
	const isExpired = !decodedToken.exp || decodedToken.exp < now;
	if (isExpired) console.error('JWT token is expired');
	return isExpired;
};
