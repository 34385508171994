import { ChangeEvent, useEffect, useState } from 'react';

import { useInvoiceLinesStore } from '../../../../../store/invoiceLinesStore';

import {
	SearchInputContainer,
	FormContainer,
	ClearFilters,
	SearchInput,
} from './styles/search-bar.styled';

export const SearchBar = () => {
	const { filters, clearFilters, setQuery } = useInvoiceLinesStore((state) => ({
		filters: state.filters,
		clearFilters: state.clearFilters,
		setQuery: state.setQuery,
	}));
	const [searchQuery, setSearchQuery] = useState<string>(filters.query || '');

	useEffect(() => {
		filters.query === '' && setSearchQuery('');
	}, [filters.query]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setQuery(searchQuery);
		}, 750);

		return () => clearTimeout(timeout);
	}, [searchQuery, setQuery]);

	return (
		<FormContainer>
			<SearchInputContainer>
				<SearchInput
					type="text"
					data-test="invoice-line-query"
					value={searchQuery}
					placeholder="Zoek op omschrijving, casenummer, kenteken, vin"
					reference="invoice-line-finder"
					name="invoice-line-finder"
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						setSearchQuery(event.target.value)
					}
				/>

				<ClearFilters onClick={() => clearFilters()}>
					Filters wissen
				</ClearFilters>
			</SearchInputContainer>
		</FormContainer>
	);
};
