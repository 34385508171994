import { JwtPayload } from 'jwt-decode';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type DecodedAccessToken = {
	'sub': string;
	'cognito:groups': CognitoRole[];
	'iss': string;
	'version': number;
	'client_id': string;
	'origin_jti': string;
	'event_id': string;
	'token_use': 'access';
	'scope': string;
	'auth_time': number;
	'exp': number;
	'iat': number;
	'jti': string;
	'username': string;
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type DecodedIdToken = {
	'at_hash': string;
	'sub': string;
	'cognito:groups': CognitoRole[];
	'email_verified': boolean;
	'iss': string;
	'phone_number_verified': boolean;
	'cognito:username': string;
	'preferred_username': string;
	'given_name': string;
	'origin_jti': string;
	'aud': string;
	'event_id': string;
	'token_use': 'id';
	'auth_time': number;
	'phone_number': string;
	'exp': number;
	'iat': number;
	'family_name': string;
	'jti': string;
	'email': string;
};

export type CognitoRole =
	| 'ADMIN'
	| 'INVOICES'
	| 'KEY_USER'
	| 'PHV'
	| 'REPORTS'
	| 'VHV';

export type DecodedJwt = JwtPayload & {
	'cognito:groups': CognitoRole[];
	client_id: string;
};

export enum Tokens {
	IdToken = 'idToken',
	AccessToken = 'accessToken',
	RefreshToken = 'refreshToken',
}
