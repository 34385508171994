import Table from '@anwb/poncho/components/table';

import { HeaderCell } from '../../../../../styles/table.styled';
import { CasesType } from '../../../types/cases';

type CaseSummaryHeaderProps = {
	casesType: CasesType;
};

export const CaseSummaryHeader = ({ casesType }: CaseSummaryHeaderProps) => {
	const reportType = casesType === 'phv' ? 'Dossiersoort' : 'Type incident';
	const assistanceRequester = casesType === 'phv' ? 'Hulpvrager' : 'Bestuurder';
	const statusOrLicense = casesType === 'phv' ? 'Status' : 'Kenteken';

	return (
		<Table.Header>
			<Table.Row>
				<HeaderCell>Dossier</HeaderCell>
				<HeaderCell>Land</HeaderCell>
				<HeaderCell>{reportType}</HeaderCell>
				<HeaderCell>{assistanceRequester}</HeaderCell>
				<HeaderCell>{statusOrLicense}</HeaderCell>
				<HeaderCell $alignRight>Meldingsdatum</HeaderCell>
			</Table.Row>
		</Table.Header>
	);
};
