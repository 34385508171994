import { Page } from '../../../../types/page';
import { Invoice } from '../../../../types/invoices/invoice';

const STORAGE_NAME = 'currentInvoice';

export const setInvoiceSessionStorage = (
	invoiceId: string | undefined,
	page: Page<Invoice>
) => {
	const storeInvoice = page.items.find(
		(item) => item.invoiceNumber === invoiceId
	);

	storeInvoice &&
		sessionStorage.setItem(STORAGE_NAME, JSON.stringify(storeInvoice));
};

export const getInvoiceSessionStorage = () => {
	const invoiceFromSession: string | null =
		sessionStorage.getItem(STORAGE_NAME);

	return (
		(typeof invoiceFromSession === 'string' &&
			JSON.parse(invoiceFromSession)) ||
		null
	);
};

export const clearInvoiceSessionStorage = () => {
	setTimeout(() => {
		sessionStorage.removeItem(STORAGE_NAME);
	}, 5000);
};
