import { useParams } from 'react-router-dom';

import { useInvoicesStore } from '../../../../store/invoicesStore';
import { UseInvoicesStore } from '../../../../types/invoices/invoices-store';
import { Invoice } from '../../../../types/invoices/invoice';
import {
	getInvoiceSessionStorage,
	setInvoiceSessionStorage,
} from '../utils/invoiceSessionStorage';

const useGetInvoice = (): Invoice | null => {
	const { invoiceId } = useParams();

	const { page } = useInvoicesStore((state: UseInvoicesStore) => ({
		page: state.page,
	}));

	page.items.length && setInvoiceSessionStorage(invoiceId, page);

	return getInvoiceSessionStorage();
};

export default useGetInvoice;
