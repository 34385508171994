export const matchesOption = (
	searchOptions: undefined | string[],
	option: string | undefined
) =>
	searchOptions && searchOptions.length > 0 && option
		? searchOptions.includes(option)
		: !(searchOptions && searchOptions.length > 0);

export const matchesOptions = (
	searchOptions: undefined | string[],
	options: string[] | undefined
) =>
	searchOptions && searchOptions.length > 0
		? searchOptions.every((searchOption) => options?.includes(searchOption))
		: !(searchOptions && searchOptions.length > 0);
