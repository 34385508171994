import { DescriptionListItem } from '../../../types/poncho';
import { formatDate } from '../../../utils/formatDate';
import { VhvCase } from '../../../types/cases/vhv-case';
import { formatStatus, lookupVhvIncidentCategory } from './general';

export const createVhvDescriptionListGeneral = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Bedrijf/label',
		value: currentCase.caseSummary.label.name,
	},
	{
		key: 'Status',
		value: formatStatus(currentCase.caseSummary.status),
	},
	{
		key: 'Type incident',
		value: lookupVhvIncidentCategory(currentCase.caseSummary.incidentCategory),
	},
	{
		key: 'Meldingsmoment',
		value: formatDate(currentCase.caseSummary.reportDateTime),
	},
];

export const createVhvDescriptionListDriver = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Naam',
		value: currentCase.caseSummary.assistanceRequester.personName.surname,
	},
	{
		key: 'Straatnaam',
		value: '',
	},
	{
		key: 'Postcode',
		value: '',
	},
	{
		key: 'Stad',
		value: '',
	},
];

export const createVhvDescriptionListVehicle = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Merk',
		value: '',
	},
	{
		key: 'Model',
		value: '',
	},
	{
		key: 'Kleur',
		value: '',
	},
	{
		key: 'VIN',
		value: currentCase.caseSummary.vehicle.vin,
	},
];

export const createVhvDescriptionListInitialLocation = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Straatnaam',
		value: '',
	},
	{
		key: 'Postcode',
		value: '',
	},
	{
		key: 'Stad',
		value: currentCase.caseSummary.initialLocation,
	},
	{
		key: 'Land',
		value: '',
	},
];

export const createVhvDescriptionListFirstDiagnosis =
	(): // currentCase: VhvCase
	DescriptionListItem[] => [
		{
			key: 'Schadesoort',
			value: '',
		},
		{
			key: 'Storingsomschrijving',
			value: '',
		},
	];

export const createVhvDescriptionListTravelContext = (): // currentCase: VhvCase
DescriptionListItem[] => [
	{
		key: 'Richting',
		value: '',
	},
	{
		key: 'Vertrekdatum',
		value: '',
	},
	{
		key: 'Terugreisdatum',
		value: '',
	},
	{
		key: 'Reisdoel',
		value: '',
	},
];

export const createVhvDescriptionListTransportDestination =
	(): // currentCase: VhvCase
	DescriptionListItem[] => [
		{
			key: 'Straatnaam',
			value: '',
		},
		{
			key: 'Postcode',
			value: '',
		},
		{
			key: 'Stad',
			value: '',
		},
		{
			key: 'Land',
			value: '',
		},
	];
