import { VhvCase } from '../../../types/cases/vhv-case';
import { Action } from '../../../types/cases/common-case';
import { nextDate } from '../../utils/nextDate';

const vhvCasesDB = [] as VhvCase[];

const STARTING_DATE = new Date('2022-01-01');

let sequenceNumber = 1000000;

const nextSequenceNumber = () => {
	sequenceNumber += 1;
	return sequenceNumber.toString();
};

const nextReportDateTime = nextDate(STARTING_DATE);
const nextCaseActionDateTime = nextDate(STARTING_DATE, 0.25);

let licensePlateNumber = 1000;

const nextlicensePlate = () => {
	licensePlateNumber += 1;
	return `${Math.ceil(licensePlateNumber / 100)}AB${
		licensePlateNumber % 100
	}`.toString();
};

const personName = {
	initials: 'B.',
	surname: 'Jansen',
};

const address = {
	street: 'Street',
	houseNumber: '2',
	postalCode: '4321BA',
	city: 'City',
	country: 'Nederland',
};

const assistanceRequester = {
	personName,
};

const driver = {
	personName,
	address,
};

const vehicle = {
	vehicleType: 'PERSONENAUTO',
	licensePlate: 'AA-11-BB',
	vin: 'ABC1234',
	brand: 'Renault',
	model: 'Clio',
	color: 'Antraciet',
};

const geoCoordinates = {
	longitude: 52.096214,
	latitude: 4.330095,
	system: 'system',
	notation: '52°05\'46.4"N 4°19\'48.3"E',
};

const assistanceReportedLocation = {
	description: 'Description',
	address,
	geoCoordinates,
};

const initialDiagnosis = {
	kind: 'Mechanisch',
	description: 'Accu',
};

const contractLabel = {
	id: '123',
	name: 'ANWB Autoverzekering',
};

const caseSummary = {
	caseNumber: '12345',
	assistanceType: 'VHV',
	status: 'OPEN',
	reportDateTime: STARTING_DATE,
	label: contractLabel,
	passedOn: 'UNKNOWN',
	assistanceRequester,
	country: 'Nederland',
	initialLocation: 'DOMESTIC',
	incidentCategory: 'PECH_MECHANISCH',
	assistanceCategory: 'DIEFSTALHULPVERLENING_BUITENLAND',
	driver,
	vehicle,
	assistanceReportedLocation,
	initialDiagnosis,
};

const assistanceAction = (): Action => ({
	title: 'Bergen voertuig',
	icon: 'car-transport',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'ASSISTANCE_ACTION',
	code: 'BERG',
	name: 'Bergen voertuig',
	registrationDate: nextCaseActionDateTime(),
});

const assistanceAction2 = (): Action => ({
	...assistanceAction(),
	title: 'Repareren op afstand',
	icon: 'car-repair',
	status: 'CLOSED',
	sortingDate: nextCaseActionDateTime(),
	code: 'ROAD',
	name: 'Repareren op afstand',
	registrationDate: nextCaseActionDateTime(),
});

const contactMoment = (): Action => ({
	title: 'Telefoongesprek',
	icon: 'smartphone',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'CONTACT_MOMENT',
	direction: 'IN',
	media: 'TELEFOON',
	role: 'HULPVRAAGMELDER',
	text: 'Ik heb pech',
});

const contactMoment2 = (): Action => ({
	...contactMoment(),
	sortingDate: nextCaseActionDateTime(),
	direction: 'OUT',
	role: 'CONTRACTANT',
	text: 'We hebben de pech verholpen',
});

const offeredSolution = (): Action => ({
	title: 'Aangeboden oplossing',
	icon: 'information',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'OFFERED_SOLUTION',
	text: 'Oplossing',
});

const financialAgreement = (): Action => ({
	title: 'Financiële afspraak',
	icon: 'money',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'FINANCIAL_AGREEMENT',
	text: 'U betaalt 500 euro, wij betalen 2 euro',
});

const actions: Action[] = [];

[
	assistanceAction,
	assistanceAction2,
	contactMoment,
	contactMoment2,
	offeredSolution,
	financialAgreement,
].forEach((action) => {
	for (let i = 0; i < 5; i += 1) {
		actions.push(action());
	}
});

const vhvCase = {
	isFavorite: false,
	caseSummary,
} as VhvCase;

const clone = <T>(value: T) => JSON.parse(JSON.stringify(value)) as T;

const nextCase = () => {
	const cloned = clone(vhvCase);
	cloned.caseSummary.caseNumber = nextSequenceNumber();
	cloned.caseSummary.vehicle.licensePlate = nextlicensePlate();
	cloned.caseSummary.reportDateTime = nextReportDateTime();
	return cloned;
};

const generateVhvCases = () => {
	// default
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// label: Volkwagen Nederland.
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.label = { id: '4321', name: 'Volkswagen Nederland' };
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// domestic
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.initialLocation = 'ABROAD';
		newCase.caseSummary.country = 'Duitsland';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// closed
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.status = 'CLOSED';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// intern afgewenteld
	for (let i = 1; i <= 4; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.passedOn = 'INTERNAL';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// extern afgewenteld
	for (let i = 1; i <= 6; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.passedOn = 'EXTERNAL';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// vehicleType
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.vehicle.vehicleType = 'BOOT';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// vhvAssistanceCategory
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.assistanceCategory = 'PECHHULPVERLENING_BINNENLAND';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
	// vhvIncidentCategory
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.incidentCategory = 'SCHADE_RUITBREUK';
		newCase.actions = actions;
		vhvCasesDB.push(newCase);
	}
};

generateVhvCases();

export const getAllCases = () => vhvCasesDB.map((aCase) => aCase);
