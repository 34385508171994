import Table from '@anwb/poncho/components/table';

import { TableContainer } from '../../../../styles/table.styled';
import { VhvCase } from '../../../../types/cases/vhv-case';
import { PhvCase } from '../../../../types/cases/phv-case';
import { Page } from '../../../../types/page';
import { CasesType } from '../../types/cases';
import { CaseSummaryHeader } from './CaseSummaryHeader/CaseSummaryHeader';
import { CaseSummaryRow } from './CaseSummaryRow/CaseSummaryRow';

type CasesOverviewTableProps = {
	casesType: CasesType;
	page: Page<VhvCase | PhvCase>;
};

export const CasesOverviewTable = ({
	casesType,
	page,
}: CasesOverviewTableProps) => (
	<TableContainer>
		<Table withOuterSpacing={false}>
			<CaseSummaryHeader casesType={casesType} />
			<Table.Body>
				{page.items.map((caseData) => (
					<CaseSummaryRow
						caseSummary={caseData.caseSummary}
						casesType={casesType}
						key={caseData.caseSummary.caseNumber}
					/>
				))}
			</Table.Body>
		</Table>
	</TableContainer>
);
