import { Pageable } from '../types/pageable';
import { Page } from '../types/page';
import {
	CaseActionSort,
	CaseSort,
	InvoiceLineSort,
	InvoiceSort,
	UserSort,
} from '../types/sort';
import { ContractorPageable } from '../types/contractors/contractor';

export const toPage = <T>(
	items: T[],
	pageable:
		| Pageable<
				InvoiceSort | InvoiceLineSort | CaseSort | CaseActionSort | UserSort
		  >
		| ContractorPageable
) => {
	const totalPages =
		Math.floor(items.length / pageable.pageSize) +
		(items.length % pageable.pageSize > 0 ? 1 : 0);
	const startIndex = (pageable.pageNumber - 1) * pageable.pageSize;
	if (startIndex < items.length) {
		return {
			items: items.slice(startIndex, startIndex + pageable.pageSize),
			totalItems: items.length,
			totalPages,
			pageNumber: pageable.pageNumber,
		} as Page<T>;
	}
	return {
		items: [] as T[],
		totalItems: items.length,
		totalPages: totalPages === 0 ? 1 : totalPages,
		pageNumber: pageable.pageNumber,
	} as Page<T>;
};
