const isEmpty = <T>(v: T[] | T | undefined | null) =>
	v === null ||
	v === undefined ||
	(Array.isArray(v) && v.length === 0) ||
	false;

const appendParam = <T>(
	urlSearchParams: URLSearchParams,
	nameValue: [string, T]
) => {
	const name = nameValue[0];
	const value = nameValue[1];

	// remove empty arrays, remove empty values
	if (Array.isArray(value)) {
		urlSearchParams.append(name, value.join());
	} else {
		urlSearchParams.append(name, String(value));
	}
	return urlSearchParams;
};

const toAnyParameters = <T>(query: T) =>
	Object.entries(query as ArrayLike<unknown>)
		.filter((v) => !isEmpty(v[1]))
		.reduce(appendParam, new URLSearchParams())
		.toString();

export const toPageParams = <T>(query: T) => toAnyParameters<T>(query);

export const toQueryParams = <T>(query: T) => toAnyParameters(query);
