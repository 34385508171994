import { ASSISTANCE_ACTIONS } from '../../../../constants/assistance-actions';
import { FilterData } from '../types/filter';

export const INVOICE_FILTER_DATA: FilterData[] = [
	{
		name: 'assistanceType',
		type: 'checkboxGroup',
		active: true,
		label: 'Voertuig en/of personenhulpverlening',
		options: [
			{ label: 'Voertuighulpverlening', value: 'VHV' },
			{ label: 'Personenhulpverlening', value: 'PHV' },
		],

		value: [],
	},
	{
		name: 'creditOrDebet',
		type: 'checkboxGroup',
		active: true,
		label: 'Credit of Debet',
		options: [
			{ label: 'Credit', value: 'CREDIT' },
			{ label: 'Debet', value: 'DEBET' },
		],

		value: [],
	},
	{
		name: 'paymentState',
		type: 'checkboxGroup',
		active: true,
		label: 'Betaalstatus',
		options: [
			{ label: 'Open', value: 'OPEN' },
			{ label: 'Gesloten', value: 'CLOSED' },
		],

		value: [],
	},
	{
		type: 'dateRange',
		active: true,
		label: 'Datum',
	},
	{
		type: 'numberRange',
		active: true,
		label: 'Factuurbedrag',
	},
	{
		name: 'invoiceType',
		type: 'checkboxGroup',
		active: true,
		label: 'Factuursoort',
		options: [
			{ label: 'Losse factuur', value: 'SINGLE' },
			{ label: 'Verzamelfactuur', value: 'COLLECTIVE' },
		],

		value: [],
	},
	{
		name: 'assistanceActions',
		type: 'checkboxGroup',
		active: true,
		label: 'Hulpverleningactie',
		options: (filters) =>
			ASSISTANCE_ACTIONS.filter((action) =>
				action.assistanceType &&
				action.assistanceType.length === 1 &&
				filters.assistanceType &&
				filters.assistanceType.length === 1
					? action.assistanceType[0] === filters.assistanceType[0]
					: true
			).map((action) => ({
				label: action.name,
				value: action.code,
			})),
		value: [],
	},
];
