import styled, { css } from 'styled-components';

import Table from '@anwb/poncho/components/table';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';

const headerCellStyles = () => css`
	text-align: left;
`;

export const HeaderCell = styled(Table.HeaderColumn)`
	${headerCellStyles}
`;

const descriptionHeaderCellStyles = ({ theme }: DefaultThemeProps) => css`
	min-width: 35rem;
	${headerCellStyles}

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
		min-width: auto !important;
	}
`;

export const DescriptionHeaderCell = styled(Table.HeaderColumn)`
	${descriptionHeaderCellStyles};
`;

const priceRowCellStyles = () => css`
	text-align: right;
`;

export const PriceRowCell = styled(Table.Column)`
	${priceRowCellStyles};
`;
