import styled, { css } from 'styled-components';

import Typography from '@anwb/poncho/components/typography';
import Grid from '@anwb/poncho/components/grid';

const contentHeaderStyles = () => css`
	margin: 16px 0 30px 0;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
`;

export const ContentHeader = styled(Typography).attrs({
	variant: 'page-title',
})`
	${contentHeaderStyles}
`;

const overviewContainerStyles = () => css`
	display: flex;
`;

export const OverviewContainer = styled.div`
	${overviewContainerStyles}
`;

const paddingStyles = () => css`
	padding-bottom: 4rem;
`;

export const TablePartContainer = styled(Grid)`
	${paddingStyles};
	padding-left: 20px;
`;

export const DefaultContainer = styled(Grid)`
	${paddingStyles}
`;
