import styled, { css } from 'styled-components';

import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';

const wrapperStyles = ({ theme }: DefaultThemeProps) => css`
	display: flex;
	justify-content: center;
	margin-bottom: 3rem;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
		justify-content: flex-start;
	}
`;

export const PaginationWrapper = styled.div`
	${wrapperStyles};
`;
