import styled, { css } from 'styled-components';
import { FormContainerFieldContainer } from '@anwb/poncho/components/form-container-field';
import { ButtonTertiary } from '@anwb/poncho/components/button';

const anwbRelationNumberInputContainerStyles = () => css`
	${FormContainerFieldContainer} {
		width: 150px !important;
		max-width: 150px !important;
	}
`;

export const AnwbRelationNumberInputContainer = styled.div`
	${anwbRelationNumberInputContainerStyles}
`;

const anwbRelationTrashContainerStyles = () => css`
	${FormContainerFieldContainer} {
		width: 60px !important;
	}
`;

export const AnwbRelationTrashContainer = styled.div`
	${anwbRelationTrashContainerStyles}
`;

const anwbRelationNumberInputRowStyles = () => css`
	display: flex;
	column-gap: 0.5rem;
`;

export const AnwbRelationNumberInputRow = styled.div`
	${anwbRelationNumberInputRowStyles}
`;

const fullWidthButtonTertiaryStyles = () => css`
	width: 100% !important;
	margin-bottom: 1rem !important;
`;

export const FullWidthButtonTertiary = styled(ButtonTertiary)`
	${fullWidthButtonTertiaryStyles}
`;
