export const compare = (
	lhs: string | number | undefined,
	rhs: string | number | undefined
): number => {
	if (lhs === undefined && rhs === undefined) {
		return 0;
	}
	if (lhs === undefined) {
		return 1;
	}
	if (rhs === undefined) {
		return -1;
	}
	if (lhs < rhs) {
		return -1;
	}
	if (lhs > rhs) {
		return 1;
	}
	return 0;
};
