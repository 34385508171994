import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import Link from '@anwb/poncho/components/link';

const returnLinkStyles = ({ theme }: DefaultThemeProps) => css`
	display: flex;
	align-items: flex-start;
	margin-top: ${pxToRem(theme.spacing['400'])};
`;

export const ReturnLink = styled(Link).attrs({
	iconInverted: true,
})`
	${returnLinkStyles};
`;
