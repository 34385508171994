import { Route, Routes, Navigate } from 'react-router-dom';

import { RelationsOverview } from './RelationsOverview/RelationsOverview';
import { AddRelation } from './AddRelation/AddRelation';
import { EditRelation } from './EditRelation/EditRelation';

export const RelationManagement = () => (
	<Routes>
		<Route index element={<RelationsOverview />} />
		<Route path="/relatie-toevoegen" element={<AddRelation />} />
		<Route path="/relatie-bewerken/:id" element={<EditRelation />} />
		<Route path="/*" element={<Navigate to="/relatiebeheer" />} />
	</Routes>
);
