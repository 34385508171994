import { Pageable } from '../types/pageable';
import { UserSort } from '../types/sort';
import { NewUser, SearchUser, UpdatedUser } from '../types/users/user';
import { USER_MANAGEMENT_FILTER_DATA } from '../components/Common/Filter/constants/user-management-filter-data';
import api from '../api/api';
import { API_URL_USER, API_URL_USERS } from '../api/constants/urls';
import { toPageParams, toQueryParams } from './toQueryParameters';
import { stripSearch } from './stripSearch';

export const getUsers = (
	relationId: string,
	searchUser: SearchUser,
	pageable: Pageable<UserSort>
) => {
	const strippedSearchUser = stripSearch(
		searchUser as Record<string, string | string[]>,
		USER_MANAGEMENT_FILTER_DATA
	);
	const searchParams = toQueryParams<SearchUser>(strippedSearchUser);
	const pageParams = toPageParams<Pageable<UserSort>>(pageable);
	const url = `${API_URL_USERS}?relationId=${relationId}&${pageParams}&${searchParams}`;
	return api.get(url);
};

export const getUser = (userId: string) =>
	api.get(`${API_URL_USER}?userId=${userId}`);

export const postUser = (user: NewUser) => api.post(API_URL_USERS, user);

export const putUser = (user: UpdatedUser) => api.put(API_URL_USERS, user);

export const deleteUser = (userId: string) =>
	api.delete(`${API_URL_USERS}?userId=${userId}`);
