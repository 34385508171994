import Typography from '@anwb/poncho/components/typography';
import { Tag, Tags } from '@anwb/poncho/components/tags';
import { PanelSpacer } from '@zama/panel-spacer';

import { Debtor } from '../../../../../../types/contractors/contractor';

type FlowIdTagsProps = {
	debtors: Debtor[];
};

export const FlowIdTags = ({ debtors }: FlowIdTagsProps) =>
	debtors?.length > 0 && (
		<>
			<PanelSpacer />
			<Typography variant="component-title">Labels</Typography>
			<Tags>
				{debtors.map((debtor, i) => (
					<Tag key={`flow-id-${i}`}>
						<Tag.Label>{debtor.name}</Tag.Label>
					</Tag>
				))}
			</Tags>
		</>
	);
