export const PHV_ASSISTANCE_CATEGORIES = [
	{
		code: 'PERSONENHULP_BINNENLAND',
		label: 'Personenhulpverlening binnenland',
	},
	{
		code: 'PERSONENHULP',
		label: 'Personenhulpverlening',
	},
	{
		code: 'PERSONENHULP_BUITENLAND',
		label: 'Personenhulpverlening buitenland',
	},
] as const;
