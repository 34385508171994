import { TagBody, TagText } from './styles/tag.styled';

type TagProps = {
	text: string;
};

const Tag = ({ text }: TagProps) => (
	<TagBody>
		<TagText>{text}</TagText>
	</TagBody>
);

export default Tag;
