import { FilterData } from '../types/filter';

export const CASE_ACTION_FILTER_DATA: FilterData[] = [
	{
		name: 'actionType',
		type: 'checkboxGroup',
		active: true,
		label: 'Communicatie',
		options: [
			{ label: 'Hulpactie', value: 'ASSISTANCE_ACTION', color: 'yellow' },
			{
				label: 'Contactmoment',
				value: 'CONTACT_MOMENT',
				color: 'blue',
				options: [
					{
						label: 'Tussen hulpvrager en ANWB',
						value: 'CONTACT_MOMENT_WITH_HELP_REQUESTER',
					},
					{
						label: 'Tussen dienstverlener en ANWB',
						value: 'CONTACT_MOMENT_WITH_SERVICE_PROVIDER',
					},
					{
						label: 'Tussen contractant en ANWB',
						value: 'CONTACT_MOMENT_WITH_RELATION',
					},
				],
			},
			{
				label: 'Aangeboden oplossing',
				value: 'OFFERED_SOLUTION',
				color: 'orange',
			},
		],

		value: [],
	},
	{
		name: 'status',
		type: 'checkboxGroup',
		active: true,
		label: 'Hulpactie status',
		options: [
			{ label: 'Open', value: 'OPEN' },
			{ label: 'Geannuleerd', value: 'CANCELED' },
			{ label: 'Afgesloten', value: 'CLOSED' },
		],

		value: [],
	},
	{
		type: 'dateRange',
		active: true,
		label: 'Datum',
	},
];
