import styled, { css } from 'styled-components';

import Typography from '@anwb/poncho/components/typography';
import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';

const headerContainerStyles = () => css`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin: -0.5rem -1rem 0 0;
`;

export const HeaderContainer = styled.header`
	${headerContainerStyles}
`;

const titleContainerStyles = () => css`
	margin-bottom: 1rem;
`;

export const TitleContainer = styled.div`
	${titleContainerStyles}
`;

const linkTextStyles = ({ theme }: DefaultThemeProps) => css`
	margin-right: ${pxToRem(theme.spacing['300'])};
`;

export const LinkText = styled(Typography).attrs({
	variant: 'link-text',
})`
	${linkTextStyles}
`;
