export const isInRange = <T>(value: T, minValue?: T, maxValue?: T) => {
	if (minValue && maxValue) {
		return value >= minValue && value <= maxValue;
	}
	if (minValue) {
		return value >= minValue;
	}
	if (maxValue) {
		return value <= maxValue;
	}
	return true;
};
