import { PHVRecordCategory } from '../../../types/cases/phv-case';
import { VHVIncidentCategory } from '../../../types/cases/vhv-case';
import { PHV_RECORD_CATEGORIES } from '../../../constants/phv-record-category';
import { VHV_INCIDENT_CATEGORIES } from '../../../constants/vhv-incident-categories';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

export const formatStatus = (status: string): string => {
	switch (status) {
		case 'OPEN':
			return 'Open';
		case 'CLOSED':
			return 'Gesloten';
		case 'REJECTED':
			return 'Afgewezen';
		case 'CANCELED':
			return 'Geannuleerd';
		default:
			return capitalizeFirstLetter(status);
	}
};

export const lookupPhvRecordCategory = (category?: PHVRecordCategory) =>
	category
		? PHV_RECORD_CATEGORIES.find((record) => category === record.code)?.label
		: '-';

export const lookupVhvIncidentCategory = (category: VHVIncidentCategory) =>
	VHV_INCIDENT_CATEGORIES.find((record) => category === record.code)?.label;
