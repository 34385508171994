import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import { PanelHighlight } from '@anwb/poncho/components/panel';

const filterContainerStyles = ({ theme }: DefaultThemeProps) => css`
	display: flex;
	flex-direction: row;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
		flex-direction: column;
	}
`;

export const FilterContainer = styled.div`
	${filterContainerStyles}
`;

export const FilterPanel = styled(PanelHighlight)`
	padding: 0;
	margin-bottom: 1.2rem;
`;
