export const INVOICE_SORTINGS = [
	{ label: 'Datum - oplopend', value: 'ASC_INVOICE_DATE' },
	{ label: 'Datum - aflopend', value: 'DESC_INVOICE_DATE' },
	{ label: 'C/D - oplopend', value: 'ASC_CD' },
	{ label: 'C/D - aflopend', value: 'DESC_CD' },
	{ label: 'Bedrag - oplopend', value: 'ASC_AMOUNT' },
	{ label: 'Bedrag - aflopend', value: 'DESC_AMOUNT' },
	{ label: 'Polisnr - oplopend', value: 'ASC_POLICY_NUMBER' },
	{ label: 'Polisnr - aflopend', value: 'DESC_POLICY_NUMBER' },
] as const;

export const DEFAULT_INVOICE_SORTING = INVOICE_SORTINGS[1];
