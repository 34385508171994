import DescriptionList from '@anwb/poncho/components/description-list';
import { Panel } from '@anwb/poncho/components/panel';
import Typography from '@anwb/poncho/components/typography';

import { Invoice } from '../../../../types/invoices/invoice';

import {
	createPhvInsuredDetails,
	createPhvInvoiceDetails,
} from './utils/createPhvInvoiceDetails';

type PhvInvoiceDetailsProps = {
	invoice: Invoice;
};

export const PhvInvoiceDetails = ({ invoice }: PhvInvoiceDetailsProps) => (
	<Panel hasOuterSpacing={false}>
		<Panel.Header title="Personenhulpverlening" />
		<Panel.Content>
			<DescriptionList items={createPhvInvoiceDetails(invoice)} />
			<Typography variant="component-title">Polishouder/verzekerde</Typography>
			<DescriptionList items={createPhvInsuredDetails(invoice)} />
		</Panel.Content>
	</Panel>
);
