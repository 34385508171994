import { useEffect, useState } from 'react';

import Notification from '@anwb/poncho/components/notification';
import Typography from '@anwb/poncho/components/typography';

import {
	DetailsAside,
	DetailsContainer,
	DetailsMain,
} from '../../../../styles/details-page';
import { CASE_ACTION_SORTINGS } from '../../../../constants/case-action-sortings';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';
import { Action, ContactMoment } from '../../../../types/cases/common-case';
import { AnyPage, AnySort } from '../../../../types/unions';
import { Pageable } from '../../../../types/pageable';
import { CaseActionSort } from '../../../../types/sort';
import {
	SearchCaseActions,
	UseCaseActionsStore,
} from '../../../../types/cases/case-actions-store';
import { PreviousQuery } from '../../../../utils/previousQuery';
import { useCaseActionStoreVhv } from '../../../../store/caseActionsStoreVhv';
import { useCaseActionStorePhv } from '../../../../store/caseActionsStorePhv';
import { CASE_ACTION_FILTER_DATA } from '../../../Common/Filter/constants/case-action-filter-data';
import { CustomPagination } from '../../../Common/CustomPagination/CustomPagination';
import SpinnerContainer from '../../../Common/SpinnerContainer/SpinnerContainer';
import { SortBar } from '../../../Common/SortBar/SortBar';
import Filter from '../../../Common/Filter/Filter';
import { CasesType } from '../../types/cases';
import { DetailsHistoryHeader } from './styles/case-actions.styled';
import CaseActionPanel from './CaseActionPanel/CaseActionPanel';
import { filterCaseActions } from './utils/filterCaseActions';

type CaseActionProps = {
	casesType: CasesType;
	actions: Action[];
};

const PAGE_SIZE = 20;

export const previousQueries = {
	vhv: new PreviousQuery<SearchCaseActions, CaseActionSort>(),
	phv: new PreviousQuery<SearchCaseActions, CaseActionSort>(),
};

const CaseActions = ({ casesType, actions }: CaseActionProps) => {
	const [showSpinner, setShowSpinner] = useState(true);

	const store =
		casesType === 'vhv' ? useCaseActionStoreVhv : useCaseActionStorePhv;
	const {
		filters,
		page,
		sorting,
		notification,
		clearFilters,
		setPage,
		updateSorting,
		setNotification,
	} = store((state: UseCaseActionsStore) => ({
		filters: state.filters,
		page: state.page,
		sorting: state.sorting,
		notification: state.notification,
		clearFilters: state.clearFilters,
		setPage: state.setPage,
		updateSorting: state.updateSorting,
		setNotification: state.setNotification,
	}));

	useEffect(() => {
		if (
			!previousQueries[casesType].shouldUpdate(
				filters,
				sorting,
				page.pageNumber
			)
		)
			return;

		previousQueries[casesType].setPrevious(filters, sorting, page.pageNumber);
		setShowSpinner(true);

		const pageable: Pageable<CaseActionSort> = {
			pageNumber: page.pageNumber,
			pageSize: PAGE_SIZE,
			sort: sorting,
		};

		filterCaseActions(actions, filters, pageable)
			.then((result) => {
				setPage(result);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	}, [filters, page.pageNumber, sorting, setPage]);

	const actionText = (action: Action) =>
		'text' in action ? action.text : null;

	return (
		<DetailsContainer>
			<DetailsHistoryHeader>
				<Typography variant="group-component-title">Geschiedenis</Typography>
				<SortBar
					value=""
					sortings={CASE_ACTION_SORTINGS}
					updateSorting={updateSorting as unknown as (sorting: AnySort) => void}
				/>
			</DetailsHistoryHeader>
			<DetailsAside>
				<Filter
					filterData={CASE_ACTION_FILTER_DATA}
					store={store}
					filterType="caseActions"
					clearFilters={clearFilters}
				/>
			</DetailsAside>
			<DetailsMain $padding="left">
				{notification && (
					<Notification variant={notification.type} title={notification.title}>
						{notification.description}
					</Notification>
				)}
				<SpinnerContainer
					showSpinner={showSpinner}
					message="Hulpacties worden geladen"
				>
					{page.items.length &&
						page.items.map((action, index) => (
							<CaseActionPanel
								key={`action-${index}`}
								title={action.title}
								type={action.actionType}
								variant={action.icon}
								status={action.status}
								direction={(action as ContactMoment).direction}
								date={action.sortingDate}
							>
								{actionText(action) || 'Some Assistance content'}
							</CaseActionPanel>
						))}
					<CustomPagination
						page={page}
						setPage={setPage as (page: AnyPage) => void}
					/>
				</SpinnerContainer>
			</DetailsMain>
		</DetailsContainer>
	);
};

export default CaseActions;
