import { Dispatch, SetStateAction } from 'react';

import Typography from '@anwb/poncho/components/typography';
import Modal from '@anwb/poncho/components/modal';

import { User } from '../../../types/users/user';
import { isUser } from './types/isUser';

type DeleteModalProps = {
	obsoleteItem?: User;
	modalOpen: boolean;
	setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
	onDeleteHandler: () => void;
};

export const DeleteModal = ({
	obsoleteItem,
	modalOpen,
	setDeleteModalOpen,
	onDeleteHandler,
}: DeleteModalProps) =>
	obsoleteItem && (
		<Modal
			data-test={`delete-${isUser(obsoleteItem) ? 'user' : 'relation'}-modal`}
			title={`${isUser(obsoleteItem) ? 'Gebruiker' : 'Relatie'} verwijderen`}
			variant="dialog"
			open={modalOpen}
			buttonText="Verwijderen"
			cancelButtonText="Annuleren"
			onOpenChange={setDeleteModalOpen}
			size="m"
			buttonHandler={onDeleteHandler}
		>
			<Typography>
				Weet je zeker dat je{' '}
				<em>
					{isUser(obsoleteItem)
						? `${obsoleteItem.firstName} ${obsoleteItem.lastName}`
						: `${obsoleteItem.relationName}`}
				</em>{' '}
				wilt verwijderen?
			</Typography>
		</Modal>
	);
