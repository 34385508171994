import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';

import { ActionType } from '../../../../../../../types/cases/common-case';
import { getCaseActionColors } from '../../../../../../../utils/getCaseActionColors';

const iconContainerStyles = ({
	theme,
	$type,
}: DefaultThemeProps & { $type: ActionType }) => css`
	background-color: ${$type === 'ASSISTANCE_ACTION' &&
	getCaseActionColors(theme, 'yellow')};
	background-color: ${$type === 'CONTACT_MOMENT' &&
	getCaseActionColors(theme, 'blue')};
	background-color: ${$type === 'OFFERED_SOLUTION' &&
	getCaseActionColors(theme, 'orange')};
	border-top-left-radius: 0.8rem;
	border-bottom-left-radius: 0.8rem;
	flex: 0 0 102px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconContainer = styled.div`
	${iconContainerStyles}
`;
