import { formatPrice } from '@anwb/poncho/utilities/formatters';
import Table from '@anwb/poncho/components/table';

import { useInvoiceLinesStore } from '../../../../store/invoiceLinesStore';
import { Truncate } from '../../../../styles/table.styled';
import { UseInvoiceLinesStore } from '../../../../types/invoices/invoice-lines-store';
import { AnyPage } from '../../../../types/unions';
import { CustomPagination } from '../../../Common/CustomPagination/CustomPagination';
import {
	DescriptionHeaderCell,
	HeaderCell,
	PriceRowCell,
} from './styles/invoice-lines-table.styled';

type InvoiceLinesTableProps = {
	invoiceNumber: string;
	loadPage: (pageNumber: number) => void;
};

export const InvoiceLinesTable = ({
	invoiceNumber,
	loadPage,
}: InvoiceLinesTableProps) => {
	let totalPrice = 0;

	const { page } = useInvoiceLinesStore((state: UseInvoiceLinesStore) => ({
		page: state.page,
	}));

	page.items.forEach((line) => {
		totalPrice += line.amount;
	});

	return (
		<>
			<Table>
				<Table.Header>
					<Table.Row>
						<HeaderCell>Aantal</HeaderCell>
						<DescriptionHeaderCell>Omschrijving</DescriptionHeaderCell>
						<HeaderCell>Stukprijs &euro; incl. BTW</HeaderCell>
						<HeaderCell>Totaal &euro; incl. BTW</HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{page.items.map((line, idx) => (
						<Table.Row key={`${invoiceNumber}-${idx}`}>
							<Table.Column>{`${line.quantity}x`}</Table.Column>
							<Table.Column>
								<Truncate $lines={3}>{line.description}</Truncate>
							</Table.Column>
							<PriceRowCell>
								{formatPrice(line.unitPrice, { euroSign: false })}
							</PriceRowCell>
							<PriceRowCell>
								{formatPrice(line.amount, { euroSign: false })}
							</PriceRowCell>
						</Table.Row>
					))}
				</Table.Body>
				<Table.Body>
					<Table.Row>
						<Table.Column> </Table.Column>
						<Table.Column colSpan={2}>
							<b>Totaal inclusief BTW</b>
						</Table.Column>
						<PriceRowCell>
							<b>{formatPrice(totalPrice, { euroSign: false })}</b>
						</PriceRowCell>
					</Table.Row>
				</Table.Body>
			</Table>
			<CustomPagination
				page={page}
				setPage={(newPage: AnyPage) => loadPage(newPage.pageNumber)}
			/>
		</>
	);
};
