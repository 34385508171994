import { Dispatch, SetStateAction, useEffect } from 'react';

import { Debtor } from '../../../../../types/relations/relation';
import { User } from '../../../../../types/users/user';

export const useFetchDebtors = (
	setDebtors: Dispatch<SetStateAction<Debtor[]>>,
	selectedItem?: User
) =>
	useEffect(() => {
		selectedItem &&
			setDebtors(
				selectedItem.debtors.map((debtor) => ({
					anwbRelationNumber: debtor.anwbRelationNumber,
					labels: debtor.labels || ['Onbekende debiteur'],
					contractNumbers: debtor.contractNumbers,
				}))
			);
	}, []);
