import { Invoices } from '../components/Invoices/Invoices';
import { Cases } from '../components/Cases/Cases';
// import { UserManagement } from '../components/UserManagement/UserManagement';
import { ContractorManagement } from '../components/ContractorManagement/ContractorManagement';
import { CasesType } from '../components/Cases/types/cases';
import { NavItem } from '../types/navigation';
import { usePhvCasesStore } from '../store/phvCasesStore';
import { useVhvCasesStore } from '../store/vhvCasesStore';
import { ContentHeader } from '../styles/page-layout.styled';
import { CognitoRole } from '../auth/types/auth';
import { useAllowAccess } from '../auth/hooks/useAllowAccess';

export const NAVIGATION = (casesType?: CasesType): NavItem[] => {
	const allowAccess = useAllowAccess();

	const navigation = [
		{
			path: '/',
			navPath: '/',
			title: 'Overzicht',
			child: <ContentHeader>Overzichtspagina</ContentHeader>,
			show: true,
		},
		{
			path: '/facturen/*',
			navPath: '/facturen',
			title: 'Facturen',
			child: <Invoices />,
			show: allowAccess('INVOICES'),
		},
		// {
		// 	path: '/gebruikersbeheer/*',
		// 	navPath: '/gebruikersbeheer',
		// 	title: 'Gebruikersbeheer',
		// 	child: <UserManagement />,
		// 	show: allowAccess('KEY_USER'),
		// },
		{
			path: '/relatiebeheer/*',
			navPath: '/relatiebeheer',
			title: 'Relatiebeheer',
			child: <ContractorManagement />,
			show: allowAccess('ADMIN'),
		},
	];

	if (!casesType) {
		navigation.splice(
			2,
			0,
			{
				path: '/dossiers-phv/*',
				navPath: '/dossiers-phv',
				title: 'Dossiers personenhulp',
				child: <Cases casesType="phv" store={usePhvCasesStore} />,
				show: allowAccess('PHV'),
			},
			{
				path: '/dossiers-vhv/*',
				navPath: '/dossiers-vhv',
				title: 'Dossiers voertuigenhulp',
				child: <Cases casesType="vhv" store={useVhvCasesStore} />,
				show: allowAccess('VHV'),
			}
		);
	}

	if (casesType) {
		navigation.push({
			path: '/dossiers/*',
			navPath: '/dossiers',
			title: 'Dossiers',
			child: (
				<Cases
					casesType={casesType}
					store={casesType === 'phv' ? usePhvCasesStore : useVhvCasesStore}
				/>
			),
			show: allowAccess(casesType.toUpperCase() as CognitoRole),
		});
	}

	return navigation;
};
