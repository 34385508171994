import { useNavigate } from 'react-router-dom';

import Table from '@anwb/poncho/components/table';
import { formatLicensePlate } from '@anwb/poncho/validations/validations-license-plate';

import { useCaseActionStoreVhv } from '../../../../../store/caseActionsStoreVhv';
import { useCaseActionStorePhv } from '../../../../../store/caseActionsStorePhv';
import {
	InvoiceNumberLink,
	InvoiceNumberTableCell,
	TableCell,
} from '../../../../../styles/table.styled';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import { formatDateTime } from '../../../../../utils/formatDate';
import { UseCaseActionsStore } from '../../../../../types/cases/case-actions-store';
import { PhvCaseSummary } from '../../../../../types/cases/phv-case';
import { VhvCaseSummary } from '../../../../../types/cases/vhv-case';
import { CasesType } from '../../../types/cases';
import {
	formatStatus,
	lookupPhvRecordCategory,
	lookupVhvIncidentCategory,
} from '../../../utils/general';
import { formatPersonName } from '../../../utils/formatPersonName';

type CaseSummaryRowProps = {
	caseSummary: PhvCaseSummary | VhvCaseSummary;
	casesType: CasesType;
};

export const CaseSummaryRow = ({
	caseSummary,
	casesType,
}: CaseSummaryRowProps) => {
	const navigate = useNavigate();
	const store =
		casesType === 'vhv' ? useCaseActionStoreVhv : useCaseActionStorePhv;

	const { clearFilters } = store((state: UseCaseActionsStore) => ({
		clearFilters: state.clearFilters,
	}));

	const reportType =
		casesType === 'phv'
			? lookupPhvRecordCategory((caseSummary as PhvCaseSummary).recordCategory)
			: lookupVhvIncidentCategory(
					(caseSummary as VhvCaseSummary).incidentCategory
				);
	const licenseOrStatus =
		casesType === 'phv'
			? formatStatus(caseSummary.status)
			: formatLicensePlate(
					(caseSummary as VhvCaseSummary).vehicle.licensePlate
				);

	return (
		<Table.Row>
			<InvoiceNumberTableCell>
				<InvoiceNumberLink
					variant="link-text"
					onClick={() => {
						clearFilters();
						navigate(`/dossiers-${casesType}/${caseSummary.caseNumber}`);
					}}
				>
					{caseSummary.caseNumber}
				</InvoiceNumberLink>
			</InvoiceNumberTableCell>
			<TableCell>
				{caseSummary.country ? capitalizeFirstLetter(caseSummary.country) : '-'}
			</TableCell>
			<TableCell>{reportType}</TableCell>
			<TableCell>
				{formatPersonName(caseSummary.assistanceRequester?.personName)}
			</TableCell>
			<TableCell>{licenseOrStatus}</TableCell>
			<TableCell $alignRight>
				{formatDateTime(caseSummary.reportDateTime)}
			</TableCell>
		</Table.Row>
	);
};
