import { FormElementsData } from '@anwb/poncho/types/types';

import { USER_RIGHTS_CATEGORIES } from '../../../constants/user-rights-categories';
import { ProcessContractor } from '../../../types/contractors/contractor';

const getDebtorKey = (relationNumberKey: string) => {
	const index = relationNumberKey.split('-').pop();
	return `debtor-${index}`;
};

const formatContractNumbers = (
	elementsData: FormElementsData,
	relationNumber: string
) =>
	Object.keys(elementsData)
		.filter((key) => key.indexOf(relationNumber) !== -1)
		.map((contractNumber) => elementsData[contractNumber]);

const formatDebtors = (elementsData: FormElementsData) =>
	Object.keys(elementsData)
		.filter((key) => key.indexOf('relation-number-') !== -1)
		.map((relationNumberKey) => ({
			relationNumber: elementsData[relationNumberKey],
			name: elementsData[getDebtorKey(relationNumberKey)],
			contractNumbers: formatContractNumbers(
				elementsData,
				elementsData[relationNumberKey] as string
			),
		}));

export const formatContractor = (elementsData: FormElementsData) =>
	({
		name: elementsData.name,
		debtors: formatDebtors(elementsData),
		firstName: elementsData.firstName,
		lastName: elementsData.lastName,
		email: elementsData.email,
		phoneNumber: elementsData.phoneNumber,
		userRights: USER_RIGHTS_CATEGORIES.filter(
			(category) => elementsData[category.code]
		).map((category) => category.code),
	}) as ProcessContractor;
