import { Page } from '../../types/page';
import {
	Contractor,
	ContractorPageable,
	SearchContractor,
} from '../../types/contractors/contractor';
import { toPage } from '../../utils/toPage';
import { getAllContractors } from './contractorsDB';

const getSelectedContractors = (
	searchContractor: SearchContractor,
	pageable: ContractorPageable
) => {
	const page = getContractors(searchContractor, pageable);

	return {
		items: page.items,
		pageNumber: page.pageNumber,
		totalPages: page.totalPages,
		totalItems: page.totalItems,
	} as Page<Contractor>;
};

const getContractors = (
	searchContractor: SearchContractor,
	pageable: ContractorPageable
) => {
	const allContractors = getAllContractors();

	if (!searchContractor) {
		return toPage<Contractor>(allContractors, pageable);
	}

	const filteredContractors = allContractors
		// eslint-disable-next-line no-nested-ternary
		.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
		.filter((aCase) => doFilter(aCase, searchContractor));
	return toPage<Contractor>(filteredContractors, pageable);
};

const doFilter = (contractor: Contractor, searchContractor: SearchContractor) =>
	matchesSearchTerm(contractor, searchContractor);

const matchesSearchTerm = (
	contractor: Contractor,
	searchContractor: SearchContractor
) => {
	if (searchContractor.query && searchContractor.query !== '') {
		const keysToSearch = ['name'];

		return keysToSearch.some(
			(key) =>
				(contractor[key as keyof Contractor] as string).indexOf(
					searchContractor.query as string
				) !== -1
		);
	}
	return !searchContractor.query || searchContractor.query !== '';
};

const mockedContractorApi = {
	getSelectedContractors,
};

export { mockedContractorApi };
