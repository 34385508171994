import { ReactNode } from 'react';

import { PanelHeader, PanelContent } from '@anwb/poncho/components/panel';
import Typography from '@anwb/poncho/components/typography';
import { IllustrativeIconsKey } from '@anwb/poncho/utilities/icon-utils';

import {
	ActionStatus,
	ActionType,
	ContactDirection,
} from '../../../../../../types/cases/common-case';
import { formatStatus } from '../../../../utils/general';
import {
	DateContainer,
	DetailsContainer,
	PanelContainer,
} from './styles/case-action-panel.styled';
import IconArea from './IconArea/IconArea';
import Tag from './Tag/Tag';

type CaseActionPanelProps = {
	iconVariant: IllustrativeIconsKey;
	actionType: ActionType;
	title: string;
	subTitle?: string;
	date: string;
	status?: ActionStatus;
	direction?: ContactDirection;
	children: ReactNode;
};

export const CaseActionPanelLayout = ({
	iconVariant,
	actionType,
	title,
	subTitle,
	date,
	status,
	direction,
	children,
}: CaseActionPanelProps) => (
	<PanelContainer data-test={actionType}>
		<IconArea type={actionType} variant={iconVariant} direction={direction} />
		<DetailsContainer>
			<PanelHeader title={title} subTitle={subTitle} />
			<PanelContent>{children}</PanelContent>
		</DetailsContainer>
		<DateContainer>
			<Typography variant="content-subtitle">{date}</Typography>
			{status && <Tag text={formatStatus(status)} />}
		</DateContainer>
	</PanelContainer>
);
