import { AxiosResponse } from 'axios';

export const handleDownload = (response: AxiosResponse, name: string) =>
	new Promise((resolve) => {
		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: response.data.type })
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', name);
		document.body.appendChild(link);
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
		resolve(true);
	});
