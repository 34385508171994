import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import Typography from '@anwb/poncho/components/typography';

const filterBlockStyles = ({ theme }: DefaultThemeProps) => css`
	width: 33.33%;
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	flex-wrap: wrap;
	margin-left: 1.2rem;
	overflow: hidden;

	:first-child {
		margin-left: 0;
	}

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
		width: 100%;
		margin-left: 0;

		:not(:last-child) {
			margin-bottom: 0.8rem;
		}
	}
`;

export const FilterBlock = styled.div`
	${filterBlockStyles}
`;

const titleStyles = () => css`
	width: 100%;
	white-space: nowrap;
`;

export const Title = styled(Typography).attrs({
	variant: 'content-subtitle',
	tagName: 'div',
})`
	${titleStyles}
`;

export const FieldContainer = styled.div`
	flex: 1 1 48.5%;
`;
