import styled, { css } from 'styled-components';
import { FormContainerFieldContainer } from '@anwb/poncho/components/form-container-field';
import { ButtonTertiary } from '@anwb/poncho/components/button';

const relationNumberInputContainerStyles = () => css`
	${FormContainerFieldContainer} {
		width: 150px !important;
		max-width: 150px !important;
	}
`;

export const RelationNumberInputContainer = styled.div`
	${relationNumberInputContainerStyles}
`;

const relationTrashContainerStyles = () => css`
	${FormContainerFieldContainer} {
		width: 60px !important;
	}
`;

export const RelationTrashContainer = styled.div`
	${relationTrashContainerStyles}
`;

const relationNumberInputRowStyles = () => css`
	display: flex;
	column-gap: 0.5rem;
`;

export const RelationNumberInputRow = styled.div`
	${relationNumberInputRowStyles}
`;

const fullWidthButtonTertiaryStyles = () => css`
	width: 100% !important;
	margin-bottom: 1rem !important;
`;

export const FullWidthButtonTertiary = styled(ButtonTertiary)`
	${fullWidthButtonTertiaryStyles}
`;
