import List from '@anwb/poncho/components/list';
import { formatFirstCaseToUpper } from '@anwb/poncho/utilities/formatters';

import { ContactMoment } from '../../../../../../types/cases/common-case';
import { formatDateTime } from '../../../../../../utils/formatDate';
import { CaseActionPanelLayout } from '../CaseActionPanelLayout/CaseActionPanelLayout';
import { getContactMomentListItems } from './utils/getContactMomentListItems';
import { getContactMomentIcon } from './utils/getContactMomentIcon';

export type CaseActionPanelProps = {
	action: ContactMoment;
};

export const PanelContactMoment = ({ action }: CaseActionPanelProps) => (
	<CaseActionPanelLayout
		iconVariant={getContactMomentIcon(action.media)}
		actionType={action.actionType}
		title={`${formatFirstCaseToUpper(action.role)} ${action.contactName}${action.phoneNumber ? ` (${action.phoneNumber})` : ''}`}
		subTitle={`${action.direction === 'IN' ? 'Inkomend' : 'Uitgaand'} contactmoment (${action.media.toLowerCase()})`}
		date={formatDateTime(action.actionDateTime)}
		direction={action.direction}
	>
		<List items={getContactMomentListItems(action)} />
	</CaseActionPanelLayout>
);
