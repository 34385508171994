import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import { FormState } from '@zama/types';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { useUsersStore } from '../../../store/usersStore';
import { useCurrentUserStore } from '../../../store/currentUserStore';
import { UseUsersStore } from '../../../types/users/users-store';
import { NewUser } from '../../../types/users/user';
import { postUser } from '../../../utils/userService';
import UserForm from '../common/UserForm/UserForm';
import { formatUser } from '../utils/formatUser';

export const AddUser = () => {
	const navigate = useNavigate();
	const [showError, setShowError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { setNotification } = useUsersStore((state: UseUsersStore) => ({
		setNotification: state.setNotification,
	}));
	const { currentUser } = useCurrentUserStore((state) => ({
		currentUser: state.currentUser,
	}));

	const onCancelHandler = () => navigate(-1);

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		setShowError(false);
		const { valid, elementsData } = formData as FormState;
		const newUser = formatUser<NewUser>(elementsData, currentUser);

		if (valid) {
			postUser(newUser)
				.then(() => {
					setNotification({
						type: 'success',
						title: 'Gebruiker toegevoegd',
						description: `${newUser.firstName} ${newUser.lastName} is succesvol toegevoegd`,
					});
					navigate('/gebruikersbeheer');
				})
				.catch(() => {
					setShowError(true);
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}
	};

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Gebruiker toevoegen</ContentHeader>
				{showError && (
					<Notification
						dataTest="bad-response"
						variant="error"
						title="Door technische redenen is het niet gelukt de gebruiker toe te voegen. Probeer het opnieuw."
					/>
				)}
				<UserForm
					isLoading={isLoading}
					onCancelHandler={onCancelHandler}
					onSubmitHandler={onSubmitHandler}
				/>
			</Grid.Item>
		</Grid>
	);
};
