export const VEHICLE_TYPES = [
	{
		code: 'AANHANGWAGEN',
		label: 'Aanhangwagen',
	},
	{
		code: 'BEDRIJFSAUTO',
		label: 'Bedrijfsauto',
	},
	{
		code: 'BOOT',
		label: 'Boot',
	},
	{
		code: 'BROMFIETS',
		label: 'Bromfiets',
	},
	{
		code: 'BROMMOBIEL',
		label: 'Brommobiel',
	},
	{
		code: 'BUS',
		label: 'Bus',
	},
	{
		code: 'CAMPER',
		label: 'Camper',
	},
	{
		code: 'CARAVAN',
		label: 'Caravan',
	},
	{
		code: 'DRIEWIELIG_MOTORRIJTUIG',
		label: 'Driewielig motorrijtuig',
	},
	{
		code: 'FIETS',
		label: 'Fiets',
	},
	{
		code: 'LAADPAAL',
		label: 'Laadpaal',
	},
	{
		code: 'MOTORFIETS',
		label: 'Motorfiets',
	},
	{
		code: 'MOTORFIETS_MET_ZIJSPAN',
		label: 'Motorfiets met zijspan',
	},
	{
		code: 'PERSONENAUTO',
		label: 'Personenauto',
	},
	{
		code: 'ROLSTOEL',
		label: 'Rolstoel',
	},
	{
		code: 'SCOOTMOBIEL',
		label: 'Scootmobiel',
	},
	{
		code: 'SUV_MVP',
		label: 'SUV/MVP',
	},
	{
		code: 'VOUWWAGEN',
		label: 'Vouwwagen',
	},
] as const;
