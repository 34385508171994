import { Pageable } from '../../../types/pageable';
import { Page } from '../../../types/page';
import {
	Contract,
	PhvCase,
	PhvCaseSummary,
	PolicyHolder,
	SearchPhvCase,
} from '../../../types/cases/phv-case';
import { CaseSort } from '../../../types/sort';
import { isInRange } from '../../../utils/isInRange';
import { matchesOption } from '../../../utils/matchesOptions';
import { toPage } from '../../../utils/toPage';
import { compare } from '../../../utils/compare';
import { PersonName } from '../../../types/cases/common-case';
import { getAllCases } from './phvCasesDB';

const getPhvCases = (
	searchPhvCase: SearchPhvCase,
	pageable: Pageable<CaseSort>
) => {
	const page = getCases(searchPhvCase, pageable);

	return {
		items: page.items,
		pageNumber: page.pageNumber,
		totalPages: page.totalPages,
		totalItems: page.totalItems,
	} as Page<PhvCase>;
};

const getPhvCase = (caseNumber: string) =>
	getAllCases().find(
		(foundCase) => foundCase.caseSummary.caseNumber === caseNumber
	) as PhvCase;

const getCases = (
	searchPhvCase: SearchPhvCase,
	pageable: Pageable<CaseSort>
) => {
	const allCases = getAllCases();
	const sorting = pageable.sort;
	doSort(allCases, sorting);

	if (!searchPhvCase) {
		return toPage<PhvCase>(allCases, pageable);
	}

	const filteredCases = allCases.filter((aCase) =>
		doFilter(aCase, searchPhvCase)
	);
	return toPage<PhvCase>(filteredCases, pageable);
};

const doSort = (cases: PhvCase[], sorting: CaseSort) => {
	switch (sorting) {
		case 'ASC_CASE_DATE':
			cases.sort((lhs: PhvCase, rhs: PhvCase) =>
				compare(
					lhs.caseSummary.reportDateTime.getTime(),
					rhs.caseSummary.reportDateTime.getTime()
				)
			);
			break;
		case 'DESC_CASE_DATE':
			cases.sort((lhs: PhvCase, rhs: PhvCase) =>
				compare(
					rhs.caseSummary.reportDateTime.getTime(),
					lhs.caseSummary.reportDateTime.getTime()
				)
			);
			break;
		case 'ASC_CASE_NUMBER':
			cases.sort((lhs: PhvCase, rhs: PhvCase) =>
				compare(lhs.caseSummary.caseNumber, rhs.caseSummary.caseNumber)
			);
			break;
		case 'DESC_CASE_NUMBER':
			cases.sort((lhs: PhvCase, rhs: PhvCase) =>
				compare(rhs.caseSummary.caseNumber, lhs.caseSummary.caseNumber)
			);
			break;
		default:
			cases.sort((lhs: PhvCase, rhs: PhvCase) =>
				compare(
					rhs.caseSummary.reportDateTime.getTime(),
					lhs.caseSummary.reportDateTime.getTime()
				)
			);
	}
};

const doFilter = (vhvCase: PhvCase, searchVhvCase: SearchPhvCase) =>
	matchesSearchTerm(vhvCase, searchVhvCase) &&
	matchesRangeTerms(vhvCase, searchVhvCase) &&
	matchesOptionTerms(vhvCase, searchVhvCase);

const matchesSearchTerm = (phvCase: PhvCase, searchPhvCase: SearchPhvCase) => {
	const { contract } = phvCase.caseSummary;
	const { policyHolder } = contract;
	const { personName } = policyHolder;

	if (searchPhvCase.query && searchPhvCase.query !== '') {
		const keysToSearch = [
			'policyNumber',
			'postalCode',
			'surname',
			'dob',
			'country',
			'caseNumber',
		];

		return keysToSearch.some(
			(key) =>
				(phvCase.caseSummary[key as keyof PhvCaseSummary] ||
					contract[key as keyof Contract] ||
					policyHolder[key as keyof PolicyHolder] ||
					personName[key as keyof PersonName]) &&
				(
					phvCase.caseSummary[key as keyof PhvCaseSummary] ||
					contract[key as keyof Contract] ||
					policyHolder[key as keyof PolicyHolder] ||
					(personName[key as keyof PersonName] as string)
				)
					// this mock will be removed when real API is implemented, so won't fix
					// @ts-ignore
					.indexOf(searchPhvCase.query as string) !== -1
		);
	}
	return !searchPhvCase.query || searchPhvCase.query !== '';
};

const matchesRangeTerms = (vhvCase: PhvCase, searchCase: SearchPhvCase) =>
	isInRange<Date>(
		vhvCase.caseSummary.reportDateTime,
		searchCase.minDate,
		searchCase.maxDate
	);

const matchesOptionTerms = (aCase: PhvCase, searchCase: SearchPhvCase) =>
	matchesOption(searchCase.caseStates, aCase.caseSummary.status) &&
	matchesOption(
		searchCase.initialLocations,
		aCase.caseSummary.initialLocation
	) &&
	matchesOption(searchCase.passedOns, aCase.caseSummary.passedOn) &&
	matchesOption(
		searchCase.phvAssistanceCategories,
		aCase.caseSummary.assistanceCategory
	) &&
	matchesOption(
		searchCase.phvRecordCategories,
		aCase.caseSummary.recordCategory
	);

const mockedPhvCaseApi = {
	getPhvCase,
	getPhvCases,
};

export { mockedPhvCaseApi };
