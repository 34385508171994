import { PhvCase, SearchPhvCase } from '../types/cases/phv-case';
import { Pageable } from '../types/pageable';
import { Page } from '../types/page';
import { CaseSort } from '../types/sort';
import { mockedPhvCaseApi } from '../mocks/cases/phv/phvCaseApi';

export const getPhvCase = (caseNumber: string): PhvCase =>
	mockedPhvCaseApi.getPhvCase(caseNumber);

export const getPhvCases = (
	searchPhvCase: SearchPhvCase,
	pageable: Pageable<CaseSort>
): Promise<Page<PhvCase>> =>
	Promise.resolve(mockedPhvCaseApi.getPhvCases(searchPhvCase, pageable));
