import { Contractor } from '../../types/contractors/contractor';

const contractorsDB: Contractor[] = [
	{
		id: '233356',
		name: 'Aegon',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Karel',
		lastName: 'Grutjes',
		email: 'kgrutjes@grutteke.nl',
		phoneNumber: '0675432219',
		userRights: ['REPORTS', 'INVOICES', 'VHV_CASES'],
	},
	{
		id: '656532',
		name: 'Mercedes',
		debtors: [
			{
				relationNumber: '123456789',
				name: 'Mock debtor 1',
				contractNumbers: ['989957', '234875'],
			},
			{
				relationNumber: '987654321',
				name: 'Mock debtor 2',
				contractNumbers: ['845746'],
			},
			{ relationNumber: '485566644' },
		],
		firstName: 'Joop',
		lastName: 'Erwtjes',
		email: 'jerwtjes@erwtekke.nl',
		phoneNumber: '0645781249',
		userRights: ['REPORTS', 'INVOICES', 'VHV_CASES', 'PHV_CASES'],
	},
	{
		id: '124245',
		name: 'Peugeot',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Simone',
		lastName: 'van Doelen',
		email: 'svdoelen@doelleke.nl',
		phoneNumber: '0614878786',
		userRights: ['REPORTS', 'INVOICES'],
	},
	{
		id: '654354',
		name: 'Unigarant',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Klaas',
		lastName: 'Heelvaak',
		email: 'kheelvaak@echtheelvaak.nl',
		phoneNumber: '0600457911',
		userRights: ['INVOICES', 'VHV_CASES', 'PHV_CASES'],
	},
	{
		id: '243524',
		name: 'Puch',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Kristyl',
		lastName: 'de Wit',
		email: 'kdewit@witteke.nl',
		phoneNumber: '0645365332',
		userRights: ['REPORTS', 'INVOICES', 'VHV_CASES', 'PHV_CASES'],
	},
	{
		id: '454545',
		name: 'Nationale-Nederlanden Verzekering Services',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Ines',
		lastName: 'Nootenboom',
		email: 'inooteboom@boomkes.nl',
		phoneNumber: '0600003399',
		userRights: ['VHV_CASES', 'PHV_CASES'],
	},
	{
		id: '967757',
		name: 'Ford',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Jeroen',
		lastName: 'Fatsoen',
		email: 'jfatsoen@fatsoen.nl',
		phoneNumber: '0674653928',
		userRights: ['REPORTS'],
	},
	{
		id: '868688',
		name: 'Bugatti',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Joost',
		lastName: 'Enkelvaart',
		email: 'jenkelvaart@vaartjes.nl',
		phoneNumber: '0675678843',
		userRights: ['INVOICES', 'VHV_CASES'],
	},
	{
		id: '132342',
		name: 'BMW',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Camiel',
		lastName: 'Fragiel',
		email: 'cfragiel@ielleke.nl',
		phoneNumber: '0641412536',
		userRights: ['REPORTS', 'INVOICES', 'VHV_CASES', 'PHV_CASES'],
	},
	{
		id: '657687',
		name: 'Arval',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Pim',
		lastName: 'Stroop',
		email: 'pstroop@plakt.nl',
		phoneNumber: '0667487564',
		userRights: ['INVOICES'],
	},
	{
		id: '465646',
		name: 'Alphabet',
		debtors: [
			{ relationNumber: '123456789', name: 'Mock debtor 1' },
			{ relationNumber: '987654321', name: 'Mock debtor 2' },
		],
		firstName: 'Joyce',
		lastName: 'Graat',
		email: 'jgraat@graatteke.nl',
		phoneNumber: '0688738889',
		userRights: ['REPORTS', 'VHV_CASES'],
	},
];

export const mockDeleteContractor = (id: string) => {
	const index = contractorsDB.findIndex((contractor) => contractor.id === id);
	index > -1 && contractorsDB.splice(index, 1);
	return Promise.resolve();
};

export const getAllContractors = () => contractorsDB.map((aCase) => aCase);
