import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { User } from '../types/users/user';

type CurrentUserStore = {
	currentUser: User;
	setCurrentUser: (user: User) => void;
};

const initialUser = {
	userId: '',
	relationId: '',
	relationName: '',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	keyUser: false,
	adminUser: false,
	roles: [],
	debtors: [],
};

export const useCurrentUserStore = createWithEqualityFn<CurrentUserStore>(
	(set) => ({
		currentUser: initialUser,
		setCurrentUser: (currentUser: User) => set({ currentUser }),
	}),
	shallow
);
