import Typography from '@anwb/poncho/components/typography';
import Tooltip from '@anwb/poncho/components/tooltip';

import {
	PaymentStatusContainer,
	StatusOpen,
} from '../styles/general-invoice-details.styled';

export const PaymentStatusButton = () => (
	<PaymentStatusContainer>
		<StatusOpen>Open</StatusOpen>
		<Typography variant="link-text"> Op overgemaakt zetten</Typography>
		<Tooltip>
			&apos;Op overgemaakt&apos; is een status voor de eigen administratie.
			Zodra ANWB de betaling heeft ontvangen wordt de status omgezet naar
			&apos;betaald&apos;.
		</Tooltip>
	</PaymentStatusContainer>
);
