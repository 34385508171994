import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import { FormState } from '@zama/types';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { useContractorsStore } from '../../../store/contractorsStore';
import { UseContractorsStore } from '../../../types/contractors/contractor-store';
import { ContractorForm } from '../common/ContractorForm/ContractorForm';
import { formatContractor } from '../utils/formatContractor';
import { addContractor } from './utils/addContractor';

export const AddContractor = () => {
	const navigate = useNavigate();
	const [showError, setShowError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { setNotification } = useContractorsStore(
		(state: UseContractorsStore) => ({
			setNotification: state.setNotification,
		})
	);

	const onCancelHandler = () => navigate(-1);

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		setShowError(false);
		const { valid, elementsData } = formData as FormState;

		if (valid) {
			const newContractor = formatContractor(elementsData);
			addContractor(newContractor)
				.then((data) => {
					console.info(data);
					setNotification({
						type: 'success',
						title: 'Relatie toegevoegd',
						description: `${newContractor.name} is succesvol toegevoegd`,
					});
					navigate('/relatiebeheer');
				})
				.catch(() => {
					setShowError(true);
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}
	};

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Relatie toevoegen</ContentHeader>
				{showError && (
					<Notification
						dataTest="bad-response"
						variant="error"
						title="Door technische redenen is het niet gelukt de gebruiker toe te voegen. Probeer het opnieuw."
					/>
				)}
				<ContractorForm
					isLoading={isLoading}
					onCancelHandler={onCancelHandler}
					onSubmitHandler={onSubmitHandler}
				/>
			</Grid.Item>
		</Grid>
	);
};
