import ReactDOM from 'react-dom/client';

import api from './api/api';
import { processJwt } from './auth/processJwt';
import Application from './application';

const element = document.getElementById('app-container');
const renderApplication = () => {
	const root = ReactDOM.createRoot(element as HTMLElement);
	root.render(<Application />);
};

processJwt((storedAccessToken: string) => {
	api.defaults.headers.common.Authorization = storedAccessToken;
	renderApplication();
});
