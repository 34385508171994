import { getInvoiceFile } from '../../../../../utils/invoiceService';
import { Invoice } from '../../../../../types/invoices/invoice';
import { handleDownload } from './handleDownload';

export const downloadInvoice = (
	invoiceDetails: Invoice,
	setShowError: React.Dispatch<React.SetStateAction<boolean>>
) =>
	getInvoiceFile(invoiceDetails.invoiceURL)
		.then((response) => {
			handleDownload(response, `factuur-${invoiceDetails.invoiceNumber}`).catch(
				(error) => {
					console.error(error);
					setShowError(true);
				}
			);
		})
		.catch((error) => {
			console.error(error);
			setShowError(true);
		});
