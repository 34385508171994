import { formatPrice } from '@anwb/poncho/utilities/formatters';
import Typography from '@anwb/poncho/components/typography';

import { Invoice } from '../../../../../types/invoices/invoice';
import { DescriptionListItem } from '../../../../../types/poncho';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import { formatDate } from '../../../../../utils/formatDate';
import { formatInvoiceType, formatOpenClosed } from './invoiceFieldFormatters';

export const createInvoiceDetailDescriptionList = (
	invoiceDetails: Invoice
): DescriptionListItem[] => {
	const descriptionList = [
		{ key: 'Status', value: formatOpenClosed(invoiceDetails.paymentState) },
		{
			key: 'Factuursoort',
			value: formatInvoiceType(invoiceDetails.invoiceType),
		},
		{ key: 'Omschrijving', value: invoiceDetails.description },
		{
			key: 'Credit/Debet',
			value: capitalizeFirstLetter(invoiceDetails.creditOrDebet),
		},
		{ key: 'Factuurdatum', value: formatDate(invoiceDetails.invoiceDate) },
		{
			key: 'Totaal €',
			value: formatPrice(invoiceDetails.amount, { euroSign: false }),
		},
		{
			key: 'Factuur',
			value: (
				<Typography
					variant="link-text"
					onClick={() => console.info(`download ${invoiceDetails.invoiceURL}`)}
				>
					{invoiceDetails.invoiceNumber}
				</Typography>
			),
		},
	];

	if (invoiceDetails.assistance?.assistanceType === 'VHV') {
		descriptionList.splice(2, 0, {
			key: 'Contractnaam',
			value: invoiceDetails.contractName,
		});
	}

	return descriptionList;
};
