import { Pageable } from '../types/pageable';
import { Page } from '../types/page';
import { VhvCase, SearchVhvCase } from '../types/cases/vhv-case';
import { CaseSort } from '../types/sort';
import { mockedVhvCaseApi } from '../mocks/cases/vhv/vhvCaseApi';

export const getVhvCase = (caseNumber: string): VhvCase =>
	mockedVhvCaseApi.getVhvCase(caseNumber);

export const getVhvCases = (
	searchVhvCase: SearchVhvCase,
	pageable: Pageable<CaseSort>
): Promise<Page<VhvCase>> =>
	Promise.resolve(mockedVhvCaseApi.getVhvCases(searchVhvCase, pageable));
