import styled, { css } from 'styled-components';
import DescriptionList from '@anwb/poncho/components/description-list';

const actionLocationDescriptionListStyles = () => css`
	padding: 0.5em 0;
`;

export const ActionLocationDescriptionList = styled(DescriptionList)`
	${actionLocationDescriptionListStyles};
`;
