import { Action } from '../../../../../types/cases/common-case';
import { CaseActionSort } from '../../../../../types/sort';
import { compare } from '../../../../../utils/compare';

export const doSort = (actions: Action[], sorting: CaseActionSort) => {
	switch (sorting) {
		case 'ASC_CASE_ACTION_DATE':
			actions.sort((lhs: Action, rhs: Action) =>
				compare(lhs.sortingDate.getTime(), rhs.sortingDate.getTime())
			);
			break;
		case 'DESC_CASE_ACTION_DATE':
			actions.sort((lhs: Action, rhs: Action) =>
				compare(rhs.sortingDate.getTime(), lhs.sortingDate.getTime())
			);
			break;
		case 'ASC_CASE_ACTION_STATUS':
			actions.sort((lhs: Action, rhs: Action) =>
				compare(lhs.status, rhs.status)
			);
			break;
		case 'DESC_CASE_ACTION_STATUS':
			actions.sort((lhs: Action, rhs: Action) =>
				compare(rhs.status, lhs.status)
			);
			break;
		default:
			actions.sort((lhs: Action, rhs: Action) =>
				compare(rhs.sortingDate.getTime(), lhs.sortingDate.getTime())
			);
	}
};
