import Pagination from '@anwb/poncho/components/pagination';

import { AnyPage } from '../../../types/unions';
import { PaginationWrapper } from './styles/pagination.styled';

type PaginationProps = {
	page: AnyPage;
	setPage: (page: AnyPage) => void;
};

export const CustomPagination = ({ page, setPage }: PaginationProps) =>
	page.totalPages <= 1 ? null : (
		<PaginationWrapper data-test="custom-pagination">
			<Pagination
				totalPages={page.totalPages}
				currentPage={page.pageNumber}
				onPaginationChange={(pageNumber: number) =>
					setPage({ ...page, pageNumber })
				}
			/>
		</PaginationWrapper>
	);
