import { Outlet } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';

import AppHeader from './AppHeader/AppHeader';

const PageLayout = () => (
	<Grid constrainedWidth>
		<Grid.Item fullWidth>
			<AppHeader />
			<Outlet />
		</Grid.Item>
	</Grid>
);

export default PageLayout;
