import DescriptionList from '@anwb/poncho/components/description-list';
import { Panel } from '@anwb/poncho/components/panel';

import { Invoice } from '../../../../types/invoices/invoice';
import { DetailPanelHeader } from '../../../Common/DetailPanelHeader/DetailPanelHeader';
import { createInvoiceDetailDescriptionList } from './utils/createInvoiceDetailDescriptionList';

type GeneralInvoiceDetailsProps = {
	invoice: Invoice;
};

export const GeneralInvoiceDetails = ({
	invoice,
}: GeneralInvoiceDetailsProps) => (
	<Panel hasOuterSpacing={false}>
		<Panel.Content>
			<DetailPanelHeader title="Factuurgegevens" />
			<DescriptionList items={createInvoiceDetailDescriptionList(invoice)} />
		</Panel.Content>
	</Panel>
);
