import styled, { css, DefaultThemeProps } from 'styled-components';

const BUTTON_COLOR = 'rgba(0, 150, 218, 1)';
const BUTTON_COLOR_TRANSPARENT = 'rgba(0, 150, 218, 0.5)';

const linkStyles = ({
	theme,
	$isActive,
}: DefaultThemeProps & { $isActive: boolean }) => css`
	height: 48.8px;
	display: block;
	text-decoration: none;
	border-radius: 12px;
	color: ${theme.colors.blanc.textButton};
	padding: 13px;
	margin: 0;
	border: none;
	background-color: ${$isActive && BUTTON_COLOR_TRANSPARENT};

	&:hover {
		color: ${theme.colors.blanc.textButton};
	}

	&:hover {
		color: ${theme.colors.blanc.textButton};
		background-color: ${BUTTON_COLOR};
	}

	&:focus {
		color: ${theme.colors.blanc.textButton};
		background-color: ${BUTTON_COLOR_TRANSPARENT};
	}
`;

export const StyledLink = styled.a`
	${linkStyles}
`;
