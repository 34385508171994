import { Mileage, MileageUnit } from '../../../types/cases/vhv-case';

const getMileageUnit = (unit: MileageUnit) => {
	switch (unit) {
		case 'ZEEMIJL':
			return 'nmi';
		case 'MIJL':
			return 'mi';
		case 'KILOMETER':
			return 'km';
		case 'ONBEKEND':
		default:
			return '';
	}
};

export const generateMilage = (mileage?: Mileage) => {
	if (mileage && mileage.amount && mileage.unit) {
		const unit = getMileageUnit(mileage.unit);
		return `${mileage.amount}${unit && ` ${unit}`}`;
	}
	return undefined;
};
