import { AxiosResponse } from 'axios';

import api from '../api/api';
import {
	API_URL_CONTRACTS,
	API_URL_KEY_USERS,
	API_URL_LABEL,
	API_URL_USERS,
} from '../api/constants/urls';
import {
	ProcessRelation,
	RelationPageable,
	SearchRelation,
} from '../types/relations/relation';
import { Contract } from '../types/relations/contract';
import { User } from '../types/users/user';
import { toPageParams, toQueryParams } from './toQueryParameters';

export const getRelations = (
	searchRelation: SearchRelation,
	pageable: RelationPageable
) => {
	const searchParams = toQueryParams<SearchRelation>(searchRelation);
	const pageParams = toPageParams<RelationPageable>(pageable);
	const url = `${API_URL_KEY_USERS}?${pageParams}&${searchParams}`;
	return api.get(url);
};

export const getLabel = (anwbRelationNumber: string) =>
	api.get(`${API_URL_LABEL}?anwbRelationNumber=${anwbRelationNumber}`);

export const getContracts = (
	anwbRelationNumbers: string[]
): Promise<AxiosResponse<{ data: Contract[] }>> => {
	if (anwbRelationNumbers.length === 0) {
		return Promise.reject('No anwbRelationNumbers provided');
	}

	const params = new URLSearchParams({
		anwbRelationNumbers: anwbRelationNumbers.join(','),
	});

	const url = `${API_URL_CONTRACTS}?${params}`;
	return api.get(url);
};

export const postRelation = (relation: ProcessRelation) =>
	api.post(API_URL_USERS, relation);

export const updateRelation = (relation: User) =>
	api.put(API_URL_USERS, relation);

export const deleteRelation = (relationId: string) =>
	api.delete(`${API_URL_KEY_USERS}?relationId=${relationId}`);
