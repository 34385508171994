import { useEffect, useState } from 'react';

import { UserSort } from '../../../../types/sort';
import { Pageable } from '../../../../types/pageable';
import { UseUsersStore } from '../../../../types/users/users-store';
import { Page } from '../../../../types/page';
import { User } from '../../../../types/users/user';
import { useUsersStore } from '../../../../store/usersStore';
import { getUsers } from '../../../../utils/userService';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';
import { useCurrentUserStore } from '../../../../store/currentUserStore';

const PAGE_SIZE = 5;

const pageable = (pageNumber: number, sorting: UserSort) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
		sort: sorting,
	}) as Pageable<UserSort>;

const useProcessUsers = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { currentUser } = useCurrentUserStore((state) => ({
		currentUser: state.currentUser,
	}));
	const { filters, setNotification, setPage, page, sorting } = useUsersStore(
		(state: UseUsersStore) => ({
			filters: state.filters,
			setNotification: state.setNotification,
			page: state.page,
			setPage: state.setPage,
			sorting: state.sorting,
		})
	);

	const loadPage = (pageNumber: number) => {
		setNotification(undefined);
		setShowSpinner(true);
		getUsers(currentUser.relationId, filters, pageable(pageNumber, sorting))
			.then((response) => {
				setPage(response.data as Page<User>);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			(() => {
				loadPage(page.pageNumber);
			})();
		}, 750);

		return () => clearTimeout(timeout);
	}, [filters, sorting, currentUser]);

	return { showSpinner, loadPage };
};

export default useProcessUsers;
