import Icon from '@anwb/poncho/components/icon';
import { IllustrativeIconsKey } from '@anwb/poncho/utilities/icon-utils';

import {
	ActionType,
	ContactDirection,
} from '../../../../../../types/cases/common-case';
import { IconContainer } from './styles/icon-area.styled';

export type IconAreaProps = {
	type: ActionType;
	variant: IllustrativeIconsKey;
	direction?: ContactDirection;
};

const IconArea = ({ type, variant, direction }: IconAreaProps) => (
	<IconContainer $type={type}>
		<Icon variant={variant} size="jumbo-xl" type="illustrative" />
		{direction && (
			<Icon
				variant={direction === 'IN' ? 'arrow-left' : 'arrow-right'}
				size="lg"
				type="ui"
			/>
		)}
	</IconContainer>
);

export default IconArea;
