import { Pageable } from '../types/pageable';
import { ReportSort } from '../types/sort';
import { Report, SearchReport } from '../types/reports/report';

import { Page } from '../types/page';
import { API_URL_REPORTS } from '../api/constants/urls';
import { toPageParams, toQueryParams } from './toQueryParameters';

export const getReports = (
	searchReport: SearchReport,
	pageable: Pageable<ReportSort>
) => {
	const searchParams = toQueryParams(searchReport);
	if (searchParams === null) {
		return Promise.reject('the filter is not valid');
	}
	const pageParams = toPageParams(pageable);
	const url = `${API_URL_REPORTS}?${pageParams}&${searchParams}`;
	console.info('url:', url);

	return Promise.resolve({
		data: {
			pageNumber: 1,
			totalItems: 2,
			totalPages: 1,
			items: [
				{
					id: '1',
					fileName: 'some-file.xsl',
					type: 'EXCEL',
					date: '2024-02-01',
				},
				{
					id: '2',
					fileName: 'some-other-file.doc',
					type: 'MS WORD',
					date: '2024-07-01',
				},
			],
		} as Page<Report>,
	});
	// return api.get(url);
};
