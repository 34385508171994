import styled, { css } from 'styled-components';

const fieldWrapperStyles = () => css`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.2rem;
	gap: 0.5rem;
	flex-wrap: wrap;
`;

export const FieldWrapper = styled.div`
	${fieldWrapperStyles};
`;

const fieldContainerStyles = () => css`
	flex: 1 1 48.5%;
`;

export const FieldContainer = styled.div`
	${fieldContainerStyles};
`;
