import { Checkbox } from '@anwb/poncho/components/forms';

import { CheckboxGroupTypeAll } from '../../../../../types/filters';
import { SearchInvoice } from '../../../../../types/invoices/invoice';
import { SearchPhvCase } from '../../../../../types/cases/phv-case';
import { SearchVhvCase } from '../../../../../types/cases/vhv-case';
import { SearchUser } from '../../../../../types/users/user';
import { MakeRequired } from '../../../../../types/tools';
import { FilterData } from '../../types/filter';
import {
	CategoryIndicator,
	CheckboxContainer,
	CheckboxSectionContainer,
	CheckboxWrapper,
} from './styles/checkbox-section.styled';
import { generateNewFilters } from './utils/generateNewFilters';

export type CheckboxSectionProps = {
	group: MakeRequired<FilterData, 'options'>;
	filters: SearchInvoice & SearchPhvCase & SearchVhvCase & SearchUser;
	updateCheckboxGroup: (
		fieldName: CheckboxGroupTypeAll,
		newSelection: string[]
	) => void;
	parent?: string;
};

const CheckboxSection = ({
	group,
	filters,
	updateCheckboxGroup,
	parent,
}: CheckboxSectionProps) => {
	const options = Array.isArray(group.options)
		? group.options
		: group.options(filters);
	return (
		<CheckboxWrapper>
			{options.map((option) => (
				<>
					<CheckboxContainer>
						<Checkbox
							key={option.value}
							dataTest={option.value}
							name={group.name || ''}
							value={option.value}
							onChange={() => {
								updateCheckboxGroup(
									group.name as CheckboxGroupTypeAll,
									generateNewFilters(
										filters[group.name as CheckboxGroupTypeAll] as string[],
										option,
										options.map((groupOption) => groupOption.value),
										parent
									)
								);
							}}
							checked={(
								filters[group.name as CheckboxGroupTypeAll] as string[]
							)?.includes(option.value)}
							reference={option.value}
						>
							{option.label}
						</Checkbox>
						{option.color && <CategoryIndicator $color={option.color} />}
					</CheckboxContainer>
					{option.options && (
						<CheckboxSectionContainer>
							<CheckboxSection
								group={{
									name: group.name,
									type: 'checkboxGroup',
									active: true,
									label: '',
									options: option.options,
								}}
								filters={filters}
								updateCheckboxGroup={updateCheckboxGroup}
								parent={option.value}
							/>
						</CheckboxSectionContainer>
					)}
				</>
			))}
		</CheckboxWrapper>
	);
};

export default CheckboxSection;
