import { jwtDecode } from 'jwt-decode';

import { CognitoRole, DecodedJwt, Tokens } from '../types/auth';

export const useAllowAccess = () => {
	const getAllowedGroups = () => {
		const storedAccessToken = localStorage.getItem(Tokens.AccessToken);
		if (storedAccessToken) {
			try {
				const decodedToken: DecodedJwt = jwtDecode(storedAccessToken);
				if (decodedToken && decodedToken['cognito:groups'])
					return decodedToken['cognito:groups'];
			} catch (error) {
				console.error(error);
			}
		}
		console.warn('Could not retrieve user rights from stored token...');
		return [];
	};

	const allowedGroups = getAllowedGroups();

	const allowAccess = (group: CognitoRole) =>
		allowedGroups.indexOf(group) !== -1;

	return allowAccess;
};
