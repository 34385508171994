import {
	AnyAction,
	AssistanceAction,
} from '../../../../../types/cases/common-case';
import { CaseActionSort } from '../../../../../types/sort';
import { compare } from '../../../../../utils/compare';

export const doSort = (actions: AnyAction[], sorting: CaseActionSort) => {
	switch (sorting) {
		case 'ASC_CASE_ACTION_DATE':
			actions.sort((lhs: AnyAction, rhs: AnyAction) =>
				compare(
					new Date(lhs.sortingDateTime).getTime(),
					new Date(rhs.sortingDateTime).getTime()
				)
			);
			break;
		case 'DESC_CASE_ACTION_DATE':
			actions.sort((lhs: AnyAction, rhs: AnyAction) =>
				compare(
					new Date(rhs.sortingDateTime).getTime(),
					new Date(lhs.sortingDateTime).getTime()
				)
			);
			break;
		case 'ASC_CASE_ACTION_STATUS':
			actions.sort((lhs: AnyAction, rhs: AnyAction) =>
				compare(
					(lhs as AssistanceAction).actionStatus,
					(rhs as AssistanceAction).actionStatus
				)
			);
			break;
		case 'DESC_CASE_ACTION_STATUS':
			actions.sort((lhs: AnyAction, rhs: AnyAction) =>
				compare(
					(rhs as AssistanceAction).actionStatus,
					(lhs as AssistanceAction).actionStatus
				)
			);
			break;
		default:
			actions.sort((lhs: AnyAction, rhs: AnyAction) =>
				compare(
					new Date(rhs.sortingDateTime).getTime(),
					new Date(lhs.sortingDateTime).getTime()
				)
			);
	}
};
