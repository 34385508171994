import Filter from './Filter/Filter';
import { SearchBar } from './SearchBar/SearchBar';
import {
	FilterContainer,
	FilterPanel,
} from './styles/search-and-filter-invoice-rule.styled';

const SearchAndFilterInvoiceLine = () => (
	<FilterPanel>
		<SearchBar />
		<FilterContainer>
			<Filter
				title="Factuurregel stukprijs €"
				from={{ id: 'minUnitPrice', placeholder: 'Bijv.: 10' }}
				to={{ id: 'maxUnitPrice', placeholder: 'Bijv.: 100' }}
			/>

			<Filter
				title="Factuurregel totaalbedrag €"
				from={{ id: 'minAmount', placeholder: 'Bijv.: 10' }}
				to={{ id: 'maxAmount', placeholder: 'Bijv.: 100' }}
			/>

			<Filter
				title="Factuurregel Aantal"
				from={{ id: 'minQuantity', placeholder: 'Bijv.: 10' }}
				to={{ id: 'maxQuantity', placeholder: 'Bijv.: 35' }}
			/>
		</FilterContainer>
	</FilterPanel>
);

export default SearchAndFilterInvoiceLine;
