import { Pageable } from '../types/pageable';
import { CaseSort } from '../types/sort';
import { SearchVhvCase } from '../types/cases/vhv-case';
import api from '../api/api';
import { API_URL_VHV_CASES } from '../api/constants/urls';
import { VHV_CASES_FILTER } from '../components/Common/Filter/constants/case-filter-data';
import { toPageParams, toQueryParams } from './toQueryParameters';
import { stripSearch } from './stripSearch';

export const getVhvCases = (
	searchVhvCase: SearchVhvCase,
	pageable: Pageable<CaseSort>
) => {
	const strippedSearchVhvCase = stripSearch(
		searchVhvCase as Record<string, string | string[]>,
		VHV_CASES_FILTER
	);
	const searchParams = toQueryParams(strippedSearchVhvCase);
	if (searchParams === null) {
		return Promise.reject('the filter is not valid');
	}
	const pageParams = toPageParams<Pageable<CaseSort>>(pageable);
	const url = `${API_URL_VHV_CASES}?${pageParams}&${searchParams}`;
	return api.get(url);
};
