import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { DEFAULT_CASE_ACTION_SORTING } from '../../constants/case-action-sortings';
import { CheckboxGroupTypeAll, DateRangeType } from '../../types/filters';
import { Page } from '../../types/page';
import { UseCaseActionsStore } from '../../types/cases/case-actions-store';
import { AnyAction } from '../../types/cases/common-case';

export const INITIAL_PAGE: Page<AnyAction> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseCaseActionsStore = {
	accordionState: [],
	filters: {
		query: '',
		actionType: [],
		status: [],
		minDate: undefined,
		maxDate: undefined,
		locationChangeActions: [],
	},
	page: INITIAL_PAGE,
	sorting: DEFAULT_CASE_ACTION_SORTING.value,
	notification: undefined,
	setAccordionState: () => undefined,
	updateDateRange: () => undefined,
	clearFilters: () => undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	updateCheckboxGroup: () => undefined,
	updateSorting: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = (
	state: UseCaseActionsStore,
	fieldName: DateRangeType | CheckboxGroupTypeAll | 'query',
	newValue: Date | number | string[] | string | undefined
) => ({
	filters: {
		...state.filters,
		[fieldName]: newValue,
	},
});

export const createCaseActionsStore = () =>
	createWithEqualityFn<UseCaseActionsStore>(
		(set) => ({
			...initialState,
			setAccordionState: (accordionState) => set({ accordionState }),
			updateDateRange: (fieldName, minOrMaxDate) =>
				set((state) => updateFilter(state, fieldName, minOrMaxDate)),
			clearFilters: () =>
				set((state) => ({
					filters: initialState.filters,
					page: { ...state.page, pageNumber: 1 },
					sorting: DEFAULT_CASE_ACTION_SORTING.value,
				})),
			setPage: (page) => set({ page }),
			setQuery: (query) => set((state) => updateFilter(state, 'query', query)),
			updateCheckboxGroup: (fieldName, newSelection) =>
				set((state) => updateFilter(state, fieldName, newSelection)),
			updateSorting: (sorting): void =>
				set({ sorting, page: initialState.page }),
			setNotification: (notification) => set({ notification }),
		}),
		shallow
	);
