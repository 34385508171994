import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import { FormState } from '@zama/types';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { useRelationsStore } from '../../../store/relationsStore';
import { UseRelationsStore } from '../../../types/relations/relation-store';
import { postRelation } from '../../../utils/relationService';
import { RelationForm } from '../common/RelationForm/RelationForm';
import { formatRelation } from '../utils/formatRelation';

export const AddRelation = () => {
	const navigate = useNavigate();
	const [showError, setShowError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { setNotification } = useRelationsStore((state: UseRelationsStore) => ({
		setNotification: state.setNotification,
	}));

	const onCancelHandler = () => navigate(-1);

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		setShowError(false);
		const { valid, elementsData } = formData as FormState;

		if (valid) {
			const newRelation = formatRelation(elementsData);
			postRelation(newRelation)
				.then(() => {
					setNotification({
						type: 'success',
						title: 'Relatie toegevoegd',
						description: `${newRelation.relationName} is succesvol toegevoegd`,
					});
					navigate('/relatiebeheer');
				})
				.catch(() => {
					setShowError(true);
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}
	};

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Relatie toevoegen</ContentHeader>
				{showError && (
					<Notification
						dataTest="bad-response"
						variant="error"
						title="Door technische redenen is het niet gelukt de gebruiker toe te voegen. Probeer het opnieuw."
					/>
				)}
				<RelationForm
					isLoading={isLoading}
					onCancelHandler={onCancelHandler}
					onSubmitHandler={onSubmitHandler}
				/>
			</Grid.Item>
		</Grid>
	);
};
