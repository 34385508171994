import { ROLES_CATEGORIES } from '../../../../constants/roles-categories';
import { FilterData } from '../types/filter';

export const USER_MANAGEMENT_FILTER_DATA: FilterData[] = [
	{
		name: 'roles',
		type: 'checkboxGroup',
		active: true,
		label: 'Gebruikers met de volgende rechten tonen',
		options: ROLES_CATEGORIES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
];
