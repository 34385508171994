import styled, { css } from 'styled-components';

import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';
import { ButtonIconContainer } from '@anwb/poncho/components/button';

const customStyleContainerStyles = () => css`
	display: inline;

	${ButtonIconContainer} {
		width: ${pxToRem(38)}!important;
		height: ${pxToRem(38)}!important;
	}
`;

export const CustomStyleContainer = styled.div`
	${customStyleContainerStyles}
`;
