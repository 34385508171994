import axios from 'axios';

import {
	COGNITO_CLIENT_ID,
	COGNITO_CLIENT_SECRET,
	URL_COGNITO_OAUTH,
	URL_COGNITO_REDIRECT,
} from '../constants/cognito';

export const exchangeCodeForTokens = async (authCode: string) => {
	const authHeader = btoa(`${COGNITO_CLIENT_ID}:${COGNITO_CLIENT_SECRET}`);

	const data = new URLSearchParams({
		grant_type: 'authorization_code',
		code: authCode,
		redirect_uri: URL_COGNITO_REDIRECT,
	});

	return axios.post(URL_COGNITO_OAUTH, data, {
		headers: {
			'Authorization': `Basic ${authHeader}`,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});
};
