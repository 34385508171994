import { Contract } from '../types/contract';

const contracts: Contract[] = [
	{
		relationNumber: '123456789',
		contractDetails: [
			{ contractNumber: '784758', contractName: 'Some contract name 1' },
			{ contractNumber: '989957', contractName: 'Some contract name 2' },
			{ contractNumber: '923234', contractName: 'Some contract name 3' },
			{ contractNumber: '123488', contractName: 'Some contract name 4' },
			{ contractNumber: '234875', contractName: 'Some contract name 5' },
		],
	},
	{
		relationNumber: '987654321',
		contractDetails: [
			{ contractNumber: '454854', contractName: 'Some contract name 6' },
			{ contractNumber: '345456', contractName: 'Some contract name 7' },
			{ contractNumber: '845746', contractName: 'Some contract name 8' },
			{ contractNumber: '754858', contractName: 'Some contract name 9' },
		],
	},
];

export const fetchContracts = (relationNumbers: string[]) => {
	const found = contracts.filter((contract) =>
		relationNumbers.find(
			(relationNumber) => relationNumber === contract.relationNumber
		)
	);

	return found ? Promise.resolve(found) : Promise.reject('error');
};
