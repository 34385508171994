import { DEFAULT_CASE_SORTING } from '../../constants/case-sortings';
import { UseCasesStore } from '../../types/cases/cases-store';
import { InitialFilterState } from '../../types/filters';
import { Page } from '../../types/page';
import { PhvCase } from '../../types/cases/phv-case';
import { VhvCase } from '../../types/cases/vhv-case';

const INITIAL_PAGE: Page<PhvCase> | Page<VhvCase> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

export const CASES_STORE_INITIAL_STATE = (
	filters?: InitialFilterState
): UseCasesStore => ({
	accordionState: [],
	filters: {
		query: '',
		caseStatuses: [],
		minDate: undefined,
		maxDate: undefined,
		initialLocations: [],
		passedOns: [],
		...filters,
	},
	page: INITIAL_PAGE,
	sorting: DEFAULT_CASE_SORTING.value,
	notification: undefined,
	setAccordionState: () => undefined,
	updateDateRange: () => undefined,
	clearFilters: () => undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	updateCheckboxGroup: () => undefined,
	updateSorting: () => undefined,
	setNotification: () => undefined,
});
