import { FilterOption } from '../../../types/filter';

export const generateNewFilters = (
	previousSelected: string[],
	option: FilterOption,
	siblings: string[],
	parent?: string
) => {
	const newSelection = previousSelected?.filter(
		(selection) => selection !== option.value
	);

	if (newSelection?.length === previousSelected?.length) {
		// filter is added
		newSelection.push(option.value);
		if (option.options) {
			// filter has childs, select them all
			option.options.forEach((subOption: FilterOption) => {
				if (!newSelection.includes(subOption.value)) {
					newSelection.push(subOption.value);
				}
			});
		} else if (siblings && parent) {
			// adds parent selection if all siblings are selected
			let countSiblingsSelected = 0;
			siblings
				.filter((sibling) => newSelection.includes(sibling))
				.forEach(() => {
					countSiblingsSelected += 1;
				});
			if (
				countSiblingsSelected === siblings.length &&
				!newSelection.includes(parent)
			)
				newSelection.push(parent);
		}
	} else if (parent) {
		// filter was removed: also deselect parent
		const index = newSelection.indexOf(parent);
		index !== -1 && newSelection.splice(index, 1);
	} else if (option.options) {
		// filter was removed: also deselect all subfilters
		option.options.forEach((subOption: FilterOption) => {
			const index = newSelection.indexOf(subOption.value);
			index !== -1 && newSelection.splice(index, 1);
		});
	}

	return newSelection;
};
