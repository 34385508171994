import { ChangeEvent } from 'react';

import { Input } from '@anwb/poncho/components/forms';

import { SearchBarType } from '../../../types/unions';
import { SearchBarContainer } from './styles/searchbar.styled';

type SearchBarProps = {
	query?: string;
	setQuery: (query: string) => void;
	searchBarType: SearchBarType;
	placeHolder: string;
};

export const SearchBar = ({
	query,
	setQuery,
	searchBarType,
	placeHolder,
}: SearchBarProps) => (
	<SearchBarContainer>
		<Input
			type="text"
			data-test={`${searchBarType}-query`}
			value={query}
			placeholder={placeHolder}
			reference={`${searchBarType}-finder`}
			name={`${searchBarType}-finder`}
			onChange={(event: ChangeEvent<HTMLInputElement>) =>
				setQuery(event.target.value)
			}
		/>
	</SearchBarContainer>
);
