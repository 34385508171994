import { ButtonHTMLAttributes } from 'react';

import Icon from '@anwb/poncho/components/icon';
import { UiIconsKey } from '@anwb/poncho/utilities/icon-utils';
import { ButtonIconContainer } from '@anwb/poncho/components/button';
import { Color, Variant } from '@anwb/poncho/components/button/types';

import { CustomStyleContainer } from './styles/button-icon-small.styled';

export type ButtonIconProps = Omit<
	ButtonHTMLAttributes<HTMLButtonElement>,
	'children' | 'color'
> & {
	color: Color;
	icon: UiIconsKey;
	variant?: Variant;
	dataTest?: string;
};

export const ButtonIconSmall = ({
	className,
	icon,
	variant = 'on-light',
	dataTest = `button-${variant}`,
	type = 'button',
	color,
	...props
}: ButtonIconProps) => {
	return (
		<CustomStyleContainer>
			<ButtonIconContainer
				$variant={variant}
				$color={color}
				className={className}
				data-test={dataTest}
				type={type}
				{...props}
			>
				<Icon variant={icon} size="md" />
			</ButtonIconContainer>
		</CustomStyleContainer>
	);
};
