import axios from 'axios';
import { API_KEY, API_URL } from '../constants/api';

const api = axios.create({
	baseURL: API_URL,
	headers: {
		'content-type': 'application/json',
		'APIKEY': API_KEY,
	},
});

export default api;
