import { PhvCase } from '../../../../types/cases/phv-case';
import {
	createPhvDescriptionListAssistanceRequester,
	createPhvDescriptionListGeneral,
	createPhvDescriptionListInsuredData,
	createPhvDescriptionListSecondaryInsurances,
} from '../../utils/createPhvDescriptionLists';
import { createDescriptionListTravelContext } from '../../utils/createCommonDescriptionLists';
import DescriptionListBlock from '../DescriptionListBlock/DescriptionListBlock';
import {
	DescriptionListColumn,
	DescriptionListContainer,
} from '../styles/cases-details.styled';

export const CaseDetailsPhv = ({ selectedCase }: { selectedCase: PhvCase }) => (
	<DescriptionListContainer>
		<DescriptionListColumn>
			<DescriptionListBlock
				title="Algemeen"
				items={createPhvDescriptionListGeneral(selectedCase)}
			/>

			<DescriptionListBlock
				title="Hulpvrager"
				items={createPhvDescriptionListAssistanceRequester(selectedCase)}
			/>
		</DescriptionListColumn>
		<DescriptionListColumn>
			<DescriptionListBlock
				title="Gegevens verzekerde"
				items={createPhvDescriptionListInsuredData(selectedCase)}
			/>
			{selectedCase.caseSummary.contracts?.map((contract, index) => (
				<DescriptionListBlock
					title={`Overige verzekeringen (${index + 1})`}
					items={createPhvDescriptionListSecondaryInsurances(contract)}
				/>
			))}
			<DescriptionListBlock
				title="Reiscontext"
				items={createDescriptionListTravelContext<PhvCase>(selectedCase)}
			/>
		</DescriptionListColumn>
	</DescriptionListContainer>
);
