import { Invoices } from '../components/Invoices/Invoices';
import { Cases } from '../components/Cases/Cases';
import { UserManagement } from '../components/UserManagement/UserManagement';
import { RelationManagement } from '../components/RelationManagement/RelationManagement';
import { NavItem } from '../types/navigation';
import { ContentHeader } from '../styles/page-layout.styled';
import { useAllowAccess } from '../auth/hooks/useAllowAccess';
import { Reports } from '../components/Reports/Reports';

export const NAVIGATION = (): NavItem[] => {
	const allowAccess = useAllowAccess();

	return [
		{
			path: '/',
			navPath: '/',
			title: 'Overzicht',
			child: <ContentHeader>Overzichtspagina</ContentHeader>,
			show: true,
		},
		{
			path: '/dossiers-phv/*',
			navPath: '/dossiers-phv',
			title: allowAccess('VHV') ? 'Dossiers personenhulp' : 'Dossiers',
			child: <Cases casesType="phv" />,
			show: allowAccess('PHV'),
		},
		{
			path: '/dossiers-vhv/*',
			navPath: '/dossiers-vhv',
			title: allowAccess('PHV') ? 'Dossiers voertuigenhulp' : 'Dossiers',
			child: <Cases casesType="vhv" />,
			show: allowAccess('VHV'),
		},
		{
			path: '/facturen/*',
			navPath: '/facturen',
			title: 'Facturen',
			child: <Invoices />,
			show: allowAccess('INVOICES'),
		},
		{
			path: '/rapportages/*',
			navPath: '/rapportages',
			title: 'Rapportages',
			child: <Reports />,
			show: allowAccess('REPORTS'),
		},
		{
			path: '/gebruikersbeheer/*',
			navPath: '/gebruikersbeheer',
			title: 'Gebruikersbeheer',
			child: <UserManagement />,
			show: allowAccess('ADMIN'),
		},
		{
			path: '/relatiebeheer/*',
			navPath: '/relatiebeheer',
			title: 'Relatiebeheer',
			child: <RelationManagement />,
			show: allowAccess('ADMIN'),
		},
	];
};
