import { parseAmount } from '../../../utils/parseAmount';

const isEmpty = (someValue: string) => !someValue || someValue.length === 0;

export const isMinValid = (min: string, max: string) => {
	if (isEmpty(min)) {
		return true;
	}
	if (Number.isNaN(parseAmount(min))) {
		return false;
	}
	if (isEmpty(max) || Number.isNaN(parseAmount(max, true))) {
		return true;
	}
	return parseAmount(min) <= parseAmount(max, true);
};

export const isMaxValid = (min: string, max: string) => {
	if (isEmpty(max)) {
		return true;
	}
	if (Number.isNaN(parseAmount(max, true))) {
		return false;
	}
	if (isEmpty(min) || Number.isNaN(parseAmount(min))) {
		return true;
	}
	return parseAmount(min) <= parseAmount(max, true);
};
