import styled, { css } from 'styled-components';

const titleAndSortContainerStyles = ({ $styled }: { $styled: boolean }) => css`
	${$styled &&
	css`
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	`}
`;

export const TitleAndSortContainer = styled.div`
	${titleAndSortContainerStyles}
`;
