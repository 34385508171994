import { ReactNode } from 'react';

import Typography from '@anwb/poncho/components/typography';

import {
	PanelTitle,
	PanelTitleContainer,
} from './styles/panel-title-with-menu.styled';

type PanelTitleWithMenuProps = {
	title: string | ReactNode;
	onSelectHandler?: () => void;
	onDeleteHandler?: () => void;
};

const PanelTitleWithMenu = ({
	title,
	onSelectHandler,
	onDeleteHandler,
}: PanelTitleWithMenuProps) => (
	<PanelTitleContainer>
		<PanelTitle variant="component-title">{title}</PanelTitle>
		{onSelectHandler && onDeleteHandler ? (
			<>
				<Typography
					variant="link-text"
					onClick={onSelectHandler}
					dataTest="edit-item-link"
				>
					Wijzigen
				</Typography>
				<Typography
					variant="link-text"
					onClick={onDeleteHandler}
					dataTest="delete-item-link"
				>
					Verwijderen
				</Typography>
			</>
		) : (
			<div />
		)}
	</PanelTitleContainer>
);

export default PanelTitleWithMenu;
