import { useEffect, useState } from 'react';

import { ReportSort } from '../../../types/sort';
import { UseReportsStore } from '../../../types/reports/reports-store';
import { Report } from '../../../types/reports/report';
import { Page } from '../../../types/page';
import { Pageable } from '../../../types/pageable';
import { useReportsStore } from '../../../store/reportsStore';
import { getReports } from '../../../utils/reportsService';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../constants/notifications';

const PAGE_SIZE = 20;

const pageable = (pageNumber: number, sorting: ReportSort) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
		sort: sorting,
	}) as Pageable<ReportSort>;

export const useProcessReports = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, setPage, page, sorting, setNotification } = useReportsStore(
		(state: UseReportsStore) => ({
			filters: state.filters,
			page: state.page,
			setPage: state.setPage,
			sorting: state.sorting,
			setNotification: state.setNotification,
		})
	);

	const loadPage = (pageNumber: number) => {
		setNotification(undefined);
		setShowSpinner(true);
		getReports(filters, pageable(pageNumber, sorting))
			.then((result) => {
				setPage(result.data as Page<Report>);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			loadPage(page.pageNumber);
		}, 750);
		return () => clearTimeout(timeout);
	}, [filters, sorting]);

	return { showSpinner, loadPage };
};
