import { DecodedJwt } from '../types/auth';
import { COGNITO_CLIENT_ID, COGNITO_ISSUER } from '../constants/cognito';

export const tokenAppCredentialsAreValid = (decodedToken: DecodedJwt) => {
	const isValidForApp =
		decodedToken.iss === COGNITO_ISSUER &&
		decodedToken.client_id === COGNITO_CLIENT_ID;

	if (!isValidForApp) console.error('Unexpected JWT issuer or clientId');

	return isValidForApp;
};
