import { PaymentState } from '../../../types/invoices/invoice';

export const formatPaymentState = (paymentState?: PaymentState | null) => {
	switch (paymentState) {
		case 'OPEN':
			return 'Open';
		case 'CLOSED':
			return 'Gesloten';
		case 'SENT':
			return 'Verzonden';
		case 'CREDITED':
			return 'Gecrediteerd';
		case 'BILLED':
			return 'Gefactureerd';
		default:
			return '-';
	}
};
