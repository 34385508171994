import { useEffect, useState } from 'react';

import { getInvoices } from '../../../../utils/invoiceService';
import { useInvoicesStore } from '../../../../store/invoicesStore';
import { InvoiceSort } from '../../../../types/sort';
import { Pageable } from '../../../../types/pageable';
import { Page } from '../../../../types/page';
import { Invoice, SearchInvoice } from '../../../../types/invoices/invoice';
import { UseInvoicesStore } from '../../../../types/invoices/invoices-store';
import { PreviousQuery } from '../../../../utils/previousQuery';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';
import { endSession } from '../../../../auth/utils/endSession';

const PAGE_SIZE = 20;

const pageable = (pageNumber: number, sorting: InvoiceSort) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
		sort: sorting,
	}) as Pageable<InvoiceSort>;

export const previousQuery = new PreviousQuery<SearchInvoice, InvoiceSort>();

const useProcessInvoices = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, setPage, page, sorting, setNotification } = useInvoicesStore(
		(state: UseInvoicesStore) => ({
			filters: state.filters,
			page: state.page,
			setPage: state.setPage,
			sorting: state.sorting,
			setNotification: state.setNotification,
		})
	);

	const loadPage = (pageNumber: number) => {
		setNotification(undefined);
		setShowSpinner(true);
		getInvoices(filters, pageable(pageNumber, sorting))
			.then((result) => {
				setPage(result.data as Page<Invoice>);
			})
			.catch((error) => {
				if (error.response && error.response.status === 403) {
					endSession();
				}
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	};

	useEffect(() => {
		if (!previousQuery.shouldUpdate(filters, sorting)) {
			setShowSpinner(false);
			return;
		}

		const timeout = setTimeout(() => {
			previousQuery.setPrevious(filters, sorting);
			loadPage(page.pageNumber);
		}, 750);
		return () => clearTimeout(timeout);
	}, [filters, sorting]);

	return { showSpinner, loadPage };
};

export default useProcessInvoices;
