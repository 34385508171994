import { User } from '../../../../../types/users/user';
import { mapUserRights } from './mapUserRights';

export const mapUserDescriptionListItems = (user: User) => [
	{ key: 'Achternaam', value: user.lastName },
	{ key: 'Voornaam', value: user.firstName },
	{ key: 'E-mailadres', value: user.email },
	{ key: 'Mobiele nummer', value: user.phoneNumber },
	{
		key: 'Rechten',
		value: mapUserRights(user),
	},
];
