import { AnyAction } from '../../../../../types/cases/common-case';
import { SearchCaseActions } from '../../../../../types/cases/case-actions-store';
import { Pageable } from '../../../../../types/pageable';
import { CaseActionSort } from '../../../../../types/sort';
import { toPage } from '../../../../../utils/toPage';
import { doFilter } from './doFilter';
import { doSort } from './doSort';

export const filterCaseActions = (
	actions: AnyAction[],
	filters: SearchCaseActions,
	pageable: Pageable<CaseActionSort>
) => {
	const sorting = pageable.sort;
	doSort(actions, sorting);

	if (!filters) {
		return Promise.resolve(toPage<AnyAction>(actions, pageable));
	}

	const filteredActions = actions.filter((action) => doFilter(action, filters));
	return Promise.resolve(toPage<AnyAction>(filteredActions, pageable));
};
