import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';

const panelContainerStyles = ({ theme }: DefaultThemeProps) => css`
	background-color: ${theme.colors.extralight.bgPanelHighlight};
	border-radius: 0.8rem;
	display: flex;
	min-height: 124px;
`;

export const PanelContainer = styled.div`
	${panelContainerStyles}
`;

const detailsContainerStyles = () => css`
	flex: 1;
`;

export const DetailsContainer = styled.div`
	${detailsContainerStyles}
`;

const dateContainerStyles = () => css`
	padding: 16px;
	flex: 0 0 165px;
	text-align: right;
`;

export const DateContainer = styled.div`
	${dateContainerStyles}
`;
