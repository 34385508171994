import { Dispatch, SetStateAction } from 'react';

import { Debtor } from '../../../../../../types/contractors/contractor';
import { fetchDebtorName } from '../utils/fetchDebtorName';

export const useValidateRelationNumber = (
	debtors: Debtor[],
	setDebtors: Dispatch<SetStateAction<Debtor[]>>
) => {
	return (relationNumber: string, input?: HTMLInputElement): Promise<string> =>
		new Promise((resolve, reject) => {
			const index = input?.name.split('-').pop() as unknown as number;
			const newData = [...debtors];

			const updateRelationItems = (debtorName?: string) => {
				newData.splice(index, 1, {
					relationNumber,
					name: debtorName || '',
				});
				setDebtors(newData);
			};

			if (relationNumber.length !== 9) {
				updateRelationItems();
				return reject('Voer svp 9 tekens in');
			}

			const occurrences = debtors.filter(
				(relationObj) => relationObj.relationNumber === relationNumber
			).length;

			if (occurrences > 0) {
				return reject('Relatienummer komt al voor in deze relatie');
			}

			return fetchDebtorName(relationNumber)
				.then((debtorName) => {
					updateRelationItems(debtorName);
					return resolve('');
				})
				.catch(() => reject('Debiteurnaam ophalen mislukt'));
		});
};
