import {
	AssistanceAction,
	ContactMoment,
	OfferedSolution,
} from '../../../../../types/cases/common-case';
import {
	isAssistanceAction,
	isContactMoment,
	isOfferedSolution,
} from './types/guards';
import { PanelAssistanceAction } from './PanelAssistanceAction/PanelAssistanceAction';
import { PanelContactMoment } from './PanelContactMoment/PanelContactMoment';
import { PanelOfferedSolution } from './PanelOfferedSolution/PanelOfferedSolution';

type CaseActionPanelProps = {
	action: AssistanceAction | ContactMoment | OfferedSolution;
};

export const CaseActionPanel = ({ action }: CaseActionPanelProps) => {
	if (isAssistanceAction(action)) {
		return <PanelAssistanceAction action={action} />;
	}

	if (isContactMoment(action)) {
		return <PanelContactMoment action={action} />;
	}

	if (isOfferedSolution(action)) {
		return <PanelOfferedSolution action={action} />;
	}
};
