export const INVOICE_LINES_SORTINGS = [
	{ label: 'Omschrijving - aflopend', value: 'DESC_DESCRIPTION' },
	{ label: 'Omschrijving - oplopend', value: 'ASC_DESCRIPTION' },
	{ label: 'Stukprijs- aflopend', value: 'DESC_PRICE_PIECE' },
	{ label: 'Stukprijs - oplopend', value: 'ASC_PRICE_PIECE' },
	{ label: 'Totaalbedrag - aflopend', value: 'DESC_PRICE_TOTAL' },
	{ label: 'Totaalbedrag - oplopend', value: 'ASC_PRICE_TOTAL' },
	{ label: 'Aantal - aflopend', value: 'DESC_AMOUNT' },
	{ label: 'Aantal - oplopend', value: 'ASC_AMOUNT' },
] as const;

export const DEFAULT_INVOICE_LINES_SORTING = INVOICE_LINES_SORTINGS[0];
