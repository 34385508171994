import { jwtDecode } from 'jwt-decode';

import { URL_COGNITO_LOGIN } from './constants/cognito';
import { exchangeCodeForTokens } from './api/exchangeCodeForTokens';
import { DecodedJwt, Tokens } from './types/auth';
import { tokenIsExpired } from './utils/tokenIsExpired';
import { tokenFormatIsValid } from './utils/tokenFormatIsValid';
import { tokenAppCredentialsAreValid } from './utils/tokenAppCredentialsAreValid';
import { reload } from './utils/reload';
import { endSession } from './utils/endSession';

export const processJwt = (
	onProceedCallback: (storedAccessToken: string) => void
) => {
	/**
	 * This flow acts like a funnel that always tries to use a valid stored token in order to proceed
	 * It will try to retrieve tokens using the url param code (Cognito redirect) in case no stored tokens are present and will then retry the flow
	 */

	const storedIdToken = localStorage.getItem(Tokens.IdToken);
	const storedAccessToken = localStorage.getItem(Tokens.AccessToken);
	const storedRefreshToken = localStorage.getItem(Tokens.RefreshToken);

	if (storedIdToken && storedAccessToken && storedRefreshToken) {
		/**
		 * Stored token scenario
		 */
		if (tokenIsExpired(storedIdToken)) {
			endSession();
		} else {
			onProceedCallback(storedAccessToken);
		}
	} else {
		/**
		 * Code param scenario:
		 */
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');

		if (code) {
			exchangeCodeForTokens(code)
				.then((response) => {
					const { id_token, access_token, refresh_token } = response.data;

					if (id_token && access_token && refresh_token) {
						if (tokenFormatIsValid(access_token)) {
							try {
								const decodedToken: DecodedJwt = jwtDecode(access_token);

								if (tokenAppCredentialsAreValid(decodedToken)) {
									localStorage.setItem(Tokens.IdToken, id_token);
									localStorage.setItem(Tokens.AccessToken, access_token);
									localStorage.setItem(Tokens.RefreshToken, refresh_token);
								}
							} catch (error) {
								console.error(error);
							}
						}
					}
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					Promise.resolve().then(reload);
				});
		} else {
			window.location.href = URL_COGNITO_LOGIN;
		}
	}
};
