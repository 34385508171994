import { FilterData } from '../components/Common/Filter/types/filter';

export const stripSearch = (
	searchInvoice: Record<string, string | string[]>,
	filterConfig: FilterData[]
) => {
	const invoiceData = { ...searchInvoice };

	Object.entries(invoiceData).forEach(([key, value]) => {
		if (Array.isArray(value) && value.length === 2) {
			const hasTwoOptions = filterConfig.find(
				(item) =>
					item.name === key &&
					item.type === 'checkboxGroup' &&
					item.options?.length === 2 &&
					item.name !== 'passedOns'
			);
			if (hasTwoOptions) invoiceData[key] = [];
		}
	});

	return invoiceData;
};
