import Typography from '@anwb/poncho/components/typography';
import { Tag, Tags } from '@anwb/poncho/components/tags';
import { PanelSpacer } from '@zama/panel-spacer';

import { Debtor } from '../../../../../../types/relations/relation';

type LabelTagsProps = {
	debtors: Debtor[];
};

export const LabelTags = ({ debtors }: LabelTagsProps) =>
	debtors?.length > 0 && (
		<>
			<PanelSpacer />
			<Typography variant="component-title">Labels</Typography>
			<Tags>
				{debtors.map((debtor) =>
					debtor.labels?.map((label, i) => (
						<Tag key={`flow-id-${i}`}>
							<Tag.Label>{label}</Tag.Label>
						</Tag>
					))
				)}
			</Tags>
		</>
	);
