import { Route, Routes, Navigate } from 'react-router-dom';

import { CasesZustandStore } from '../../types/zustand';
import { CasesDetails } from './CasesDetails/CasesDetails';
import { CasesOverview } from './CasesOverview/CasesOverview';
import { CasesType } from './types/cases';

type CasesProps = {
	casesType: CasesType;
	store: CasesZustandStore;
};

export const Cases = ({ casesType, store }: CasesProps) => (
	<Routes>
		<Route
			index
			element={<CasesOverview casesType={casesType} store={store} />}
		/>

		<Route
			path=":caseId"
			element={<CasesDetails casesType={casesType} store={store} />}
		/>
		<Route path="/*" element={<Navigate to={`/dossiers-${casesType}`} />} />
	</Routes>
);
