import styled, { css, DefaultThemeProps } from 'styled-components';

import Image from '@anwb/poncho/components/image';
import Typography from '@anwb/poncho/components/typography';

const headerStyles = () => css`
	width: 100%;
	height: 126.8px;
`;

export const StyledHeader = styled.header`
	${headerStyles}
`;

const topBarStyles = () => css`
	height: 66px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

export const StyledTopBar = styled.div`
	${topBarStyles}
`;

const logoStyles = () => css`
	width: 116px;
	position: relative;
	top: 20px;
	margin: 0 0 0 20px !important;
`;

export const StyledLogo = styled(Image)`
	${logoStyles}
`;

const logoTitleStyles = ({ theme }: DefaultThemeProps) => css`
	position: relative;
	top: 20px;
	padding: 0;
	line-height: 50px !important;
	margin: 0 0 0 10px;
	color: ${theme.colors.base.textTitles};
`;

export const StyledLogoTitle = styled(Typography)`
	${logoTitleStyles}
`;

const navStyles = ({ theme }: DefaultThemeProps) => css`
	height: 60.8px;
	background-color: ${theme.colors.base.textTitles};
	border-radius: 12px;
`;

export const StyledNavBar = styled.nav`
	${navStyles}
`;

const listStyles = () => css`
	list-style: none;
	height: 60.8px;
	margin: 0;
	padding: 0 12px;
	max-width: 100%;
`;

export const StyledList = styled.ul`
	${listStyles}
`;

const listItemStyles = () => css`
	height: 60.8px;
	margin: 0;
	padding: 0;
	display: table-cell;
	vertical-align: middle;
`;

export const StyledListItem = styled.li`
	${listItemStyles}
`;

const linkTitleStyles = () => css`
	margin: 0;
	padding: 0;
	color: white;
	line-height: 22px;
`;

export const StyledLinkTitle = styled(Typography)`
	${linkTitleStyles}
`;

const logoutStyles = () => css`
	position: relative;
	margin: 30px 10px 0 auto;
`;

export const StyledLogout = styled(Typography).attrs({
	variant: 'link-text',
})`
	${logoutStyles}
`;
