import styled, { css } from 'styled-components';

const filterContainerStyles = () => css`
	width: 25%;
`;

export const FilterContainer = styled.div`
	${filterContainerStyles}
`;

const filterWrapperStyles = () => css`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const FilterWrapper = styled.div`
	${filterWrapperStyles}
`;
