import LicensePlate from '@anwb/poncho/components/license-plate-text';

import { VhvCase, VhvCaseSummary } from '../../../../types/cases/vhv-case';
import { LocationInfo } from '../../../../types/cases/common-case';
import {
	createVhvDescriptionListDriver,
	createVhvDescriptionListFirstDiagnosis,
	createVhvDescriptionListGeneral,
	createVhvDescriptionListInitialLocation,
	createVhvDescriptionListPassedOn,
	createVhvDescriptionListTransportDestination,
	createVhvDescriptionListVehicle,
} from '../../utils/createVhvDescriptionLists';
import { createDescriptionListTravelContext } from '../../utils/createCommonDescriptionLists';
import DescriptionListBlock from '../DescriptionListBlock/DescriptionListBlock';
import {
	DescriptionListColumn,
	DescriptionListContainer,
} from '../styles/cases-details.styled';
import { composeGoogleDirectionsUrl } from '../utils/maps';

export const CaseDetailsVhv = ({ selectedCase }: { selectedCase: VhvCase }) => (
	<DescriptionListContainer>
		<DescriptionListColumn>
			<DescriptionListBlock
				title="Algemeen"
				items={createVhvDescriptionListGeneral(selectedCase as VhvCase)}
			/>

			<DescriptionListBlock
				title="Bestuurder"
				items={createVhvDescriptionListDriver(selectedCase as VhvCase)}
			/>

			<DescriptionListBlock
				title="Voertuiggegevens"
				items={createVhvDescriptionListVehicle(selectedCase as VhvCase)}
				customComponent={
					<LicensePlate
						value={
							(selectedCase.caseSummary as VhvCaseSummary).vehicle.licensePlate
						}
					/>
				}
			/>

			<DescriptionListBlock
				title="Initiële locatie"
				items={createVhvDescriptionListInitialLocation(selectedCase as VhvCase)}
				googleMaps={composeGoogleDirectionsUrl(
					(selectedCase as VhvCase).caseSummary.assistanceReportedLocation
				)}
			/>
		</DescriptionListColumn>
		<DescriptionListColumn>
			<DescriptionListBlock
				title="Eerste diagnose"
				items={createVhvDescriptionListFirstDiagnosis(selectedCase as VhvCase)}
			/>

			<DescriptionListBlock
				title="Reiscontext"
				items={createDescriptionListTravelContext<VhvCase>(selectedCase)}
			/>

			<DescriptionListBlock
				title="Eindbestemming voertuigtransport"
				items={createVhvDescriptionListTransportDestination(
					selectedCase as VhvCase
				)}
				googleMaps={
					(selectedCase as VhvCase).caseSummary.transportDestination
						? composeGoogleDirectionsUrl(
								(selectedCase as VhvCase).caseSummary.transportDestination
									?.location as LocationInfo
							)
						: undefined
				}
			/>

			<DescriptionListBlock
				title="Afwentelinformatie"
				items={createVhvDescriptionListPassedOn(selectedCase as VhvCase)}
			/>
		</DescriptionListColumn>
	</DescriptionListContainer>
);
