const debtors = [
	{
		relationNumber: '123456789',
		name: 'Mock debtor 1',
	},
	{
		relationNumber: '987654321',
		name: 'Mock debtor 2',
	},
];

export const fetchDebtorName = (relationNumber: string) => {
	const found = debtors.find(
		(debtor) => debtor.relationNumber === relationNumber
	);

	return found ? Promise.resolve(found.name) : Promise.reject('error');
};
