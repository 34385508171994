import {
	Address,
	GeoCoordinates,
	LocationInfo,
} from '../../../../types/cases/common-case';

const encode = (str: string) => encodeURIComponent(str).replace(/'/g, '');

const getGeoPositionQuery = (geoCoordinates: GeoCoordinates) => {
	const parts = [
		geoCoordinates.latitude.toString(),
		geoCoordinates.longitude.toString(),
	];
	return encode(parts.join(','));
};

const getAddressQuery = (address: Address) => {
	const parts = [
		address.street,
		address.houseNumber,
		address.houseNumberSuffix,
		address.postalCode,
		address.city,
		address.country,
	];

	return encode(parts.filter((part) => part).join('+'));
};

const addressIsValid = (address: Address | undefined) =>
	address?.street && address?.postalCode && address?.city && address?.country;

const locationIsValid = (assistanceReportedLocation: LocationInfo) =>
	assistanceReportedLocation &&
	(addressIsValid(assistanceReportedLocation.address) ||
		(assistanceReportedLocation.geoCoordinates?.latitude &&
			assistanceReportedLocation.geoCoordinates?.longitude));

const formatGoogleLocationQuery = (
	assistanceReportedLocation: LocationInfo
) => {
	return addressIsValid(assistanceReportedLocation.address)
		? getAddressQuery(assistanceReportedLocation.address as Address)
		: getGeoPositionQuery(
				assistanceReportedLocation.geoCoordinates as GeoCoordinates
			);
};

export const composeGoogleDirectionsUrl = (
	assistanceReportedLocation: LocationInfo
) => {
	if (!locationIsValid(assistanceReportedLocation)) return '';
	return `https://www.google.com/maps/search/?api=1&query=${formatGoogleLocationQuery(
		assistanceReportedLocation
	)}`;
};
