import { env } from '../../inject_env';

export const COGNITO_BASE_URL =
	'https://cognito-idp.eu-central-1.amazonaws.com';
export const COGNITO_ISSUER = `${COGNITO_BASE_URL}/${env.COGNITO_USER_POOL_ID}`;
export const COGNITO_CLIENT_ID = env.COGNITO_CLIENT_ID;
export const COGNITO_CLIENT_SECRET = env.COGNITO_CLIENT_SECRET;
export const COGNITO_USER_POOL_ID = env.COGNITO_USER_POOL_ID;
export const AUTH_URL_COGNITO = env.COGNITO_URL;
export const URL_COGNITO_REDIRECT = env.COGNITO_REDIRECT as string;
export const URL_COGNITO_LOGIN = `${AUTH_URL_COGNITO}/oauth2/authorize?client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(URL_COGNITO_REDIRECT as string)}`;
export const URL_COGNITO_LOGOUT = `${AUTH_URL_COGNITO}/logout?response_type=code&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${encodeURIComponent(URL_COGNITO_REDIRECT as string)}`;
export const URL_COGNITO_OAUTH = `${AUTH_URL_COGNITO}/oauth2/token`;
export const URL_COGNITO_TOKEN_REVOCATION_LAMBDA =
	env.URL_COGNITO_TOKEN_REVOCATION_LAMBDA;
