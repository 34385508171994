import { PHV_RECORD_CATEGORIES } from '../../../../constants/phv-record-category';
import { PHV_ASSISTANCE_CATEGORIES } from '../../../../constants/phv-assistance-categories';
import { VEHICLE_TYPES } from '../../../../constants/vehicle-types';
import { VHV_ASSISTANCE_CATEGORIES } from '../../../../constants/vhv-assistance-categories';
import { VHV_INCIDENT_CATEGORIES } from '../../../../constants/vhv-incident-categories';
import { FilterData } from '../types/filter';

const CASES_FILTER_DATA: FilterData[] = [
	{
		name: 'caseStates',
		type: 'checkboxGroup',
		active: true,
		label: 'Status',
		options: [
			{ label: 'Open', value: 'OPEN' },
			{ label: 'Gesloten', value: 'CLOSED' },
		],

		value: [],
	},
	{
		type: 'dateRange',
		active: true,
		label: 'Meldingsdatum',
	},
	{
		name: 'initialLocations',
		type: 'checkboxGroup',
		active: true,
		label: 'Initiële locatie',
		options: [
			{ label: 'Binnenland', value: 'DOMESTIC' },
			{ label: 'Buitenland', value: 'ABROAD' },
		],

		value: [],
	},
	{
		name: 'passedOns',
		type: 'checkboxGroup',
		active: true,
		label: 'Bevat afgewentelde acties',
		options: [
			{ label: 'Intern afgewenteld', value: 'INTERNAL' },
			{ label: 'Extern afgewenteld', value: 'EXTERNAL' },
		],

		value: [],
	},
];

export const PHV_CASES_FILTER: FilterData[] = [
	...CASES_FILTER_DATA,
	{
		name: 'phvRecordCategories',
		type: 'checkboxGroup',
		active: true,
		label: 'Dossiersoort',
		options: PHV_RECORD_CATEGORIES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
	{
		name: 'phvAssistanceCategories',
		type: 'checkboxGroup',
		active: true,
		label: 'Hulpverleningactie',
		options: PHV_ASSISTANCE_CATEGORIES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
];

export const VHV_CASES_FILTER: FilterData[] = [
	...CASES_FILTER_DATA,
	{
		name: 'vehicleTypes',
		type: 'checkboxGroup',
		active: true,
		label: 'Vervoermiddel/object',
		options: VEHICLE_TYPES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
	{
		name: 'vhvIncidentCategories',
		type: 'checkboxGroup',
		active: true,
		label: 'Type incident',
		options: VHV_INCIDENT_CATEGORIES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
	{
		name: 'vhvAssistanceCategories',
		type: 'checkboxGroup',
		active: true,
		label: 'Hulpverleningactie',
		options: VHV_ASSISTANCE_CATEGORIES.map((category) => ({
			label: category.label,
			value: category.code,
		})),
		value: [],
	},
];
