import { Route, Routes, Navigate } from 'react-router-dom';

import { ContractorsOverview } from './ContractorsOverview/ContractorsOverview';
import { AddContractor } from './AddContractor/AddContractor';
import { EditContractor } from './EditContractor/EditContractor';

export const ContractorManagement = () => (
	<Routes>
		<Route index element={<ContractorsOverview />} />
		<Route path="/relatie-toevoegen" element={<AddContractor />} />
		<Route path="/relatie-bewerken/:id" element={<EditContractor />} />
		<Route path="/*" element={<Navigate to="/relatiebeheer" />} />
	</Routes>
);
