import { Route, Routes, Navigate } from 'react-router-dom';

import { UsersOverview } from './UsersOverview/UsersOverview';
import { AddUser } from './AddUser/AddUser';

export const UserManagement = () => (
	<Routes>
		<Route index element={<UsersOverview />} />
		<Route path="/gebruiker-toevoegen" element={<AddUser />} />
		<Route path="/*" element={<Navigate to="/gebruikersbeheer" />} />
	</Routes>
);
