import styled, { css } from 'styled-components';
import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';

const sortBarWrapperSyles = ({
	theme,
	$addMargin,
}: DefaultThemeProps & { $addMargin: boolean }) => css`
	${$addMargin &&
	css`
		margin-bottom: 1rem;
	`}
	display: flex;
	justify-content: flex-end;

	@media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
		justify-content: flex-start;
	}
`;

export const SortBarWrapper = styled.div`
	${sortBarWrapperSyles}
`;
