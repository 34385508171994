export const VHV_ASSISTANCE_CATEGORIES = [
	{
		code: 'DIEFSTALHULPVERLENING_BUITENLAND',
		label: 'Diefstalhulpverlening buiten Nederland',
	},
	{
		code: 'DIEFSTALHULPVERLENING_NEDERLAND',
		label: 'Diefstalhulpverlening in Nederland',
	},
	{
		code: 'ONGEVALSHULPVERLENING_BUITENLAND',
		label: 'Ongevalshulpverlening buiten Nederland',
	},
	{
		code: 'ONGEVALSHULPVERLENING_BINNENLAND',
		label: 'Ongevalshulpverlening in Nederland',
	},
	{
		code: 'PECHHULPVERLENING_BUITENLAND',
		label: 'Pechhulpverlening buiten Nederland',
	},
	{
		code: 'PECHHULPVERLENING_BINNENLAND',
		label: 'Pechhulpverlening in Nederland',
	},
	{
		code: 'RUITBREUKHULPVERLENING_BUITENLAND',
		label: 'Ruitbreukhulpverlening buiten Nederland',
	},
	{
		code: 'RUITBREUKHULPVERLENING_BINNENLAND',
		label: 'Ruitbreukhulpverlening in Nederland',
	},
	{ code: 'HELPDESK', label: 'Helpdesk' },
] as const;
