export class PreviousQuery<Search, Sort = never> {
	previousFilters: Search | undefined;

	previousSorting: Sort | undefined;

	previousPageNumber: number | undefined;

	resetPrevious() {
		this.previousFilters = undefined;
		this.previousSorting = undefined;
		this.previousPageNumber = undefined;
	}

	setPrevious(filters: Search, sorting?: Sort, pageNumber?: number) {
		this.previousFilters = filters;
		this.previousSorting = sorting;
		this.previousPageNumber = pageNumber;
	}

	shouldUpdate(filters: Search, sorting?: Sort, pageNumber?: number) {
		return [
			[this.previousFilters, filters],
			[this.previousSorting, sorting],
			[this.previousPageNumber, pageNumber],
		].some((arr) => JSON.stringify(arr[0]) !== JSON.stringify(arr[1]));
	}
}
