import { FormElementsData } from '@anwb/poncho/types/types';

import { ROLES_CATEGORIES } from '../../../constants/roles-categories';
import { ProcessRelation } from '../../../types/relations/relation';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

const getDebtorKey = (relationNumberKey: string) => {
	const index = relationNumberKey.split('-').pop();
	return `debtor-${index}`;
};

const formatContractNumbers = (
	elementsData: FormElementsData,
	anwbRelationNumber: string
) =>
	Object.keys(elementsData)
		.filter((key) => key.indexOf(anwbRelationNumber) !== -1)
		.map((contractNumber) => elementsData[contractNumber]);

const formatDebtors = (elementsData: FormElementsData) =>
	Object.keys(elementsData)
		.filter((key) => key.indexOf('relation-number-') !== -1)
		.map((relationNumberKey) => ({
			anwbRelationNumber: elementsData[relationNumberKey],
			labels: [elementsData[getDebtorKey(relationNumberKey)]],
			contractNumbers: formatContractNumbers(
				elementsData,
				elementsData[relationNumberKey] as string
			),
		}));

export const formatRelation = (elementsData: FormElementsData) =>
	({
		relationName: elementsData.relationName,
		debtors: formatDebtors(elementsData),
		firstName: elementsData.firstName,
		lastName: elementsData.lastName,
		email: elementsData.email,
		phoneNumber: formatPhoneNumber(elementsData.phoneNumber as string),
		roles: ROLES_CATEGORIES.filter(
			(category) => elementsData[category.code]
		).map((category) => category.code),
		adminUser: false,
		keyUser: true,
	}) as ProcessRelation;
