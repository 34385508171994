import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import Typography from '@anwb/poncho/components/typography';

import { useInvoiceLinesStore } from '../../../store/invoiceLinesStore';
import { DetailsContainer, DetailsMain } from '../../../styles/details-page';
import { ReturnLink } from '../../../styles/return-link';
import { ContentHeader } from '../../../styles/page-layout.styled';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { GeneralInvoiceDetails } from './GeneralInvoiceDetails/GeneralInvoiceDetails';
import { PhvInvoiceDetails } from './PhvInvoiceDetails/PhvInvoiceDetails';
import SearchAndFilterInvoiceLine from './SearchAndFilterInvoiceLine/SearchAndFilterInvoiceLine';
import { InvoiceLinesTable } from './InvoiceLinesTable/InvoiceLinesTable';
import { InvoiceLinesSortBar } from './InvoiceLinesSortBar/InvoiceLinesSortBar';
import { clearInvoiceSessionStorage } from './utils/invoiceSessionStorage';
import useProcessInvoiceLines from './hooks/useProcessInvoiceLines';
import useGetInvoice from './hooks/useGetInvoice';
import { TitleAndSortContainer } from './styles/invoice-details.styled';

export const InvoiceDetails = () => {
	const { notification, resetStore } = useInvoiceLinesStore((state) => ({
		notification: state.notification,
		resetStore: state.resetStore,
	}));
	const navigate = useNavigate();
	const invoice = useGetInvoice();
	const { showSpinner, loadPage } = useProcessInvoiceLines();

	return (
		<Grid constrainedWidth>
			<Grid.Item>
				{invoice && (
					<>
						<ReturnLink
							onClick={() => {
								resetStore();
								clearInvoiceSessionStorage();
								navigate(-1);
							}}
						>
							Terug naar facturen
						</ReturnLink>
						<ContentHeader>{`Factuur ${invoice.invoiceNumber}`}</ContentHeader>
						<DetailsContainer>
							<DetailsMain>
								<GeneralInvoiceDetails invoice={invoice} />
								{invoice.assistance?.assistanceType === 'PHV' && (
									<PhvInvoiceDetails invoice={invoice} />
								)}
								<TitleAndSortContainer
									$styled={invoice.invoiceType === 'SINGLE'}
								>
									<Typography variant="group-component-title">
										Factuurregels
									</Typography>
									{invoice.invoiceType === 'COLLECTIVE' && (
										<SearchAndFilterInvoiceLine />
									)}

									<InvoiceLinesSortBar
										addMargin={invoice.invoiceType === 'SINGLE'}
									/>
								</TitleAndSortContainer>

								{notification && (
									<Notification
										variant={notification.type}
										title={notification.title}
									>
										{notification.description}
									</Notification>
								)}

								<SpinnerContainer
									showSpinner={showSpinner}
									message="Factuurregels worden geladen"
								>
									<InvoiceLinesTable
										loadPage={loadPage}
										invoiceNumber={invoice.invoiceNumber}
									/>
								</SpinnerContainer>
							</DetailsMain>
						</DetailsContainer>
					</>
				)}
			</Grid.Item>
		</Grid>
	);
};
