import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { InvoiceLine, SearchInvoiceLine } from '../types/invoices/invoice';
import { Page } from '../types/page';
import { UseInvoiceLinesStore } from '../types/invoices/invoice-lines-store';
import { DEFAULT_INVOICE_LINES_SORTING } from '../constants/invoice-lines-sorting';

export const INITIAL_PAGE: Page<InvoiceLine> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseInvoiceLinesStore = {
	filters: {
		query: '',
		minQuantity: '',
		maxQuantity: '',
		minUnitPrice: '',
		maxUnitPrice: '',
		minAmount: '',
		maxAmount: '',
	},
	page: INITIAL_PAGE,
	sorting: DEFAULT_INVOICE_LINES_SORTING.value,
	notification: undefined,
	updateNumberRange: () => undefined,
	clearFilters: () => undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	updateSorting: () => undefined,
	resetStore: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = <T>(
	state: UseInvoiceLinesStore,
	fieldName: keyof SearchInvoiceLine,
	newValue: T | undefined
): Pick<UseInvoiceLinesStore, 'filters'> => ({
	filters: {
		...state.filters,
		[fieldName]: newValue,
	},
});

export const useInvoiceLinesStore = createWithEqualityFn<UseInvoiceLinesStore>(
	(set) => ({
		...initialState,
		updateNumberRange: (fieldName, minOrMaxNumber) =>
			set((state) => updateFilter<string>(state, fieldName, minOrMaxNumber)),
		clearFilters: () =>
			set((state) => ({
				filters: initialState.filters,
				page: { ...state.page, pageNumber: 1 },
				sorting: DEFAULT_INVOICE_LINES_SORTING.value,
			})),
		setPage: (page) => set({ page }),
		setQuery: (query) =>
			set((state) => updateFilter<string>(state, 'query', query)),
		updateSorting: (sorting): void => set({ sorting }),
		resetStore: () =>
			set(() => ({
				filters: initialState.filters,
				page: INITIAL_PAGE,
				sorting: DEFAULT_INVOICE_LINES_SORTING.value,
			})),
		setNotification: (notification) => set({ notification }),
	}),
	shallow
);
