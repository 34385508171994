import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { UseContractorsStore } from '../types/contractors/contractor-store';
import { Contractor } from '../types/contractors/contractor';
import { Page } from '../types/page';

export const INITIAL_PAGE: Page<Contractor> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseContractorsStore = {
	filters: {
		query: '',
	},
	page: INITIAL_PAGE,
	notification: undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = (state: UseContractorsStore, newValue: string) => ({
	filters: {
		...state.filters,
		query: newValue,
	},
	page: initialState.page,
});

export const useContractorsStore = createWithEqualityFn<UseContractorsStore>(
	(set) => ({
		...initialState,
		setPage: (page) => set({ page }),
		setQuery: (query) => set((state) => updateFilter(state, query)),
		setNotification: (notification) => set({ notification }),
	}),
	shallow
);
