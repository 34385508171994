import { Dispatch, SetStateAction } from 'react';

import FormFieldButton from '@anwb/poncho/components/form-field-button';
import FormFieldInput from '@anwb/poncho/components/form-field-input';

import { Debtor } from '../../../../../types/contractors/contractor';
import {
	RelationNumberInputRow,
	RelationNumberInputContainer,
	RelationTrashContainer,
	FullWidthButtonTertiary,
} from './styles/relationNumberInput.styled';
import { useValidateRelationNumber } from './hooks/useValidateRelationNumber';

type RelationNumberInputProps = {
	debtors: Debtor[];
	setDebtors: Dispatch<SetStateAction<Debtor[]>>;
};

export const RelationNumberInput = ({
	debtors,
	setDebtors,
}: RelationNumberInputProps) => {
	const validateRelationNumber = useValidateRelationNumber(debtors, setDebtors);

	const deleteRelationItem = (index: number) =>
		setDebtors((prevState) => [...prevState.filter((_item, i) => index !== i)]);

	return (
		<>
			{debtors.map((item, index) => (
				<RelationNumberInputRow
					key={`relation-number-row-${index}`}
					data-test="relation-number-row"
				>
					<RelationNumberInputContainer>
						<FormFieldInput
							key={`relation-number-${index}`}
							label="Relatienummer"
							value={item.relationNumber}
							name={`relation-number-${index}`}
							reference={`relation-number-${index}`}
							handlerValidationDynamic={validateRelationNumber}
							required
						/>
					</RelationNumberInputContainer>
					<FormFieldInput
						key={`debtor-${index}`}
						label="Debiteur"
						value={item.name}
						name={`debtor-${index}`}
						reference={`debtor-${index}`}
						required
						disabled
					/>
					{debtors.length > 1 && (
						<RelationTrashContainer>
							<FormFieldButton
								data-test="button-delete"
								aria-label="Relatienummer verwijderen"
								color="secondary"
								icon="trash"
								// @ts-ignore
								onClick={() => deleteRelationItem(index)}
							/>
						</RelationTrashContainer>
					)}
				</RelationNumberInputRow>
			))}
			<FullWidthButtonTertiary
				icon="plus"
				onClick={() =>
					setDebtors((prevState) => [
						...prevState,
						{ relationNumber: '', name: '', contractNumbers: [] },
					])
				}
			>
				Voeg een relatienummer toe
			</FullWidthButtonTertiary>
		</>
	);
};
