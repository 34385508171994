import { formatAddress } from '@anwb/poncho/components/panel/variants/PanelContact/utils';
import { formatFirstCaseToUpper } from '@anwb/poncho/utilities/formatters';

import { AddressPhv } from '../types/cases/phv-case';
import { Address } from '../types/cases/common-case';

export const formatMazStreet = (address?: Address | AddressPhv) => {
	if (!address) return;
	const { street, houseNumber = '', houseNumberSuffix = '' } = address;

	if (!street) return;

	return formatAddress({
		street: formatFirstCaseToUpper(street) || '',
		houseNumber,
		houseNumberSuffix,
	});
};
