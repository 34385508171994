import Icon from '@anwb/poncho/components/icon';
import Table from '@anwb/poncho/components/table';
import {
	DateHeaderCell,
	HeaderCell,
	IconHeaderCell,
} from '../../../../styles/table.styled';

type InvoiceHeaderProps = {
	description: string;
};

export const InvoiceHeader = ({ description }: InvoiceHeaderProps) => (
	<Table.Header>
		<Table.Row>
			<HeaderCell>FactuurNr</HeaderCell>
			<HeaderCell>C/D</HeaderCell>
			<HeaderCell>Status</HeaderCell>
			<HeaderCell>{description}</HeaderCell>
			<DateHeaderCell>Datum</DateHeaderCell>
			<HeaderCell>
				Totaal <br />
				&euro; incl. BTW
			</HeaderCell>
			<IconHeaderCell>
				<Icon variant="save" size="md" />
			</IconHeaderCell>
		</Table.Row>
	</Table.Header>
);
