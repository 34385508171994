import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import ApplicationSizeProvider from '@anwb/poncho/providers/providers-application';
import {
	ANWBBrandRefresh as ANWBDefaultTheme,
	Fonts,
} from '@anwb/poncho/design-tokens/theme';
import { GlobalStyle } from '@zama/global-style';

import { NAVIGATION } from '../constants/navigation';
import { DefaultRoute } from './DefaultRoute/DefaultRoute';
import PageLayout from './PageLayout/PageLayout';

const Application = () => (
	<ThemeProvider theme={ANWBDefaultTheme}>
		<ApplicationSizeProvider>
			{
				// @ts-ignore
				<Fonts />
			}
			{
				// @ts-ignore
				<GlobalStyle />
			}
			<BrowserRouter>
				<Routes>
					<Route element={<PageLayout />}>
						{NAVIGATION()
							.filter((nav) => nav.show)
							.map((nav) => (
								<Route key={nav.title} path={nav.path} element={nav.child} />
							))}
					</Route>
					<Route path="*" element={<DefaultRoute />} />
				</Routes>
			</BrowserRouter>
		</ApplicationSizeProvider>
	</ThemeProvider>
);

export default Application;
