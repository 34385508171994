import { endSession } from '../../../auth/utils/endSession';
import { NAVIGATION } from '../../../constants/navigation';
import {
	StyledHeader,
	StyledLogo,
	StyledNavBar,
	StyledTopBar,
	StyledLogoTitle,
	StyledList,
	StyledListItem,
	StyledLinkTitle,
	StyledLogout,
} from './styles/app-header.styled';
import anwbLogo from './images/anwb.svg';
import MenuItem from './MenuItem/MenuItem';

const AppHeader = () => (
	<StyledHeader>
		<StyledTopBar>
			<StyledLogo src={anwbLogo} alt="ANWB" />
			<StyledLogoTitle variant="label-title">Zakelijk</StyledLogoTitle>
			<StyledLogout onClick={endSession}>Uitloggen</StyledLogout>
		</StyledTopBar>
		<StyledNavBar>
			<StyledList>
				{NAVIGATION()
					.filter((nav) => nav.show)
					.map((nav) => (
						<StyledListItem key={nav.title}>
							<MenuItem to={nav.navPath}>
								<StyledLinkTitle
									variant="label-title"
									data-test={`button-${nav.title.toLowerCase().replace(' ', '-')}`}
								>
									{nav.title}
								</StyledLinkTitle>
							</MenuItem>
						</StyledListItem>
					))}
			</StyledList>
		</StyledNavBar>
	</StyledHeader>
);

export default AppHeader;
