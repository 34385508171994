import { ReactNode } from 'react';

import Typography from '@anwb/poncho/components/typography';

import { NoItemsFoundContainer } from './styled/no-items-found.styled';

type NoItemsFoundProps = {
	show: boolean;
	children: ReactNode;
};

export const NoItemsFound = ({ show, children }: NoItemsFoundProps) => {
	return (
		show && (
			<NoItemsFoundContainer>
				<Typography variant="body-text">{children}</Typography>
			</NoItemsFoundContainer>
		)
	);
};
