import { AxiosError } from 'axios';

import { endSession } from '../../auth/utils/endSession';

export const errorInterceptor = (error: AxiosError) => {
	if (error.response && error.response.status === 403) {
		endSession();
	}
	return Promise.reject(error);
};
