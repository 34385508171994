import axios from 'axios';

import {
	COGNITO_USER_POOL_ID,
	URL_COGNITO_TOKEN_REVOCATION_LAMBDA,
} from '../constants/cognito';

export const revokeUserTokens = async (username: string) => {
	try {
		await axios.post(
			URL_COGNITO_TOKEN_REVOCATION_LAMBDA,
			{
				userPoolId: COGNITO_USER_POOL_ID,
				username,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
	} catch (error) {
		console.error('Error invoking Lambda:', error);
	}
};
