import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { UseRelationsStore } from '../types/relations/relation-store';
import { Page } from '../types/page';
import { User } from '../types/users/user';

export const INITIAL_PAGE: Page<User> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseRelationsStore = {
	filters: {
		query: '',
	},
	page: INITIAL_PAGE,
	notification: undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = (state: UseRelationsStore, newValue: string) => ({
	filters: {
		...state.filters,
		query: newValue,
	},
	page: initialState.page,
});

export const useRelationsStore = createWithEqualityFn<UseRelationsStore>(
	(set) => ({
		...initialState,
		setPage: (page) => set({ page }),
		setQuery: (query) => set((state) => updateFilter(state, query)),
		setNotification: (notification) => set({ notification }),
	}),
	shallow
);
