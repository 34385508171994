import Notification from '@anwb/poncho/components/notification';
import Typography from '@anwb/poncho/components/typography';

import {
	DetailsAside,
	DetailsContainer,
	DetailsMain,
} from '../../../../styles/details-page';
import { CASE_ACTION_SORTINGS } from '../../../../constants/case-action-sortings';
import { AnyAction } from '../../../../types/cases/common-case';
import { AnyPage, AnySort } from '../../../../types/unions';
import { CASE_ACTION_FILTER_DATA } from '../../../Common/Filter/constants/case-action-filter-data';
import { CustomPagination } from '../../../Common/CustomPagination/CustomPagination';
import SpinnerContainer from '../../../Common/SpinnerContainer/SpinnerContainer';
import { SortBar } from '../../../Common/SortBar/SortBar';
import Filter from '../../../Common/Filter/Filter';
import { CasesType } from '../../types/cases';
import { DetailsHistoryHeader } from './styles/case-actions.styled';
import { CaseActionPanel } from './CaseActionPanel/CaseActionPanel';
import { useProcessCaseActions } from './hooks/useProcessCaseActions';
import { useCaseActionStoreVhv } from '../../../../store/caseActionsStoreVhv';
import { useCaseActionStorePhv } from '../../../../store/caseActionsStorePhv';

type CaseActionProps = {
	casesType: CasesType;
	actions: AnyAction[];
};

const CaseActions = ({ casesType, actions }: CaseActionProps) => {
	const store =
		casesType === 'vhv' ? useCaseActionStoreVhv : useCaseActionStorePhv;
	const {
		updateSorting,
		showSpinner,
		page,
		setPage,
		clearFilters,
		notification,
	} = useProcessCaseActions(actions, store);

	return (
		<DetailsContainer>
			<DetailsHistoryHeader>
				<Typography variant="group-component-title">Geschiedenis</Typography>
				<SortBar
					value=""
					sortings={CASE_ACTION_SORTINGS}
					updateSorting={updateSorting as unknown as (sorting: AnySort) => void}
				/>
			</DetailsHistoryHeader>
			<DetailsAside>
				<Filter
					filterData={CASE_ACTION_FILTER_DATA}
					store={store}
					filterType="caseActions"
					clearFilters={clearFilters}
				/>
			</DetailsAside>
			<DetailsMain $padding="left">
				{notification && (
					<Notification variant={notification.type} title={notification.title}>
						{notification.description}
					</Notification>
				)}
				<SpinnerContainer
					showSpinner={showSpinner}
					message="Hulpacties worden geladen"
				>
					{page.items.length &&
						page.items.map((action, index) => (
							<CaseActionPanel key={`action-${index}`} action={action} />
						))}
					<CustomPagination
						page={page}
						setPage={setPage as (page: AnyPage) => void}
					/>
				</SpinnerContainer>
			</DetailsMain>
		</DetailsContainer>
	);
};

export default CaseActions;
