import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PhvCase } from '../../../../types/cases/phv-case';
import { VhvCase } from '../../../../types/cases/vhv-case';
import { UseCasesStore } from '../../../../types/cases/cases-store';
import { getCasesStore } from '../../utils/getCasesStore';
import { CasesType } from '../../types/cases';

export const useSelectedCase = (casesType: CasesType) => {
	const navigate = useNavigate();
	const { caseId } = useParams();
	const [selectedCase, setSelectedCase] = useState<PhvCase | VhvCase>();

	const store = getCasesStore(casesType);
	const { page } = store((state: UseCasesStore) => ({
		page: state.page,
	}));

	useEffect(() => {
		if (page.items.length === 0) {
			navigate(`/dossiers-${casesType}`);
		}
		if (page.items.length) {
			setSelectedCase(
				page.items.find(
					(foundCase) => foundCase.caseSummary.caseNumber === caseId
				)
			);
		}
	}, [caseId, page.items, casesType, navigate]);

	return selectedCase;
};
