import { ReactNode } from 'react';

import {
	InvoiceType,
	PaymentState,
} from '../../../../../types/invoices/invoice';
import { PaymentStatusButton } from '../PaymentStatusButton/PaymentStatusButton';

export const formatOpenClosed = (status: PaymentState): string | ReactNode =>
	status === 'OPEN' ? <PaymentStatusButton /> : 'Betaald';

export const formatInvoiceType = (invoiceType: InvoiceType): string =>
	invoiceType === 'SINGLE' ? 'Losse factuur' : 'Verzamelfactuur';
