import { SearchInvoice, SearchInvoiceLine } from '../types/invoices/invoice';
import { Pageable } from '../types/pageable';
import { InvoiceLineSort, InvoiceSort } from '../types/sort';
import { API_URL_INVOICE_LINES, API_URL_INVOICES } from '../constants/api';
import {
	toInvoiceLineQueryParams,
	toInvoiceQueryParams,
	toPageParams,
} from './toInvoiceQueryParameters';
import api from './api';

export const getInvoices = (
	searchInvoice: SearchInvoice,
	pageable: Pageable<InvoiceSort>
) => {
	const searchParams = toInvoiceQueryParams(searchInvoice);
	if (searchParams === null) {
		return Promise.reject('the filter is not valid');
	}
	const pageParams = toPageParams(pageable);
	const url = `${API_URL_INVOICES}?${pageParams}&${searchParams}`;
	return api.get(url);
};

export const getInvoiceLines = (
	invoiceNumber: string,
	searchInvoiceLine: SearchInvoiceLine,
	pageable: Pageable<InvoiceLineSort>
) => {
	const searchParams = toInvoiceLineQueryParams(searchInvoiceLine);
	if (searchParams === null) {
		return Promise.reject('the filter is not valid');
	}
	const pageParams = toPageParams(pageable);
	const url = `${API_URL_INVOICE_LINES}?invoiceNumber=${invoiceNumber}&${pageParams}&${searchParams}`;
	return api.get(url);
};
