import axios, { AxiosError } from 'axios';
import { COGNITO_BASE_URL } from '../constants/cognito';

export const globalSignOut = async (accessToken: string) => {
	const headers = {
		'Content-Type': 'application/x-amz-json-1.1',
		'X-Amz-Target': 'AWSCognitoIdentityProviderService.GlobalSignOut',
	};
	const data = {
		AccessToken: accessToken,
	};

	try {
		await axios.post(COGNITO_BASE_URL, data, { headers });
	} catch (error) {
		const err = error as unknown as AxiosError;
		console.error(
			'Error signing out user:',
			err.response ? err.response.data : err.message
		);
	}
};
