import DescriptionList from '@anwb/poncho/components/description-list';
import { Panel } from '@anwb/poncho/components/panel';

import { Invoice } from '../../../../types/invoices/invoice';

import {
	createPhvInsuredDetails,
	createPhvInvoiceDetails,
} from './utils/createPhvInvoiceDetails';
import { PhvInvoiceDetailsTitle } from './styles/phv-invoice-details.styled';

type PhvInvoiceDetailsProps = {
	invoice: Invoice;
};

export const PhvInvoiceDetails = ({ invoice }: PhvInvoiceDetailsProps) => (
	<Panel hasOuterSpacing={false}>
		<Panel.Header title="Personenhulpverlening" />
		<Panel.Content>
			<DescriptionList items={createPhvInvoiceDetails(invoice)} />
			<PhvInvoiceDetailsTitle>Polishouder/verzekerde</PhvInvoiceDetailsTitle>
			<DescriptionList items={createPhvInsuredDetails(invoice)} />
		</Panel.Content>
	</Panel>
);
