import styled, { css } from 'styled-components';

const detailsContainerStyles = () => css`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;
`;

export const DetailsContainer = styled.div`
	${detailsContainerStyles};
`;

const detailsMainStyles = ({ $padding }: { $padding?: string }) => css`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 75%;
	padding: ${$padding === 'right' && '0 20px 0 0'};
	padding: ${$padding === 'left' && '0 0 0 20px'};
`;

export const DetailsMain = styled.div`
	${detailsMainStyles};
`;

const detailsAsideStyles = () => css`
	width: 25%;
`;

export const DetailsAside = styled.div`
	${detailsAsideStyles};
`;
