import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import { FormState } from '@zama/types';

import { revokeUserTokens } from '../../../auth/api/revokeUserTokens';
import { ContentHeader } from '../../../styles/page-layout.styled';
import { useRelationsStore } from '../../../store/relationsStore';
import { UseRelationsStore } from '../../../types/relations/relation-store';
import { User } from '../../../types/users/user';
import { updateRelation } from '../../../utils/relationService';
import { RelationForm } from '../common/RelationForm/RelationForm';
import { formatRelation } from '../utils/formatRelation';

export const EditRelation = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedItem, setSelectedItem] = useState<User>();
	const [showError, setShowError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const relationId = location.pathname.split('/').pop();

	const { page, setNotification } = useRelationsStore(
		(state: UseRelationsStore) => ({
			page: state.page,
			setNotification: state.setNotification,
		})
	);

	const onCancelHandler = () => navigate(-1);

	const onSubmitHandler = (formData: unknown) => {
		setIsLoading(true);
		setShowError(false);
		const { valid, elementsData } = formData as FormState;

		if (valid && relationId && selectedItem) {
			const changedRelation = formatRelation(elementsData);
			updateRelation({
				...changedRelation,
				relationId,
				userId: selectedItem.userId,
			})
				.then(() => {
					setNotification({
						type: 'success',
						title: 'Relatie bijgewerkt',
						description: `${changedRelation.relationName} is succesvol bijgewerkt`,
					});
					navigate('/relatiebeheer');
					revokeUserTokens(selectedItem.userId);
				})
				.catch(() => {
					setShowError(true);
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!page.items.length) {
			// WV: in case items were erased by page refresh
			navigate('/relatiebeheer');
		}
		setSelectedItem(page.items.find((item) => item.relationId === relationId));
	}, []);

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Relatie bewerken</ContentHeader>
				{showError && (
					<Notification
						dataTest="bad-response"
						variant="error"
						title="Door technische redenen is het niet gelukt de relatie te wijzigen. Probeer het opnieuw."
					/>
				)}
				{selectedItem && (
					<RelationForm
						selectedItem={selectedItem}
						isLoading={isLoading}
						onCancelHandler={onCancelHandler}
						onSubmitHandler={onSubmitHandler}
					/>
				)}
			</Grid.Item>
		</Grid>
	);
};
