import { useState } from 'react';

import DescriptionList from '@anwb/poncho/components/description-list';
import Notification from '@anwb/poncho/components/notification';
import { Panel } from '@anwb/poncho/components/panel';

import { Invoice } from '../../../../types/invoices/invoice';
import { DetailPanelHeader } from '../../../Common/DetailPanelHeader/DetailPanelHeader';
import { createInvoiceDetailDescriptionList } from './utils/createInvoiceDetailDescriptionList';

type GeneralInvoiceDetailsProps = {
	invoice: Invoice;
};

export const GeneralInvoiceDetails = ({
	invoice,
}: GeneralInvoiceDetailsProps) => {
	const [showError, setShowError] = useState(false);
	return (
		<Panel hasOuterSpacing={false}>
			<Panel.Content>
				<DetailPanelHeader title="Factuurgegevens" />
				{showError && <Notification variant="error" title="Er ging iets mis" />}
				<DescriptionList
					items={createInvoiceDetailDescriptionList(invoice, setShowError)}
				/>
			</Panel.Content>
		</Panel>
	);
};
