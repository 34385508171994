import { ChangeEvent } from 'react';

import Typography from '@anwb/poncho/components/typography';
import { Input } from '@anwb/poncho/components/forms';

import { FieldContainer } from './styles/range-form-field.styled';

type RangeFormFieldProps = {
	type: 'from' | 'to';
	id: string;
	value: string;
	placeholder: string;
	onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
	isValid: boolean;
	variant?: string;
};

const RangeFormField = ({
	type,
	id,
	value,
	placeholder,
	onChangeHandler,
	isValid,
	variant = 'body-text',
}: RangeFormFieldProps) => (
	<FieldContainer>
		<Typography
			tagName="label"
			variant={variant}
			data-test={`label-title-${id}`}
			htmlFor={id}
		>
			{type === 'from' && <span>Van</span>}
			{type === 'to' && <span>Tot</span>}
		</Typography>
		<Input
			data-test={id}
			reference={id}
			name={id}
			placeholder={placeholder}
			value={value}
			onChange={onChangeHandler}
			valid={isValid}
		/>
	</FieldContainer>
);

export default RangeFormField;
