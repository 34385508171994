import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import { ButtonPrimary } from '@anwb/poncho/components/button';
import Notification from '@anwb/poncho/components/notification';
import { Panel } from '@anwb/poncho/components/panel';

import { useContractorsStore } from '../../../store/contractorsStore';
import { UseContractorsStore } from '../../../types/contractors/contractor-store';
import { AnyPage } from '../../../types/unions';
import { Contractor } from '../../../types/contractors/contractor';
import { ContentHeader } from '../../../styles/page-layout.styled';
import PanelTitleWithMenu from '../../Common/PanelTitleWithMenu/PanelTitleWithMenu';
import { SearchBar } from '../../Common/SearchBar/SearchBar';
import { CustomPagination } from '../../Common/CustomPagination/CustomPagination';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { NoItemsFound } from '../../Common/NoItemsFound/NoItemsFound';
import { DeleteModal } from '../../Common/DeleteModal/DeleteModal';

import useProcessContractors from './hooks/useProcessContractors';
import { OverviewPanelContainer } from './styles/contractors-overview.styled';
import { deleteContractor } from './utils/deleteContractor';

export const ContractorsOverview = () => {
	const navigate = useNavigate();
	const { showSpinner, loadPage } = useProcessContractors();
	const [obsoleteContractor, setObsoleteContractor] = useState<Contractor>();
	const [modalOpen, setModalOpen] = useState(false);

	const { page, filters, notification, setQuery, setNotification } =
		useContractorsStore((state: UseContractorsStore) => ({
			page: state.page,
			filters: state.filters,
			notification: state.notification,
			setQuery: state.setQuery,
			setNotification: state.setNotification,
		}));

	const onDeleteHandler = () =>
		obsoleteContractor &&
		deleteContractor(obsoleteContractor.id)
			.then(() => {
				loadPage(page.pageNumber);
				setNotification({
					type: 'success',
					title: 'Relatie verwijderd',
					description: `${obsoleteContractor.name} is succesvol verwijderd`,
				});
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: 'Relatie verwijderen mislukt',
					description: `Door een technische fout is ${obsoleteContractor.name} niet verwijderd. Probeer het later nog eens.`,
				});
			})
			.finally(() => {
				setObsoleteContractor(undefined);
			});

	useEffect(() => () => setNotification(undefined), []);

	return (
		<>
			<DeleteModal
				obsoleteItem={obsoleteContractor}
				modalOpen={modalOpen}
				setDeleteModalOpen={setModalOpen}
				onDeleteHandler={onDeleteHandler}
			/>
			<Grid columns={1} constrainedWidth>
				<Grid.Item>
					<ContentHeader>
						<span>Relatiebeheer</span>
						<ButtonPrimary
							icon="plus"
							onClick={() => navigate('/relatiebeheer/relatie-toevoegen')}
						>
							Relatie toevoegen
						</ButtonPrimary>
					</ContentHeader>
					<Grid columns={1}>
						<Grid.Item>
							<SearchBar
								query={filters.query}
								placeHolder="Zoek op Naam"
								searchBarType="contractor"
								setQuery={setQuery}
							/>

							{notification && (
								<Notification
									variant={notification.type}
									title={notification.title}
								>
									{notification.description}
								</Notification>
							)}

							<SpinnerContainer
								showSpinner={showSpinner}
								message="Relaties worden geladen"
							>
								{page.items.map((contractor, index) => (
									<OverviewPanelContainer key={`overview-panel-${index}`}>
										<Panel
											key={`panel-${index}`}
											dataTest={`panel-${index}`}
											hasOuterSpacing={false}
										>
											<Panel.Content>
												<PanelTitleWithMenu
													title={contractor.name}
													onSelectHandler={() =>
														navigate(
															`/relatiebeheer/relatie-bewerken/${contractor.id}`
														)
													}
													onDeleteHandler={() => {
														setObsoleteContractor(contractor);
														setModalOpen(true);
													}}
												/>
											</Panel.Content>
										</Panel>
									</OverviewPanelContainer>
								))}
								<NoItemsFound show={!page.items.length}>
									Geen relaties gevonden
								</NoItemsFound>
							</SpinnerContainer>
							<CustomPagination
								page={page}
								setPage={(newPage: AnyPage) => loadPage(newPage.pageNumber)}
							/>
						</Grid.Item>
						<Grid.Aside>&nbsp;</Grid.Aside>
					</Grid>
				</Grid.Item>
			</Grid>
		</>
	);
};
