import { TransportLocationType } from '../../../../../../../../types/cases/common-case';

export const getAssistanceActionLocationType = (
	type: TransportLocationType
) => {
	switch (type) {
		case 'AFHAAL_LOCATIE':
			return 'Afhaallocatie';
		case 'AFLEVER_LOCATIE':
			return 'Afleverlocatie';
		default:
			return '-';
	}
};
