import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@anwb/poncho/components/grid';
import LicensePlate from '@anwb/poncho/components/license-plate-text';
import { Panel } from '@anwb/poncho/components/panel';

import { ContentHeader } from '../../../styles/page-layout.styled';
import { ReturnLink } from '../../../styles/return-link';
import { DetailsContainer } from '../../../styles/details-page';
import { PhvCase } from '../../../types/cases/phv-case';
import { VhvCase } from '../../../types/cases/vhv-case';
import { CasesZustandStore } from '../../../types/zustand';
import { UseCasesStore } from '../../../types/cases/cases-store';
import { getVhvCase } from '../../../utils/vhvCaseApi';
import { getPhvCase } from '../../../utils/phvCaseApi';
import { DetailPanelHeader } from '../../Common/DetailPanelHeader/DetailPanelHeader';
import { formatStatus } from '../utils/general';
import {
	createPhvDescriptionListAssistanceRequester,
	createPhvDescriptionListGeneral,
	createPhvDescriptionListInsuredData,
	createPhvDescriptionListSecondaryInsurances,
} from '../utils/createPhvDescriptionLists';
import {
	createVhvDescriptionListDriver,
	createVhvDescriptionListFirstDiagnosis,
	createVhvDescriptionListGeneral,
	createVhvDescriptionListInitialLocation,
	createVhvDescriptionListTransportDestination,
	createVhvDescriptionListTravelContext,
	createVhvDescriptionListVehicle,
} from '../utils/createVhvDescriptionLists';
import { CasesType } from '../types/cases';
import {
	DescriptionListColumn,
	DescriptionListContainer,
} from './styles/cases-details.styled';
import DescriptionListBlock from './DescriptionListBlock/DescriptionListBlock';
import CaseActions from './CaseActions/CaseActions';

type CasesDetailsProps = {
	casesType: CasesType;
	store: CasesZustandStore;
};

export const CasesDetails = ({ casesType, store }: CasesDetailsProps) => {
	const navigate = useNavigate();
	const { caseId } = useParams();
	const [selectedCase, setSelectedCase] = useState<PhvCase | VhvCase>();

	const { setActiveCase } = store((state: UseCasesStore) => ({
		setActiveCase: state.setActiveCase,
	}));

	useEffect(() => {
		if (casesType === 'vhv') {
			setSelectedCase(getVhvCase(caseId as string));
		} else {
			setSelectedCase(getPhvCase(caseId as string));
		}
	}, [caseId]);

	return (
		(selectedCase && (
			<Grid constrainedWidth>
				<Grid.Item>
					<ReturnLink
						onClick={() => {
							setActiveCase(undefined);
							navigate(`/dossiers-${casesType}`);
						}}
					>
						Terug naar dossiers
					</ReturnLink>
					<ContentHeader>{`Dossier ${
						selectedCase.caseSummary.caseNumber
					} (${formatStatus(selectedCase.caseSummary.status)})`}</ContentHeader>
					<DetailsContainer>
						<Panel hasOuterSpacing={false}>
							<Panel.Content>
								<DetailPanelHeader title="Samenvatting" />
								{casesType === 'phv' && (
									<DescriptionListContainer>
										<DescriptionListColumn>
											<DescriptionListBlock
												title="Algemeen"
												items={createPhvDescriptionListGeneral(
													selectedCase as PhvCase
												)}
											/>

											<DescriptionListBlock
												title="Hulpvrager"
												items={createPhvDescriptionListAssistanceRequester(
													selectedCase as PhvCase
												)}
											/>
										</DescriptionListColumn>
										<DescriptionListColumn>
											<DescriptionListBlock
												title="Gegevens verzekerde"
												items={createPhvDescriptionListInsuredData(
													selectedCase as PhvCase
												)}
											/>

											<DescriptionListBlock
												title="Overige verzekeringen"
												items={
													createPhvDescriptionListSecondaryInsurances()
													// selectedCase as RealPhvCase
												}
											/>
										</DescriptionListColumn>
									</DescriptionListContainer>
								)}

								{casesType === 'vhv' && (
									<DescriptionListContainer>
										<DescriptionListColumn>
											<DescriptionListBlock
												title="Algemeen"
												items={createVhvDescriptionListGeneral(
													selectedCase as VhvCase
												)}
											/>

											<DescriptionListBlock
												title="Bestuurder"
												items={createVhvDescriptionListDriver(
													selectedCase as VhvCase
												)}
											/>

											<DescriptionListBlock
												title="Voertuiggegevens"
												items={createVhvDescriptionListVehicle(
													selectedCase as VhvCase
												)}
												customComponent={<LicensePlate value="xj785t" />}
											/>
										</DescriptionListColumn>
										<DescriptionListColumn>
											<DescriptionListBlock
												title="Initiële locatie"
												items={createVhvDescriptionListInitialLocation(
													selectedCase as VhvCase
												)}
												googleMaps="#"
											/>

											<DescriptionListBlock
												title="Eerste diagnose"
												items={
													createVhvDescriptionListFirstDiagnosis()
													// selectedCase as RealVhvCase
												}
											/>

											<DescriptionListBlock
												title="Reiscontext"
												items={
													createVhvDescriptionListTravelContext()
													// selectedCase as RealVhvCase
												}
											/>

											<DescriptionListBlock
												title="Eindbestemming voertuigtransport"
												items={
													createVhvDescriptionListTransportDestination()
													// selectedCase as RealVhvCase
												}
												googleMaps="#"
											/>
										</DescriptionListColumn>
									</DescriptionListContainer>
								)}
							</Panel.Content>
						</Panel>
					</DetailsContainer>
					<CaseActions casesType={casesType} actions={selectedCase.actions} />
				</Grid.Item>
			</Grid>
		)) || <div />
	);
};
