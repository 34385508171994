import { ChangeEvent } from 'react';

import { SearchInvoice } from '../../../../../types/invoices/invoice';
import { NumberRangeType } from '../../../../../types/filters';
import RangeFormField from '../../../RangeFormField/RangeFormField';
import { isMinValid, isMaxValid } from '../../../RangeFormField/rangeValidator';
import { FieldWrapper } from './styles/number-range-section.styled';

type NumberRangeSectionProps = {
	filters: SearchInvoice;
	updateNumberRange: (
		fieldName: NumberRangeType,
		minOrMaxNumber: string | undefined
	) => void;
};

const NumberRangeSection = ({
	filters,
	updateNumberRange,
}: NumberRangeSectionProps) => {
	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name) {
			updateNumberRange(name as NumberRangeType, value);
		}
	};

	return (
		<FieldWrapper>
			<RangeFormField
				type="from"
				id="minAmount"
				value={filters.minAmount || ''}
				placeholder="Bijv.: 10"
				onChangeHandler={onChangeHandler}
				variant="label-title"
				isValid={isMinValid(filters.minAmount || '', filters.maxAmount || '')}
			/>

			<RangeFormField
				type="to"
				id="maxAmount"
				value={filters.maxAmount || ''}
				placeholder="Bijv.: 100"
				onChangeHandler={onChangeHandler}
				variant="label-title"
				isValid={isMaxValid(filters.minAmount || '', filters.maxAmount || '')}
			/>
		</FieldWrapper>
	);
};

export default NumberRangeSection;
