import { TravelContextVhv, VhvCase } from '../../../types/cases/vhv-case';
import { PhvCase, TravelContextPhv } from '../../../types/cases/phv-case';
import { DescriptionListItem } from '../../../types/poncho';
import { formatDate } from '../../../utils/formatDate';
import { hasProperty } from '../../../types/hasProperty';

// TODO: sync output for phv and vhv in the backend so we can use the same function for both
const formatWhenPhv = (when?: TravelContextPhv['when']) => {
	switch (when) {
		case 'VOOR_AANVANG_REIS':
			return 'Voor aanvang reis';
		case 'RONDREIS':
			return 'Rondreis';
		case 'PLAATS_VAN_BESTEMMING':
			return 'Plaats van bestemming';
		case 'WOONACHTIG':
			return 'Woonachtig';
		case 'HEENREIS':
			return 'Heenreis';
		case 'TERUGREIS':
			return 'Terugreis';
		default:
			return undefined;
	}
};

const formatWhenVhv = (when?: TravelContextVhv['when']) => {
	switch (when) {
		case 'PLAATS_VAN_BESTEMMING':
			return 'Plaats van bestemming';
		case 'HEENREIS':
			return 'Heenreis';
		case 'TERUGREIS':
			return 'Terugreis';
		case 'RONDREIS':
			return 'Rondreis';
		case 'WOONPLAATS_BUITENLAND':
			return 'Woonplaats buitenland';
		case 'VOOR_VERTREK':
			return 'Voor vertrek';
		case 'NA_TERUGKOMST':
			return 'Na terugkomst';
		case 'UNKNOWN':
			return 'Onbekend';
		default:
			return undefined;
	}
};

export const createDescriptionListTravelContext = <T extends VhvCase | PhvCase>(
	currentCase: T
): DescriptionListItem[] => [
	{
		key: 'Richting',
		value: hasProperty(currentCase, 'vehicle')
			? formatWhenVhv(
					currentCase.caseSummary.travelContext
						?.when as TravelContextVhv['when']
				)
			: formatWhenPhv(
					currentCase.caseSummary.travelContext
						?.when as TravelContextPhv['when']
				),
	},
	{
		key: 'Vertrekdatum',
		value:
			currentCase.caseSummary.travelContext?.departure &&
			formatDate(currentCase.caseSummary.travelContext?.departure),
	},
	{
		key: 'Aankomstdatum',
		value:
			currentCase.caseSummary.travelContext?.arrival &&
			formatDate(currentCase.caseSummary.travelContext?.arrival),
	},
	{
		key: 'Reisdoel',
		value: currentCase.caseSummary.travelContext?.country,
	},
];
