import {
	VhvCase,
	SearchVhvCase,
	VhvCaseSummary,
	Driver,
} from '../../../types/cases/vhv-case';
import { Address, PersonName } from '../../../types/cases/common-case';
import { Pageable } from '../../../types/pageable';
import { CaseSort } from '../../../types/sort';
import { Page } from '../../../types/page';
import { toPage } from '../../../utils/toPage';
import { isInRange } from '../../../utils/isInRange';
import { matchesOption } from '../../../utils/matchesOptions';
import { compare } from '../../../utils/compare';

import { getAllCases } from './vhvCasesDB';

const getVhvCases = (
	searchVhvCase: SearchVhvCase,
	pageable: Pageable<CaseSort>
) => {
	const page = getCases(searchVhvCase, pageable);

	return {
		items: page.items,
		pageNumber: page.pageNumber,
		totalPages: page.totalPages,
		totalItems: page.totalItems,
	} as Page<VhvCase>;
};

const getVhvCase = (caseNumber: string) =>
	getAllCases().find(
		(foundCase) => foundCase.caseSummary.caseNumber === caseNumber
	) as VhvCase;

const getCases = (
	searchVhvCase: SearchVhvCase,
	pageable: Pageable<CaseSort>
) => {
	const allCases = getAllCases();
	const sorting = pageable.sort;
	doSort(allCases, sorting);

	if (!searchVhvCase) {
		return toPage<VhvCase>(allCases, pageable);
	}

	const filteredCases = allCases.filter((aCase) =>
		doFilter(aCase, searchVhvCase)
	);
	return toPage<VhvCase>(filteredCases, pageable);
};

const doSort = (cases: VhvCase[], sorting: CaseSort) => {
	switch (sorting) {
		case 'ASC_CASE_DATE':
			cases.sort((lhs: VhvCase, rhs: VhvCase) =>
				compare(
					lhs.caseSummary.reportDateTime.getTime(),
					rhs.caseSummary.reportDateTime.getTime()
				)
			);
			break;
		case 'DESC_CASE_DATE':
			cases.sort((lhs: VhvCase, rhs: VhvCase) =>
				compare(
					rhs.caseSummary.reportDateTime.getTime(),
					lhs.caseSummary.reportDateTime.getTime()
				)
			);
			break;
		case 'ASC_CASE_NUMBER':
			cases.sort((lhs: VhvCase, rhs: VhvCase) =>
				compare(lhs.caseSummary.caseNumber, rhs.caseSummary.caseNumber)
			);
			break;
		case 'DESC_CASE_NUMBER':
			cases.sort((lhs: VhvCase, rhs: VhvCase) =>
				compare(rhs.caseSummary.caseNumber, lhs.caseSummary.caseNumber)
			);
			break;
		default:
			cases.sort((lhs: VhvCase, rhs: VhvCase) =>
				compare(
					rhs.caseSummary.reportDateTime.getTime(),
					lhs.caseSummary.reportDateTime.getTime()
				)
			);
	}
};

const doFilter = (vhvCase: VhvCase, searchVhvCase: SearchVhvCase) =>
	matchesSearchTerm(vhvCase, searchVhvCase) &&
	matchesRangeTerms(vhvCase, searchVhvCase) &&
	matchesOptionTerms(vhvCase, searchVhvCase);

const matchesSearchTerm = (vhvCase: VhvCase, searchVhvCase: SearchVhvCase) => {
	const { driver } = vhvCase.caseSummary;
	const { personName, address } = driver;

	if (searchVhvCase.query && searchVhvCase.query !== '') {
		const keysToSearch = [
			'postalCode',
			'surname',
			'dob',
			'country',
			'licensePlate',
			'vin',
			'caseNumber',
		];

		return keysToSearch.some(
			(key) =>
				(vhvCase.caseSummary[key as keyof VhvCaseSummary] ||
					driver[key as keyof Driver] ||
					personName[key as keyof PersonName] ||
					address[key as keyof Address]) &&
				(
					vhvCase.caseSummary[key as keyof VhvCaseSummary] ||
					driver[key as keyof Driver] ||
					personName[key as keyof PersonName] ||
					(address[key as keyof Address] as string)
				)
					// this mock will be removed when real API is implemented, so won't fix
					// @ts-ignore
					.indexOf(searchVhvCase.query as string) !== -1
		);
	}
	return !searchVhvCase.query || searchVhvCase.query !== '';
};

const matchesRangeTerms = (vhvCase: VhvCase, searchCase: SearchVhvCase) =>
	isInRange<Date>(
		vhvCase.caseSummary.reportDateTime,
		searchCase.minDate,
		searchCase.maxDate
	);

const matchesOptionTerms = (aCase: VhvCase, searchCase: SearchVhvCase) =>
	matchesOption(searchCase.caseStates, aCase.caseSummary.status) &&
	matchesOption(
		searchCase.initialLocations,
		aCase.caseSummary.initialLocation
	) &&
	matchesOption(searchCase.passedOns, aCase.caseSummary.passedOn) &&
	matchesOption(
		searchCase.vehicleTypes,
		aCase.caseSummary.vehicle.vehicleType
	) &&
	matchesOption(
		searchCase.vhvAssistanceCategories,
		aCase.caseSummary.assistanceCategory
	) &&
	matchesOption(
		searchCase.vhvIncidentCategories,
		aCase.caseSummary.incidentCategory
	);

const mockedVhvCaseApi = {
	getVhvCase,
	getVhvCases,
};

export { mockedVhvCaseApi };
