import { useMemo, useState } from 'react';

import Form from '@anwb/poncho/components/form';
import Typography from '@anwb/poncho/components/typography';
import FormBlock from '@anwb/poncho/components/form-block';
import FormFieldInput from '@anwb/poncho/components/form-field-input';
import FormFieldEmail from '@anwb/poncho/components/form-field-email';
import FormFieldSurname from '@anwb/poncho/components/form-field-surname';
import FormFieldTelephone from '@anwb/poncho/components/form-field-telephone';
import { ButtonPrimary, ButtonTertiary } from '@anwb/poncho/components/button';
import Spinner from '@anwb/poncho/components/spinner';
import { PanelSpacer } from '@zama/panel-spacer';

import { Contractor, Debtor } from '../../../../types/contractors/contractor';
import { UserFormFooter } from '../../../UserManagement/common/UserForm/styles/user-form.styled';
import { RelationNumberInput } from './RelationNumberInput/RelationNumberInput';

import { useFetchDebtors } from './hooks/useFetchDebtors';
import { UserRightSelector } from './UserRightSelector/UserRightSelector';

type ContractorFormProps = {
	selectedItem?: Contractor;
	isLoading: boolean;
	onCancelHandler: () => void;
	onSubmitHandler: (formData: unknown) => void;
};

export const ContractorForm = ({
	selectedItem,
	isLoading,
	onCancelHandler,
	onSubmitHandler,
}: ContractorFormProps) => {
	const [debtors, setDebtors] = useState<Debtor[]>([
		{ relationNumber: '', name: '', contractNumbers: [] },
	]);

	useFetchDebtors(setDebtors, selectedItem);

	const memoizedTitle = selectedItem
		? useMemo(() => `${selectedItem.lastName}, ${selectedItem.firstName}`, [])
		: '';

	return (
		<Form onSubmit={onSubmitHandler}>
			<FormBlock label={memoizedTitle} border>
				<FormFieldInput
					placeholder="Bijv.: BMW Groep NL"
					label="Naam relatie"
					value={selectedItem?.name || ''}
					name="name"
					reference="name"
					required
				/>
				<RelationNumberInput debtors={debtors} setDebtors={setDebtors} />
				<UserRightSelector selectedItem={selectedItem} debtors={debtors} />
				<PanelSpacer />
				<Typography variant="component-title">Hoofdgebruiker</Typography>
				<FormFieldInput
					label="Voornaam"
					value={selectedItem?.firstName || ''}
					name="firstName"
					reference="first-name"
					required
				/>
				<FormFieldSurname
					label="Achternaam"
					value={selectedItem?.lastName || ''}
					name="lastName"
					reference="last-name"
					required
				/>
				<FormFieldEmail
					value={selectedItem?.email || ''}
					name="email"
					reference="email"
					dataTest="email"
					required
				/>
				<FormFieldTelephone
					phoneType="mobile"
					value={selectedItem?.phoneNumber || ''}
					name="phoneNumber"
					reference="phone-number"
					dataTest="phone-number"
					required
				/>
			</FormBlock>
			<UserFormFooter>
				<ButtonTertiary dataTest="button-cancel" onClick={onCancelHandler}>
					Annuleren
				</ButtonTertiary>
				{isLoading ? (
					<Spinner data-test="contractor-form-spinner" />
				) : (
					<ButtonPrimary type="submit">Opslaan</ButtonPrimary>
				)}
			</UserFormFooter>
		</Form>
	);
};
