import { SearchInvoice, SearchInvoiceLine } from '../types/invoices/invoice';
import { Pageable } from '../types/pageable';
import { InvoiceLineSort, InvoiceSort } from '../types/sort';
import {
	isMinValid,
	isMaxValid,
} from '../components/Common/RangeFormField/rangeValidator';
import { formatDate_YMD } from './formatDate';
import { parseAmount } from './parseAmount';

const rangeNumbers = [
	'minQuantity',
	'maxQuantity',
	'minUnitPrice',
	'maxUnitPrice',
	'minAmount',
	'maxAmount',
];

const isEmpty = <T>(v: T[] | T | undefined | null) =>
	v === null ||
	v === undefined ||
	(Array.isArray(v) && v.length === 0) ||
	false;

const appendParam = <T>(
	urlSearchParams: URLSearchParams,
	nameValue: [string, T]
) => {
	let name = nameValue[0];
	const value = nameValue[1];

	if (name === 'creditOrDebets') {
		name = 'creditOrDebet';
		if (Array.isArray(value) && value.length !== 1) {
			return urlSearchParams;
		}
	}

	if (name === 'assistanceTypes') {
		name = 'assistanceType';
		if (Array.isArray(value) && value.length !== 1) {
			return urlSearchParams;
		}
	}

	if (name === 'paymentStates') {
		name = 'paymentState';
		if (Array.isArray(value) && value.length !== 1) {
			return urlSearchParams;
		}
	}

	if (name === 'invoiceTypes') {
		name = 'invoiceType';
		if (Array.isArray(value) && value.length !== 1) {
			return urlSearchParams;
		}
	}

	if (rangeNumbers.includes(name)) {
		const parsedAmount = parseAmount(
			value as string,
			name === 'maxAmount' || name === 'maxQuantity' || name === 'maxUnitPrice'
		);

		if (Number.isNaN(parsedAmount)) {
			return urlSearchParams;
		}
		urlSearchParams.append(name, parsedAmount.toFixed(0));
		return urlSearchParams;
	}

	if (name === 'favorites') {
		if (Array.isArray(value) && value.length === 1) {
			urlSearchParams.append('favorites', 'true');
		}
		return urlSearchParams;
	}
	// format date, remove empty arrays, remove empty values
	if (value instanceof Date) {
		urlSearchParams.append(name, formatDate_YMD(value));
	} else if (Array.isArray(value)) {
		urlSearchParams.append(name, value.join());
	} else {
		urlSearchParams.append(name, String(value));
	}
	return urlSearchParams;
};

const isValidNumberRange = (min?: string, max?: string) =>
	isMinValid(min || '', max || '') && isMaxValid(max || '', max || '');

const validateAmountsInSearchInvoice = (query: SearchInvoice) => {
	const { minAmount, maxAmount } = query;
	return isValidNumberRange(minAmount, maxAmount);
};

const validateAmountsinSearchInvoiceLine = (query: SearchInvoiceLine) => {
	const {
		minAmount,
		maxAmount,
		minQuantity,
		maxQuantity,
		minUnitPrice,
		maxUnitPrice,
	} = query;
	return (
		isValidNumberRange(minAmount, maxAmount) &&
		isValidNumberRange(minQuantity, maxQuantity) &&
		isValidNumberRange(minUnitPrice, maxUnitPrice)
	);
};

const toAnyParameters = (
	query:
		| SearchInvoice
		| SearchInvoiceLine
		| Pageable<InvoiceSort>
		| Pageable<InvoiceLineSort>
) =>
	Object.entries(query)
		.filter((v) => !isEmpty(v[1]))
		.reduce(appendParam, new URLSearchParams())
		.toString();

export const toPageParams = (
	query: Pageable<InvoiceSort> | Pageable<InvoiceLineSort>
) => toAnyParameters(query);

export const toInvoiceQueryParams = (query: SearchInvoice) =>
	!validateAmountsInSearchInvoice(query) ? null : toAnyParameters(query);

export const toInvoiceLineQueryParams = (query: SearchInvoiceLine) =>
	!validateAmountsinSearchInvoiceLine(query) ? null : toAnyParameters(query);
