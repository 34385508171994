import styled, { css } from 'styled-components';

import Typography from '@anwb/poncho/components/typography';
import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';
import { pxToRem } from '@anwb/poncho/design-tokens/style-utilities';

const paymentStatusContainerStyles = ({ theme }: DefaultThemeProps) => css`
	display: flex;
	margin-bottom: -${pxToRem(theme.spacing['200'])};
`;

export const PaymentStatusContainer = styled.div`
	${paymentStatusContainerStyles};
`;

const statusOpenStyles = ({ theme }: DefaultThemeProps) => css`
	margin-right: ${pxToRem(theme.spacing['300'])};
`;

export const StatusOpen = styled(Typography).attrs({
	variant: 'body-text',
})`
	${statusOpenStyles};
`;
