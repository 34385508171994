import DescriptionList from '@anwb/poncho/components/description-list';
import List from '@anwb/poncho/components/list';
import Typography from '@anwb/poncho/components/typography';

import { AssistanceAction } from '../../../../../../types/cases/common-case';
import { formatDateTime } from '../../../../../../utils/formatDate';
import { ActionSubtitle } from '../CaseActionPanelLayout/styles/case-action-panel.styled';
import { CaseActionPanelLayout } from '../CaseActionPanelLayout/CaseActionPanelLayout';
import { getAssistanceActionIcon } from './utils/getAssistanceActionIcon';
import { AssistanceActionLocations } from './AssistanceActionLocations/AssistanceActionLocations';
import { ActionLocationDescriptionList } from './styles/action-location-description-list.styled';
import { getOutcomeListItems } from './utils/getOutcomeListItems';
import { getBookedOnContractsListItems } from './utils/getBookedOnContractsListItems';

type CaseActionPanelProps = {
	action: AssistanceAction;
};

export const PanelAssistanceAction = ({ action }: CaseActionPanelProps) => (
	<CaseActionPanelLayout
		actionType={action.actionType}
		iconVariant={getAssistanceActionIcon(action.code)}
		title={action.name}
		date={formatDateTime(action.actionDateTime)}
		status={action.actionStatus}
	>
		<Typography variant="component-subtitle">Hulpactie</Typography>

		{action.bookedOnContracts && (
			<>
				<ActionSubtitle>Dekkende overeenkomst</ActionSubtitle>
				<List items={getBookedOnContractsListItems(action.bookedOnContracts)} />
			</>
		)}

		{action.transportLocations?.length && (
			<>
				<ActionSubtitle>Transporteren voertuig</ActionSubtitle>
				<AssistanceActionLocations locations={action.transportLocations} />
			</>
		)}

		{action.replacementVehicle && (
			<>
				<ActionSubtitle>Vervangende voertuig</ActionSubtitle>
				<AssistanceActionLocations
					locations={action.replacementVehicle.locations}
				/>
				<ActionSubtitle>Overige voertuiginformatie</ActionSubtitle>
				<ActionLocationDescriptionList
					items={[
						{
							key: 'Categorie',
							value: action.replacementVehicle.category,
						},
					]}
				/>
			</>
		)}

		{action.outcome && (
			<>
				<ActionSubtitle>Afloop</ActionSubtitle>
				<DescriptionList items={getOutcomeListItems(action.outcome)} />
			</>
		)}
	</CaseActionPanelLayout>
);
