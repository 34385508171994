import { LegacyAccordion } from '@anwb/poncho/components/accordion';
import Typography from '@anwb/poncho/components/typography';

import {
	CaseActionsZustandStore,
	CasesZustandStore,
	InvoicesZustandStore,
	UsersZustandStore,
} from '../../../types/zustand';
import { FilterWrapper } from '../../../styles/filter.styled';
import { FilterType } from '../../../types/unions';
import { useFilter } from './hooks/useFilter';
import { FilterData } from './types/filter';

type FilterProps = {
	filterData: FilterData[];
	filterType: FilterType;
	store:
		| CasesZustandStore
		| InvoicesZustandStore
		| CaseActionsZustandStore
		| UsersZustandStore;
	clearFilters: () => void;
};

const Filter = ({
	filterData,
	store,
	filterType,
	clearFilters,
}: FilterProps) => {
	const { isOpen, getSection, toggleOpenClose } = useFilter(store);

	return (
		<div>
			<FilterWrapper>
				<Typography variant="component-title">Filters</Typography>
				<Typography
					variant="link-text"
					dataTest={`clear-filters-${filterType}`}
					onClick={clearFilters}
				>
					Filters wissen
				</Typography>
			</FilterWrapper>
			{filterData.length && (
				<LegacyAccordion
					reference={`${filterType}-accordion`}
					content={filterData
						.filter((filter) => filter.active)
						.map((filter, index) => ({
							title: filter.label,
							content: getSection(filter),
							open: isOpen(index),
						}))}
					handleChange={toggleOpenClose}
				/>
			)}
		</div>
	);
};

export default Filter;
