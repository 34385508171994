export const getAssistanceActionIcon = (code: string) => {
	switch (code) {
		case 'BERG':
		case 'TRVT':
		case 'TRIN':
			return 'car-transport';
		case 'RPOA':
			return 'repair-service';
		case 'RPIG':
		case 'RPTP':
			return 'car-repair';
		case 'TRSV':
			return 'car';
		case 'REVL':
			return 'airplane';
		case 'SECH':
			return 'repair-service';
		case 'RETX':
			return 'taxi';
		default:
			return 'wegenwacht';
	}
};
