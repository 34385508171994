import { ChangeEvent, useEffect, useState } from 'react';

import FormFieldCheckbox from '@anwb/poncho/components/form-field-checkbox';
import Typography from '@anwb/poncho/components/typography';
import { PanelSpacer } from '@zama/panel-spacer';

import { Debtor } from '../../../../../../types/contractors/contractor';
import { getDebtorName } from '../utils/getDebtorName';
import { fetchContracts } from './utils/fetchContracts';
import { Contract } from './types/contract';
import { getInitialCheckedValues } from './utils/getInitialCheckedValues';
import { getAllCheckedValues } from './utils/getAllCheckedValues';

type ContractSelectorProps = {
	debtors: Debtor[];
};
export const ContractSelector = ({ debtors }: ContractSelectorProps) => {
	const [checked, setIsChecked] = useState<string[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [contracts, setContracts] = useState<Contract[]>([]);

	useEffect(() => {
		fetchContracts(debtors.map((debtor) => debtor.relationNumber)).then(
			(contract) => {
				setContracts(contract);
				setIsChecked(getInitialCheckedValues(debtors));
			}
		);
	}, [debtors]);

	const checkAllHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked: allCheckedState } = event.target;
		setAllChecked(allCheckedState);
		setIsChecked(allCheckedState ? getAllCheckedValues(contracts) : []);
	};

	const checkHandler = (event: ChangeEvent<HTMLInputElement>, id: string) =>
		setIsChecked(
			event.target.checked
				? [...checked, id]
				: checked.filter((item) => item !== id)
		);

	return (
		contracts?.length > 0 && (
			<>
				<PanelSpacer />
				<Typography variant="component-title">Contractnummers</Typography>
				<FormFieldCheckbox
					label={allChecked ? 'Deselecteer alles' : 'Selecteer alles'}
					name="selectAll"
					reference="selectAll"
					onChange={checkAllHandler}
				/>
				{contracts.map((contract, index) => (
					<div key={`contract-${index}`}>
						<Typography variant="component-subtitle">
							{getDebtorName(contract.relationNumber, debtors)}
						</Typography>
						{contract.contractDetails.map((details, i) => (
							<FormFieldCheckbox
								key={`contract-number-${contract.relationNumber}-${i}-${String(checked.includes(details.contractNumber))}`}
								label={`${details.contractNumber} - ${details.contractName}`}
								name={`contract-number-${contract.relationNumber}-${i}`}
								reference={`contract-number-${contract.relationNumber}-${i}`}
								value={details.contractNumber}
								checked={checked.includes(details.contractNumber)}
								onChange={(event) =>
									checkHandler(event, details.contractNumber)
								}
							/>
						))}
					</div>
				))}
			</>
		)
	);
};
