import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { Page } from '../types/page';
import { UseReportsStore } from '../types/reports/reports-store';
import { DEFAULT_REPORT_SORTING } from '../constants/report-sorting';
import { Report } from '../types/reports/report';

export const INITIAL_PAGE: Page<Report> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseReportsStore = {
	filters: {
		query: '',
	},
	page: INITIAL_PAGE,
	sorting: DEFAULT_REPORT_SORTING.value,
	notification: undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	updateSorting: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = (
	state: UseReportsStore,
	newValue: string | undefined
) => ({
	filters: {
		...state.filters,
		query: newValue,
	},
	page: {
		...state.page,
		pageNumber: INITIAL_PAGE.pageNumber,
	},
});

export const useReportsStore = createWithEqualityFn<UseReportsStore>(
	(set) => ({
		...initialState,
		setPage: (page) => set({ page }),
		setQuery: (query) => set((state) => updateFilter(state, query)),
		updateSorting: (sorting): void => set({ sorting, page: initialState.page }),
		setNotification: (notification) => set({ notification }),
	}),
	shallow
);
