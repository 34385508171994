let reportDateTimeSequence = 1;

export const nextDate =
	(STARTING_DATE: Date, sequence = 1) =>
	() => {
		reportDateTimeSequence += sequence;
		const dateCopy = new Date(STARTING_DATE.getTime());
		dateCopy.setDate(dateCopy.getDate() + reportDateTimeSequence);
		return dateCopy;
	};
