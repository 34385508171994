import { ReactNode } from 'react';

import Spinner from '@anwb/poncho/components/spinner';

type SpinnerContainerType = {
	showSpinner: boolean;
	message: string;
	children: ReactNode;
};

const SpinnerContainer = ({
	showSpinner,
	message,
	children,
}: SpinnerContainerType) => {
	if (showSpinner) {
		return <Spinner message={message} />;
	}
	return children;
};

export default SpinnerContainer;
