import { ChangeEvent } from 'react';

import SortDropdown from '@anwb/poncho/components/sort-dropdown';

import { Sort } from '../../../types/sort';
import { AnySort } from '../../../types/unions';
import { SortBarWrapper } from './styles/sort-bar.styled';

type SortBarProps = {
	value: string;
	sortings: Readonly<Sort[]>;
	updateSorting: (sorting: AnySort) => void;
};

export const SortBar = ({ value, sortings, updateSorting }: SortBarProps) => (
	<SortBarWrapper $addMargin>
		<SortDropdown
			value={value}
			options={sortings.map((sort) => sort)}
			onChange={(e: ChangeEvent) =>
				updateSorting((e.target as HTMLSelectElement).value as AnySort)
			}
		/>
	</SortBarWrapper>
);
