import styled, { css } from 'styled-components';

const detailsHistoryHeaderStyles = () => css`
	flex-basis: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	line-height: 35px;
`;

export const DetailsHistoryHeader = styled.div`
	${detailsHistoryHeaderStyles};
`;
