import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@anwb/poncho/design-tokens/theme';

import { getCaseActionColors } from '../../../../../../utils/getCaseActionColors';

const checkboxContainerStyles = () => css`
	display: flex;
	justify-content: space-between;
`;

export const CheckboxContainer = styled.div`
	${checkboxContainerStyles};
`;

const checkboxWrapperStyles = () => css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-bottom: 1rem;
`;

export const CheckboxWrapper = styled.div`
	${checkboxWrapperStyles};
`;

const checkboxSectionContainerStyles = () => css`
	padding-left: 10px;
`;

export const CheckboxSectionContainer = styled.div`
	${checkboxSectionContainerStyles};
`;

const categoryIndicatorStyles = ({
	theme,
	$color,
}: DefaultThemeProps & { $color: string }) => css`
	width: 19px;
	height: 19px;
	border-radius: 9.5px;
	background-color: ${$color === 'orange' &&
	getCaseActionColors(theme, 'orange')};
	background-color: ${$color === 'yellow' &&
	getCaseActionColors(theme, 'yellow')};
	background-color: ${$color === 'blue' && getCaseActionColors(theme, 'blue')};
	margin: 3px -8px 0 0;
`;

export const CategoryIndicator = styled.div`
	${categoryIndicatorStyles};
`;
