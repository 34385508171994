import { ChangeEvent, useState } from 'react';

import { FormContainerFieldLabel } from '@anwb/poncho/components/form-container-field';
import FormFieldCheckbox from '@anwb/poncho/components/form-field-checkbox';

import { USER_RIGHTS_CATEGORIES } from '../../../../../constants/user-rights-categories';
import {
	Contractor,
	Debtor,
} from '../../../../../types/contractors/contractor';
import { UserRights } from '../../../../../types/users/user';
import { ContractSelector } from './ContractSelector/ContractSelector';
import { FlowIdTags } from './FlowIdTags/FlowIdTags';

type UserRightSelectorProps = {
	selectedItem?: Contractor;
	debtors: Debtor[];
};

export const UserRightSelector = ({
	selectedItem,
	debtors,
}: UserRightSelectorProps) => {
	const getUserRightCheckedState = (userRight: UserRights) =>
		selectedItem
			? selectedItem?.userRights.find((right) => right === userRight) !==
				undefined
			: false;

	const [vhvIsSelected, setVhvIsSelected] = useState<boolean>(
		getUserRightCheckedState('VHV_CASES')
	);
	const [phvIsSelected, setPhvIsSelected] = useState<boolean>(
		getUserRightCheckedState('PHV_CASES')
	);

	const userRightChangeHandler = (
		event: ChangeEvent<HTMLInputElement>,
		userRight: UserRights
	) => {
		const isChecked = event.target.checked;
		userRight === 'VHV_CASES' && setVhvIsSelected(isChecked);
		userRight === 'PHV_CASES' && setPhvIsSelected(isChecked);
	};

	return (
		<>
			<FormContainerFieldLabel>Rechten</FormContainerFieldLabel>
			{USER_RIGHTS_CATEGORIES.map((category, index) => (
				<FormFieldCheckbox
					key={`option-${index}`}
					label={category.label}
					name={category.code}
					reference={`option-${index}`}
					value={category.code}
					checked={getUserRightCheckedState(category.code)}
					onChange={(event) => userRightChangeHandler(event, category.code)}
				/>
			))}
			{vhvIsSelected && <FlowIdTags debtors={debtors} />}
			{phvIsSelected && <ContractSelector debtors={debtors} />}
		</>
	);
};
