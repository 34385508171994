import { formatFirstCaseToUpper } from '@anwb/poncho/utilities/formatters';

import { VhvCase } from '../../../types/cases/vhv-case';
import { DescriptionListItem } from '../../../types/poncho';
import { formatDateTime } from '../../../utils/formatDate';
import { formatMazStreet } from '../../../utils/formatMazStreet';
import { formatStatus, lookupVhvIncidentCategory } from './general';
import { generateMilage } from './generateMileage';
import { formatPolicyNumbers } from './formatPolicyNumbers';

export const createVhvDescriptionListGeneral = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Bedrijf/label',
		value: currentCase.caseSummary.label.name || '',
	},
	{
		key: 'Polisnummer(s)',
		value: formatPolicyNumbers(currentCase.caseSummary.policyNumbers) || '',
	},
	{
		key: 'Status',
		value: formatStatus(currentCase.caseSummary.status || ''),
	},
	{
		key: 'Type incident',
		value: lookupVhvIncidentCategory(
			currentCase.caseSummary.incidentCategory || ''
		),
	},
	{
		key: 'Meldingsmoment',
		value: formatDateTime(currentCase.caseSummary.reportDateTime),
	},
];

export const createVhvDescriptionListDriver = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Naam',
		value: currentCase.caseSummary.driver?.personName?.surname,
	},
	{
		key: 'Straatnaam',
		value: formatMazStreet(currentCase.caseSummary.driver?.address),
	},
	{
		key: 'Postcode',
		value: currentCase.caseSummary.driver?.address?.postalCode,
	},
	{
		key: 'Stad',
		value: currentCase.caseSummary.driver?.address?.city,
	},
];

export const createVhvDescriptionListVehicle = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Merk',
		value: currentCase.caseSummary.vehicle.brand || '',
	},
	{
		key: 'Model',
		value: currentCase.caseSummary.vehicle.model || '',
	},
	{
		key: 'Kleur',
		value: currentCase.caseSummary.vehicle.color || '',
	},
	{
		key: 'VIN',
		value: currentCase.caseSummary.vehicle.vin || '',
	},
	{
		key: 'Tellerstand',
		value: generateMilage(currentCase.caseSummary.vehicle.mileage) || '',
	},
];

export const createVhvDescriptionListInitialLocation = (
	currentCase: VhvCase
): DescriptionListItem[] =>
	currentCase.caseSummary.assistanceReportedLocation.address
		? [
				{
					key: 'Straatnaam',
					value: `${currentCase.caseSummary.assistanceReportedLocation.address.street || ''} ${currentCase.caseSummary.assistanceReportedLocation.address.houseNumber || ''}`,
				},
				{
					key: 'Postcode',
					value:
						currentCase.caseSummary.assistanceReportedLocation.address
							.postalCode || '',
				},
				{
					key: 'Stad',
					value:
						currentCase.caseSummary.assistanceReportedLocation.address.city ||
						'',
				},
				{
					key: 'Land',
					value:
						currentCase.caseSummary.assistanceReportedLocation.address
							.country || '',
				},
			]
		: [
				{
					key: 'Beschrijving',
					value:
						currentCase.caseSummary.assistanceReportedLocation.description
							?.locationMarker ||
						currentCase.caseSummary.assistanceReportedLocation.description
							?.road ||
						'',
				},
			];

export const createVhvDescriptionListFirstDiagnosis = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Schadesoort',
		value: currentCase.caseSummary.initialDiagnosis.kind || '',
	},
	{
		key: 'Storingsomschrijving',
		value: currentCase.caseSummary.initialDiagnosis.faultDescription || '',
	},
	{
		key: 'Aanvullende informatie',
		value: currentCase.caseSummary.initialDiagnosis.additionalDetails || '',
	},
];

export const createVhvDescriptionListTransportDestination = (
	currentCase: VhvCase
): DescriptionListItem[] => [
	{
		key: 'Straatnaam',
		value: currentCase.caseSummary.transportDestination
			? `${currentCase.caseSummary.transportDestination.location.address?.street || ''} ${currentCase.caseSummary.transportDestination.location.address?.houseNumber || ''}`
			: '',
	},
	{
		key: 'Postcode',
		value:
			currentCase.caseSummary.transportDestination?.location.address
				?.postalCode || '',
	},
	{
		key: 'Stad',
		value:
			currentCase.caseSummary.transportDestination?.location.address?.city ||
			'',
	},
	{
		key: 'Land',
		value:
			currentCase.caseSummary.transportDestination?.location.address?.country ||
			'',
	},
];

export const createVhvDescriptionListPassedOn = (
	currentCase: VhvCase
): DescriptionListItem[] =>
	currentCase.caseSummary.passedOnInfo?.passedOn
		? [
				{
					key: 'Gemeld op',
					value: currentCase.caseSummary.label?.name,
				},
				{
					key: 'Afwenteling',
					value: formatFirstCaseToUpper(
						currentCase.caseSummary.passedOnInfo?.passedOn
					),
				},
				{
					key: 'Afgewenteld op',
					value: currentCase.caseSummary.passedOnInfo?.contractNames?.join(','),
				},
				{
					key: 'Mate',
					value: formatFirstCaseToUpper(
						currentCase.caseSummary.passedOnInfo?.rate
					),
				},
			]
		: [
				{
					key: 'Afwenteling',
					value: 'Niet afgewenteld',
				},
				{
					key: 'Reden niet afgewenteld',
					value: currentCase.caseSummary.passedOnInfo?.reasonNotPassedOn,
				},
			];
