import {
	Action,
	ContactMoment,
	ContactRole,
} from '../../../../../types/cases/common-case';
import { SearchCaseActions } from '../../../../../types/cases/case-actions-store';
import { isInRange } from '../../../../../utils/isInRange';
import { matchesOption } from '../../../../../utils/matchesOptions';

const lookupContactMomentId = (role: ContactRole): string => {
	switch (role) {
		case 'HULPVRAAGMELDER':
			return 'CONTACT_MOMENT_WITH_HELP_REQUESTER';
		case 'CONTRACTANT':
			return 'CONTACT_MOMENT_WITH_CONTRACTOR';
		case 'DIENSTVERLENER':
			return 'CONTACT_MOMENT_WITH_SERVICE_PROVIDER';
		default:
			return '';
	}
};

const matchesRangeTerms = (
	action: Action,
	searchAction: SearchCaseActions
): boolean =>
	isInRange<Date>(
		action.sortingDate,
		searchAction.minDate,
		searchAction.maxDate
	);

const matchesOptionTerms = (
	action: Action,
	searchAction: SearchCaseActions
): boolean =>
	matchesOption(searchAction.status, action.status) &&
	(matchesOption(searchAction.actionType, action.actionType) ||
		matchesOption(
			searchAction.actionType,
			lookupContactMomentId((action as ContactMoment).role)
		));

export const doFilter = (
	action: Action,
	searchAction: SearchCaseActions
): boolean =>
	matchesRangeTerms(action, searchAction) &&
	matchesOptionTerms(action, searchAction);
