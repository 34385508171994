import { PhvCase } from '../../../types/cases/phv-case';
import { Action } from '../../../types/cases/common-case';
import { nextDate } from '../../utils/nextDate';

const phvCasesDB = [] as PhvCase[];

const STARTING_DATE = new Date('2022-01-01');

const DOB = new Date('1990-01-01');

let sequenceNumber = 1000000;

const nextSequenceNumber = () => {
	sequenceNumber += 1;
	return sequenceNumber.toString();
};

const nextReportDateTime = nextDate(STARTING_DATE);
const nextCaseActionDateTime = nextDate(STARTING_DATE, 0.25);

const personName = {
	initials: 'A.',
	suffix: 'van der',
	surname: 'Vries',
};

const address = {
	street: 'Street',
	houseNumber: '1',
	houseNumberSuffix: 'A',
	postalCode: '1234AB',
	city: 'City',
	country: 'Nederland',
};

const assistanceRequester = {
	personName,
};

const policyHolder = {
	personName,
	dob: DOB,
	address,
};

const contract = {
	policyHolder,
	policyNumber: '1234',
	contractName: 'ANWB Doorlopende reisverzekering',
};

const caseSummary = {
	caseNumber: '12345',
	assistanceType: 'PHV',
	status: 'OPEN',
	reportDateTime: STARTING_DATE,
	label: {
		id: '123',
		name: 'ANWB Doorlopende reisverzekering',
	},
	passedOn: 'UNKNOWN',
	assistanceRequester,
	assistanceCategory: 'PERSONEN_HULPVERLENING',
	recordCategory: 'ZIEKTE',
	country: 'Nederland',
	initialLocation: 'DOMESTIC',
	contract,
};

const assistanceAction = (): Action => ({
	title: 'Ziekte',
	icon: 'medicine',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'ASSISTANCE_ACTION',
	code: 'ZIEKTE',
	name: 'Ziekte',
	registrationDate: nextCaseActionDateTime(),
});

const assistanceAction2 = (): Action => ({
	...assistanceAction(),
	title: 'Ongeval',
	icon: 'ambulance',
	status: 'CLOSED',
	sortingDate: nextCaseActionDateTime(),
	code: 'ONGEVAL',
	name: 'Ongeval',
	registrationDate: nextCaseActionDateTime(),
});

const contactMoment = (): Action => ({
	title: 'Ziekte',
	icon: 'smartphone',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'CONTACT_MOMENT',
	direction: 'IN',
	media: 'TELEFOON',
	role: 'HULPVRAAGMELDER',
	text: 'Ik heb pijn',
});

const contactMoment2 = (): Action => ({
	...contactMoment(),
	sortingDate: nextCaseActionDateTime(),
	direction: 'OUT',
	role: 'CONTRACTANT',
	text: 'We hebben de pijn verholpen',
});

const offeredSolution = (): Action => ({
	title: 'Aangeboden oplossing',
	icon: 'information',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'OFFERED_SOLUTION',
	text: 'Oplossing',
});

const financialAgreement = (): Action => ({
	title: 'Financiële afspraak',
	icon: 'money',
	status: 'OPEN',
	sortingDate: nextCaseActionDateTime(),
	sortingDateType: 'type',
	actionType: 'FINANCIAL_AGREEMENT',
	text: 'Wij betalen alles, u betaalt niks',
});

const actions: Action[] = [];

[
	assistanceAction,
	assistanceAction2,
	contactMoment,
	contactMoment2,
	offeredSolution,
	financialAgreement,
].forEach((action) => {
	for (let i = 0; i < 5; i += 1) {
		actions.push(action());
	}
});

const phvCase = {
	isFavorite: false,
	caseSummary,
} as PhvCase;

const clone = <T>(value: T) => JSON.parse(JSON.stringify(value)) as T;

const nextCase = () => {
	const cloned = clone(phvCase);
	cloned.caseSummary.caseNumber = nextSequenceNumber();
	cloned.caseSummary.reportDateTime = nextReportDateTime();
	return cloned;
};

const generatePhvCases = () => {
	// default
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// text search
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.contract.policyNumber = '5678';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// status
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.status = 'CLOSED';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// initialLocations
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.initialLocation = 'ABROAD';
		newCase.caseSummary.country = 'Frankrijk';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// passedOn
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.passedOn = 'EXTERNAL';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// phvAssistanceCategory
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.assistanceCategory = 'ONGEVALS_HULPVERLENING';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// phvRecordCategory
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.recordCategory = 'FAMILIE_OMSTANDIGHEDEN';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.recordCategory = 'ONGEVAL';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// related contracts
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.contracts = [{ ...contract, policyNumber: '4321' }];
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// main case
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.mainCase = '0987';
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
	// related cases
	for (let i = 1; i <= 5; i += 1) {
		const newCase = nextCase();
		newCase.caseSummary.relatedCases = ['234', '567', '890'];
		newCase.actions = actions;
		phvCasesDB.push(newCase);
	}
};

generatePhvCases();

export const getAllCases = () => phvCasesDB.map((aCase) => aCase);
