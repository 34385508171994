import { Dispatch, SetStateAction } from 'react';

import FormFieldButton from '@anwb/poncho/components/form-field-button';
import FormFieldInput from '@anwb/poncho/components/form-field-input';

import { Debtor } from '../../../../../types/relations/relation';
import {
	AnwbRelationNumberInputRow,
	AnwbRelationNumberInputContainer,
	AnwbRelationTrashContainer,
	FullWidthButtonTertiary,
} from './styles/anwbRelationNumberInput.styled';
import { useValidateAnwbRelationNumber } from './hooks/useValidateAnwbRelationNumber';

type AnwbRelationNumberInputProps = {
	debtors: Debtor[];
	setDebtors: Dispatch<SetStateAction<Debtor[]>>;
};

export const AnwbRelationNumberInput = ({
	debtors,
	setDebtors,
}: AnwbRelationNumberInputProps) => {
	const validateAnwbRelationNumber = useValidateAnwbRelationNumber(
		debtors,
		setDebtors
	);

	const deleteAnwbRelationItem = (index: number) =>
		setDebtors((prevState) => [...prevState.filter((_item, i) => index !== i)]);

	return (
		<>
			{debtors.map((item, index) => (
				<AnwbRelationNumberInputRow
					key={`relation-number-row-${index}`}
					data-test="relation-number-row"
				>
					<AnwbRelationNumberInputContainer>
						<FormFieldInput
							key={`relation-number-${index}`}
							label="Relatienummer"
							value={item.anwbRelationNumber}
							name={`relation-number-${index}`}
							reference={`relation-number-${index}`}
							handlerValidationDynamic={validateAnwbRelationNumber}
							required
						/>
					</AnwbRelationNumberInputContainer>
					<FormFieldInput
						key={`debtor-${index}`}
						label="Debiteur"
						value={item.labels ? item.labels[0] : ''}
						name={`debtor-${index}`}
						reference={`debtor-${index}`}
						required
						disabled
					/>
					{debtors.length > 1 && (
						<AnwbRelationTrashContainer>
							<FormFieldButton
								data-test="button-delete"
								aria-label="Relatienummer verwijderen"
								color="secondary"
								icon="trash"
								// @ts-ignore
								onClick={() => deleteAnwbRelationItem(index)}
							/>
						</AnwbRelationTrashContainer>
					)}
				</AnwbRelationNumberInputRow>
			))}
			<FullWidthButtonTertiary
				icon="plus"
				onClick={() =>
					setDebtors((prevState) => [
						...prevState,
						{ anwbRelationNumber: '', labels: [''], contractNumbers: [] },
					])
				}
			>
				Voeg een relatienummer toe
			</FullWidthButtonTertiary>
		</>
	);
};
