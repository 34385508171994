import { parseAmount } from './parseAmount';
import { formatDate_YMD } from './formatDate';

const rangeNumbers = [
	'minQuantity',
	'maxQuantity',
	'minUnitPrice',
	'maxUnitPrice',
	'minAmount',
	'maxAmount',
];

const isEmpty = <T>(v: T[] | T | undefined | null) =>
	v === null ||
	v === undefined ||
	(Array.isArray(v) && v.length === 0) ||
	false;

const appendParam = <T>(
	urlSearchParams: URLSearchParams,
	nameValue: [string, T]
) => {
	const name = nameValue[0];
	const value = nameValue[1];

	if (rangeNumbers.includes(name)) {
		const parsedAmount = parseAmount(
			value as string,
			name === 'maxAmount' || name === 'maxQuantity' || name === 'maxUnitPrice'
		);

		if (Number.isNaN(parsedAmount)) {
			return urlSearchParams;
		}
		urlSearchParams.append(name, parsedAmount.toFixed(0));
		return urlSearchParams;
	}

	if (name === 'favorites') {
		if (Array.isArray(value) && value.length === 1) {
			urlSearchParams.append('favorites', 'true');
		}
		return urlSearchParams;
	}
	// format date, remove empty arrays, remove empty values
	if (value instanceof Date) {
		urlSearchParams.append(name, formatDate_YMD(value));
	} else if (Array.isArray(value)) {
		urlSearchParams.append(name, value.join());
	} else {
		urlSearchParams.append(name, String(value));
	}
	return urlSearchParams;
};

const toAnyParameters = <T>(query: T) =>
	Object.entries(query as ArrayLike<unknown>)
		.filter((v) => !isEmpty(v[1]))
		.reduce(appendParam, new URLSearchParams())
		.toString();

export const toPageParams = <T>(query: T) => toAnyParameters<T>(query);

export const toQueryParams = <T>(query: T) => toAnyParameters(query);
