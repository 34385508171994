import styled, { css } from 'styled-components';

import Typography from '@anwb/poncho/components/typography';

const panelTitleContainerStyles = () => css`
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: -0.5rem;
	column-gap: 0.8rem;
`;

export const PanelTitleContainer = styled.div`
	${panelTitleContainerStyles}
`;

const panelTitleStyles = () => css`
	margin-right: auto;
	margin-bottom: 0;
`;

export const PanelTitle = styled(Typography)`
	${panelTitleStyles}
`;
