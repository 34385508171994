import { useEffect, useState } from 'react';

import { useContractorsStore } from '../../../../store/contractorsStore';
import { UseContractorsStore } from '../../../../types/contractors/contractor-store';
import { Page } from '../../../../types/page';
import {
	Contractor,
	ContractorPageable,
	SearchContractor,
} from '../../../../types/contractors/contractor';
import { getContractors } from '../../../../utils/contractorService';
import { PreviousQuery } from '../../../../utils/previousQuery';
import {
	ERROR_API_CALL,
	ERROR_API_CALL_DESCRIPTION,
} from '../../../../constants/notifications';
import { PAGE_SIZE } from '../constants/page-size';

const pageable = (pageNumber: number) =>
	({
		pageNumber,
		pageSize: PAGE_SIZE,
	}) as ContractorPageable;

export const previousQuery = new PreviousQuery<SearchContractor>();

const useProcessContractors = () => {
	const [showSpinner, setShowSpinner] = useState(true);

	const { filters, setPage, setNotification, page } = useContractorsStore(
		(state: UseContractorsStore) => ({
			filters: state.filters,
			setNotification: state.setNotification,
			page: state.page,
			setPage: state.setPage,
		})
	);

	const loadPage = (pageNumber: number) => {
		setNotification(undefined);
		setShowSpinner(true);

		getContractors(filters, pageable(pageNumber))
			.then((newPage) => {
				setPage(newPage as Page<Contractor>);
			})
			.catch(() => {
				setNotification({
					type: 'error',
					title: ERROR_API_CALL,
					description: ERROR_API_CALL_DESCRIPTION,
				});
			})
			.finally(() => setShowSpinner(false));
	};

	useEffect(() => {
		if (!previousQuery.shouldUpdate(filters)) {
			setShowSpinner(false);
			return;
		}

		const timeout = setTimeout(() => {
			previousQuery.setPrevious(filters);
			loadPage(page.pageNumber);
		}, 750);
		return () => clearTimeout(timeout);
	}, [filters]);

	return { showSpinner, loadPage };
};

export default useProcessContractors;
