import { Theme } from '@anwb/poncho/design-tokens/theme';

type Color = 'orange' | 'yellow' | 'blue';

export const getCaseActionColors = (theme: Theme, color: Color) => {
	switch (color) {
		case 'orange':
			return theme.colors.action.textAction;
		case 'yellow':
			return theme.colors.accent.bgPanelPromotional;
		case 'blue':
			return theme.colors.highlight.iconButton;
		default:
			return '';
	}
};
