import { Route, Routes, Navigate } from 'react-router-dom';

import { InvoicesOverview } from './InvoicesOverview/InvoicesOverview';
import { InvoiceDetails } from './InvoiceDetails/InvoiceDetails';

export const Invoices = () => (
	<Routes>
		<Route index element={<InvoicesOverview />} />
		<Route path=":invoiceId" element={<InvoiceDetails />} />
		<Route path="/*" element={<Navigate to="/facturen" />} />
	</Routes>
);
