import styled, { css } from 'styled-components';

const noItemsFoundContainerStyles = () => css`
	display: flex;
	justify-content: center;
`;

export const NoItemsFoundContainer = styled.div`
	${noItemsFoundContainerStyles}
`;
