import styled, { css, DefaultThemeProps } from 'styled-components';

import Table from '@anwb/poncho/components/table';
import Typography from '@anwb/poncho/components/typography';

const highlightStyles = ({ theme }: DefaultThemeProps) => css`
	color: ${theme.colors.highlight.textLink};
`;

const tableContainerStyles = () => css`
	width: 100%;
	margin-bottom: 1.2rem !important;
`;

export const TableContainer = styled.div`
	${tableContainerStyles}
`;

const paddingHeaderStyles = () => css`
	padding-left: 0.25rem !important;
	padding-right: 0.25rem !important;
`;

export const IconHeaderCell = styled(Table.HeaderColumn)`
	align-items: center !important;
`;

export const IconTableCell = styled(Table.Column)`
	${paddingHeaderStyles}
`;

const headerCellStyles = ({ $alignRight }: { $alignRight?: boolean }) => css`
	${$alignRight
		? css`
				text-align: right;
			`
		: css`
				text-align: left;
			`};
`;

export const HeaderCell = styled(Table.HeaderColumn)`
	${headerCellStyles}
`;

const dateHeaderCellStyles = () => css`
	text-align: left;
	min-width: 8.2rem;
`;

export const DateHeaderCell = styled(Table.HeaderColumn)`
	${dateHeaderCellStyles}
`;

const tableCellStyles = ({ $alignRight }: { $alignRight?: boolean }) => css`
	${$alignRight
		? css`
				text-align: right;
			`
		: css`
				text-align: left;
			`};
`;

export const TableCell = styled(Table.Column)`
	${tableCellStyles}
`;

export const InvoiceNumberTableCell = styled(Table.Column)`
	text-align: left;
	${highlightStyles}
`;

const descriptionTableCellStyles = () => css`
	text-align: left;
`;

export const DescriptionTableCell = styled(Table.Column)`
	${descriptionTableCellStyles}
`;

const truncateStyles = ({ $lines }: { $lines: number }) => css`
	text-align: left;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: ${$lines};
	text-overflow: ellipsis;
`;

export const Truncate = styled.div`
	${truncateStyles}
`;

const invoiceNumberLink = () => css`
	text-decoration: none;
`;

export const InvoiceNumberLink = styled(Typography)`
	${invoiceNumberLink}
`;
