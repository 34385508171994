import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { CheckboxGroupTypeAll } from '../types/filters';
import { User } from '../types/users/user';
import { Page } from '../types/page';
import { UseUsersStore } from '../types/users/users-store';
import { DEFAULT_USER_SORTING } from '../constants/user-sorting';

export const INITIAL_PAGE: Page<User> = {
	pageNumber: 1,
	totalPages: 1,
	totalItems: 0,
	items: [],
};

const initialState: UseUsersStore = {
	accordionState: [],
	filters: {
		query: '',
		roles: [],
	},
	page: INITIAL_PAGE,
	sorting: DEFAULT_USER_SORTING.value,
	notification: undefined,
	setAccordionState: () => undefined,
	clearFilters: () => undefined,
	setPage: () => undefined,
	setQuery: () => undefined,
	updateCheckboxGroup: () => undefined,
	updateSorting: () => undefined,
	setNotification: () => undefined,
};

const updateFilter = (
	state: UseUsersStore,
	fieldName: CheckboxGroupTypeAll | 'query',
	newValue: string[] | string | undefined
) => ({
	filters: {
		...state.filters,
		[fieldName]: newValue,
	},
	page: {
		...state.page,
		pageNumber: INITIAL_PAGE.pageNumber,
	},
});

export const useUsersStore = createWithEqualityFn<UseUsersStore>(
	(set) => ({
		...initialState,
		setAccordionState: (accordionState) => set({ accordionState }),
		clearFilters: () =>
			set((state) => ({
				filters: initialState.filters,
				page: { ...state.page, pageNumber: 1 },
				sorting: DEFAULT_USER_SORTING.value,
			})),
		setPage: (page) => {
			set({ page });
		},
		setQuery: (query) => set((state) => updateFilter(state, 'query', query)),
		updateCheckboxGroup: (fieldName, newSelection) =>
			set((state) => updateFilter(state, fieldName, newSelection)),
		updateSorting: (sorting): void => set({ sorting, page: initialState.page }),
		setNotification: (notification) => set({ notification }),
	}),
	shallow
);
