const ASSISTANCE_ACTIONS = [
	{
		code: 'ADMINISTRATIEF_DOSSIER',
		name: 'Administratief dossier',
		assistanceType: ['PHV'],
	},
	{ code: 'ADVIES_DOSSIER', name: 'Adviesdossier', assistanceType: ['PHV'] },
	{
		code: 'DIEFSTALHULPVERLENING_BUITENLAND',
		name: 'Diefstalhulpverlening buiten Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'DIEFSTALHULPVERLENING_NEDERLAND',
		name: 'Diefstalhulpverlening in Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'FAMILIE_OMSTANDIGHEDEN',
		name: 'Familieomstandigheden',
		assistanceType: ['PHV'],
	},
	{
		code: 'FRAUDE_ONDERZOEK',
		name: 'Fraudeonderzoek',
		assistanceType: ['PHV'],
	},
	{ code: 'HELPDESK', name: 'Helpdesk', assistanceType: ['PHV', 'VHV'] },
	{
		code: 'MEDISCH_ELECTIEF',
		name: 'Medisch electief',
		assistanceType: ['PHV'],
	},
	{
		code: 'MEDISCHE_GARANTIE',
		name: 'Medische garantie',
		assistanceType: ['PHV'],
	},
	{ code: 'MEDISCH_SPOED', name: 'Medisch spoed', assistanceType: ['PHV'] },
	{
		code: 'ONGEVALSHULPVERLENING_BUITENLAND',
		name: 'Ongevalshulpverlening buiten Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'ONGEVALSHULPVERLENING_NEDERLAND',
		name: 'Ongevalshulpverlening in Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'PECHHULPVERLENING_BUITENLAND',
		name: 'Pechhulpverlening buiten Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'PECHHULPVERLENING_NEDERLAND',
		name: 'Pechhulpverlening in Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'PERSONENHULPVERLENING_BUITENLAND',
		name: 'Personenhulpverlening Buitenland',
		assistanceType: ['PHV'],
	},
	{
		code: 'PERSONENHULPVERLENING_NEDERLAND',
		name: 'Personenhulpverlening Nederland',
		assistanceType: ['PHV'],
	},
	{
		code: 'RUITBREUKHULPVERLENING_BUITENLAND',
		name: 'Ruitbreukhulpverlening buiten Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'RUITBREUKHULPVERLENING_NEDERLAND',
		name: 'Ruitbreukhulpverlening in Nederland',
		assistanceType: ['VHV'],
	},
	{
		code: 'UITVAL_BESTUURDER',
		name: 'Uitval bestuurder',
		assistanceType: ['PHV', 'VHV'],
	},
];

export { ASSISTANCE_ACTIONS };
