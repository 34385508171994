import { useNavigate } from 'react-router-dom';

import { formatPrice } from '@anwb/poncho/utilities/formatters';
import Table from '@anwb/poncho/components/table';

import { Invoice } from '../../../../types/invoices/invoice';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { formatDate } from '../../../../utils/formatDate';
import {
	DescriptionTableCell,
	IconTableCell,
	InvoiceNumberLink,
	InvoiceNumberTableCell,
	TableCell,
	Truncate,
} from '../../../../styles/table.styled';
import { ButtonIconSmall } from '../../../Common/ButtonIconSmall/ButtonIconSmall';

type InvoiceRowProps = {
	invoice: Invoice;
};

export const InvoiceRow = ({ invoice }: InvoiceRowProps) => {
	const description =
		invoice.insured && invoice.assistance?.assistanceType === 'PHV'
			? invoice.insured.policyNumber || '-'
			: invoice.description;

	const navigate = useNavigate();

	return (
		<Table.Row>
			<InvoiceNumberTableCell>
				<InvoiceNumberLink
					variant="link-text"
					onClick={() => navigate(`/facturen/${invoice.invoiceNumber}`)}
				>
					{invoice.invoiceNumber}
				</InvoiceNumberLink>
			</InvoiceNumberTableCell>
			<TableCell>{capitalizeFirstLetter(invoice.creditOrDebet)}</TableCell>
			<TableCell>{capitalizeFirstLetter(invoice.paymentState)}</TableCell>
			<DescriptionTableCell>
				<Truncate $lines={3}>{description}</Truncate>
			</DescriptionTableCell>
			<TableCell>{formatDate(invoice.invoiceDate)}</TableCell>
			<TableCell $alignRight>
				{formatPrice(invoice.amount, { euroSign: false })}
			</TableCell>
			<IconTableCell>
				<ButtonIconSmall icon="save" color="secondary" variant="on-dark" />
			</IconTableCell>
		</Table.Row>
	);
};
