import { ChangeEvent, useEffect, useState } from 'react';

import FormFieldCheckbox from '@anwb/poncho/components/form-field-checkbox';
import Typography from '@anwb/poncho/components/typography';
import { PanelSpacer } from '@zama/panel-spacer';

import { Debtor } from '../../../../../../types/relations/relation';
import { Contract } from '../../../../../../types/relations/contract';
import { getContracts } from '../../../../../../utils/relationService';
import { getLabels } from '../utils/getLabels';
import { getInitialCheckedValues } from './utils/getInitialCheckedValues';
import { getAllCheckedValues } from './utils/getAllCheckedValues';

type ContractSelectorProps = {
	debtors: Debtor[];
};

export const ContractSelector = ({ debtors }: ContractSelectorProps) => {
	const [checked, setIsChecked] = useState<string[]>([]);
	const [allChecked, setAllChecked] = useState(false);
	const [contracts, setContracts] = useState<Contract[]>([]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			getContracts(debtors.map((debtor) => debtor.anwbRelationNumber)).then(
				(contract) => {
					setContracts(contract.data as unknown as Contract[]);
					setIsChecked(getInitialCheckedValues(debtors));
				}
			);
		}, 750);
		return () => clearTimeout(timeout);
	}, [debtors]);

	const checkAllHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked: allCheckedState } = event.target;
		setAllChecked(allCheckedState);
		setIsChecked(allCheckedState ? getAllCheckedValues(contracts) : []);
	};

	const checkHandler = (event: ChangeEvent<HTMLInputElement>, id: string) =>
		setIsChecked(
			event.target.checked
				? [...checked, id]
				: checked.filter((item) => item !== id)
		);

	return (
		contracts?.length > 0 && (
			<>
				<PanelSpacer />
				<Typography variant="component-title">Contractnummers</Typography>
				<FormFieldCheckbox
					label={allChecked ? 'Deselecteer alles' : 'Selecteer alles'}
					name="selectAll"
					reference="selectAll"
					onChange={checkAllHandler}
				/>
				{contracts.map((contract, index) => {
					const labels = getLabels(contract.anwbRelationNumber, debtors);

					return (
						<div key={`contract-${index}`}>
							<Typography variant="component-subtitle">
								{labels ? labels[0] : ''}
							</Typography>
							{contract.contractDetails.map((details, i) => (
								<FormFieldCheckbox
									key={`contract-number-${contract.anwbRelationNumber}-${i}-${String(checked.includes(details.contractNumber))}`}
									label={`${details.contractNumber} - ${details.contractName}`}
									name={`contract-number-${contract.anwbRelationNumber}-${i}`}
									reference={`contract-number-${contract.anwbRelationNumber}-${i}`}
									value={details.contractNumber}
									checked={checked.includes(details.contractNumber)}
									onChange={(event) =>
										checkHandler(event, details.contractNumber)
									}
								/>
							))}
						</div>
					);
				})}
			</>
		)
	);
};
