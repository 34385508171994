import {
	CaseActionsZustandStore,
	CasesZustandStore,
	InvoicesZustandStore,
	UsersZustandStore,
} from '../../../../types/zustand';
import { MakeRequired } from '../../../../types/tools';
import CheckboxSection from '../components/CheckboxSection/CheckboxSection';
import DateRangeSection from '../components/DateRangeSection/DateRangeSection';
import NumberRangeSection from '../components/NumberRangeSection/NumberRangeSection';
import { FilterData } from '../types/filter';

export const useFilter = (
	store:
		| CasesZustandStore
		| InvoicesZustandStore
		| CaseActionsZustandStore
		| UsersZustandStore
) => {
	const {
		accordionState,
		filters,
		setAccordionState,
		updateCheckboxGroup,
		// @ts-ignore
		updateDateRange, // todo: this type does not belong to every store type
		// @ts-ignore
		updateNumberRange, // todo: this type does not belong to every store type
	} = store();

	const toggleOpenClose = (checked: number, state: 'open' | 'closed'): void => {
		const newAccordionState = [...accordionState];
		const index = newAccordionState.findIndex((item) => item === checked);
		state === 'open' && index === -1 && newAccordionState.push(checked);
		state === 'closed' && index !== -1 && newAccordionState.splice(index, 1);

		setAccordionState(newAccordionState);
	};

	const getSection = (group: FilterData) => {
		switch (group.type) {
			case 'checkboxGroup':
				return (
					<CheckboxSection
						group={group as MakeRequired<FilterData, 'options'>}
						filters={filters}
						updateCheckboxGroup={updateCheckboxGroup}
					/>
				);

			case 'dateRange':
				return (
					<DateRangeSection
						filters={filters}
						updateDateRange={updateDateRange}
					/>
				);

			case 'numberRange':
				return (
					<NumberRangeSection
						filters={filters}
						updateNumberRange={updateNumberRange}
					/>
				);

			default:
				return <div />;
		}
	};

	const isOpen = (index: number) => accordionState.includes(index);

	return { isOpen, getSection, toggleOpenClose };
};
