import List from '@anwb/poncho/components/list';
import { formatFirstCaseToUpper } from '@anwb/poncho/utilities/formatters';
import { formatPostalCode } from '@anwb/poncho/utilities/formatters/form';

import { DescriptionListItem } from '../../../types/poncho';
import { ContractPhv, PhvCase } from '../../../types/cases/phv-case';
import { formatDate, formatDateTime } from '../../../utils/formatDate';
import { formatMazStreet } from '../../../utils/formatMazStreet';
import { formatStatus, lookupPhvRecordCategory } from './general';
import { formatPersonName } from './formatPersonName';

export const createPhvDescriptionListGeneral = (
	currentCase: PhvCase
): DescriptionListItem[] => [
	{
		key: 'Verzekerd bij',
		value: currentCase.caseSummary.label.name,
	},
	{
		key: 'Status',
		value: formatStatus(currentCase.caseSummary.status),
	},
	{
		key: 'Meldingsmoment',
		value: formatDateTime(currentCase.caseSummary.reportDateTime),
	},
	...(currentCase.caseSummary.mainCase
		? [
				{
					key: 'Hoofddossier',
					value: currentCase.caseSummary.mainCase,
				},
			]
		: []),
	...(currentCase.caseSummary.relatedCases?.length
		? [
				{
					key: 'Nagekomen cases',
					value: <List items={currentCase.caseSummary.relatedCases} />,
				},
			]
		: []),
];

export const createPhvDescriptionListAssistanceRequester = (
	currentCase: PhvCase
): DescriptionListItem[] => [
	{
		key: 'Naam',
		value: formatPersonName(
			currentCase.caseSummary.assistanceRequester.personName
		),
	},
	{
		key: 'Geboortedatum',
		value:
			currentCase.caseSummary.assistanceRequester.dob &&
			formatDate(currentCase.caseSummary.assistanceRequester.dob),
	},
	{
		key: 'Straatnaam',
		value: formatMazStreet(currentCase.caseSummary.assistanceRequester.address),
	},
	{
		key: 'Postcode',
		value:
			currentCase.caseSummary.assistanceRequester.address.postalCode &&
			formatPostalCode(
				currentCase.caseSummary.assistanceRequester.address.postalCode
			),
	},
	{
		key: 'Stad',
		value:
			currentCase.caseSummary.assistanceRequester.address.city &&
			formatFirstCaseToUpper(
				currentCase.caseSummary.assistanceRequester.address.city
			),
	},
	{
		key: 'Land',
		value:
			currentCase.caseSummary.assistanceRequester.address.country &&
			formatFirstCaseToUpper(
				currentCase.caseSummary.assistanceRequester.address.country
			),
	},
];

export const createPhvDescriptionListInsuredData = (
	currentCase: PhvCase
): DescriptionListItem[] => [
	{
		key: 'Polisnummer',
		value: currentCase.caseSummary.contract.policyNumber,
	},
	{
		key: 'Contractnaam',
		value: currentCase.caseSummary.contract.contractName,
	},
	{
		key: 'Dossiersoort',
		value:
			currentCase.caseSummary.recordCategory &&
			lookupPhvRecordCategory(currentCase.caseSummary.recordCategory),
	},
	{
		key: 'Land incident',
		value: currentCase.caseSummary.country,
	},
];

export const createPhvDescriptionListSecondaryInsurances = (
	contract: ContractPhv
): DescriptionListItem[] => [
	{
		key: 'Polisnummer',
		value: contract.policyNumber,
	},
	{
		key: 'Contractnaam',
		value: contract.contractName,
	},
];
