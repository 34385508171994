import { TransportLocation } from '../../../../../../../types/cases/common-case';
import { ActionLocationDescriptionList } from '../styles/action-location-description-list.styled';
import { getAssistanceActionLocationItems } from './utils/getAssistanceActionLocationItems';

type AssistanceActionLocationsProps = {
	locations: TransportLocation[];
};

export const AssistanceActionLocations = ({
	locations,
}: AssistanceActionLocationsProps) =>
	locations.map((location, index) => (
		<ActionLocationDescriptionList
			key={`action-location-${index}`}
			items={getAssistanceActionLocationItems(location)}
		/>
	));
