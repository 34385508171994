import Typography from '@anwb/poncho/components/typography';

import { OfferedSolution } from '../../../../../../types/cases/common-case';
import { formatDateTime } from '../../../../../../utils/formatDate';
import { CaseActionPanelLayout } from '../CaseActionPanelLayout/CaseActionPanelLayout';

export type CaseActionPanelProps = {
	action: OfferedSolution;
};

export const PanelOfferedSolution = ({ action }: CaseActionPanelProps) => (
	<CaseActionPanelLayout
		iconVariant="information"
		actionType={action.actionType}
		title="Aangeboden oplossing"
		date={formatDateTime(action.actionDateTime)}
	>
		{action.text && <Typography>{action.text}</Typography>}
	</CaseActionPanelLayout>
);
