import { TransportLocation } from '../../../../../../../../types/cases/common-case';
import { formatDateTime } from '../../../../../../../../utils/formatDate';
import { formatMazStreet } from '../../../../../../../../utils/formatMazStreet';
import { getAssistanceActionLocationType } from './getAssistanceActionLocationType';

export const getAssistanceActionLocationItems = (
	location: TransportLocation
) => [
	{
		key: 'Soort',
		value: getAssistanceActionLocationType(location.type),
	},
	{
		key: 'Datum',
		value: `${formatDateTime(location.locationDateTime)}${location.locationDateType === 'EXPECTED' ? ' (verwacht)' : ''}`,
	},
	{
		key: 'Straat',
		value: formatMazStreet(location.location?.address),
	},
	{
		key: 'Postcode, plaats',
		value:
			location.location?.address?.postalCode && location.location?.address?.city
				? `${location.location.address.postalCode} ${location.location.address.city}`
				: undefined,
	},
	{
		key: 'Land',
		value: location.location?.address?.country || undefined,
	},
];
