import {
	Action,
	AssistanceAction,
	ContactMoment,
	OfferedSolution,
} from '../../../../../../types/cases/common-case';

export function isAssistanceAction(obj: Action): obj is AssistanceAction {
	return (
		obj && typeof obj === 'object' && obj.actionType === 'ASSISTANCE_ACTION'
	);
}

export function isContactMoment(obj: Action): obj is ContactMoment {
	return obj && typeof obj === 'object' && obj.actionType === 'CONTACT_MOMENT';
}

export function isOfferedSolution(obj: Action): obj is OfferedSolution {
	return (
		obj && typeof obj === 'object' && obj.actionType === 'OFFERED_SOLUTION'
	);
}
