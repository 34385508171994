import axios from 'axios';

import { API_KEY, API_URL } from './constants/urls';
import { responseInterceptor } from './interceptors/responseInterceptor';
import { errorInterceptor } from './interceptors/errorInterceptor';

const api = axios.create({
	baseURL: API_URL,
	headers: {
		'content-type': 'application/json',
		'APIKEY': API_KEY,
	},
});

if (api) {
	api.interceptors.response.use(responseInterceptor, errorInterceptor);
}

export default api;
