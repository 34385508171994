import styled, { css } from 'styled-components';
import Typography from '@anwb/poncho/components/typography';

const phvInvoiceDetailsTitleStyles = () => css`
	padding-top: 1em;
`;

export const PhvInvoiceDetailsTitle = styled(Typography).attrs({
	variant: 'component-title',
})`
	${phvInvoiceDetailsTitleStyles}
`;
