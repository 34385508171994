import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';
import Table from '@anwb/poncho/components/table';

import {
	ContentHeader,
	DefaultContainer,
	OverviewContainer,
} from '../../styles/page-layout.styled';
import {
	HeaderCell,
	TableCell,
	TableContainer,
} from '../../styles/table.styled';
import { AnyPage, AnySort } from '../../types/unions';
import { UseReportsStore } from '../../types/reports/reports-store';
import { REPORT_SORTINGS } from '../../constants/report-sorting';
import { useReportsStore } from '../../store/reportsStore';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { SortBar } from '../Common/SortBar/SortBar';
import { CustomPagination } from '../Common/CustomPagination/CustomPagination';
import { NoItemsFound } from '../Common/NoItemsFound/NoItemsFound';
import { useProcessReports } from './hooks/useProcessReports';

export const Reports = () => {
	const { showSpinner, loadPage } = useProcessReports();

	const { filters, page, sorting, notification, setQuery, updateSorting } =
		useReportsStore((state: UseReportsStore) => ({
			filters: state.filters,
			page: state.page,
			sorting: state.sorting,
			notification: state.notification,
			setQuery: state.setQuery,
			updateSorting: state.updateSorting,
		}));

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Rapporten</ContentHeader>
				<OverviewContainer>
					<DefaultContainer>
						<Grid.Item>
							<SearchBar
								query={filters.query}
								placeHolder="Zoek op naam"
								searchBarType="report"
								setQuery={setQuery}
							/>

							<SortBar
								sortings={REPORT_SORTINGS}
								updateSorting={updateSorting as (sorting: AnySort) => void}
								value={sorting}
							/>

							{notification && (
								<Notification
									variant={notification.type}
									title={notification.title}
								>
									{notification.description}
								</Notification>
							)}

							<SpinnerContainer
								showSpinner={showSpinner}
								message="Rapporten worden geladen"
							>
								<TableContainer data-test="reports-table">
									<Table withOuterSpacing={false}>
										<Table.Header>
											<Table.Row>
												<HeaderCell>Naam</HeaderCell>
												<HeaderCell>Type</HeaderCell>
												<HeaderCell>Datum</HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body data-test="reports-table-body">
											{page.items.map((report, index) => (
												<Table.Row key={`report-${index}`}>
													<TableCell>{report.fileName}</TableCell>
													<TableCell>{report.type}</TableCell>
													<TableCell>{report.date}</TableCell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								</TableContainer>
								<CustomPagination
									page={page}
									setPage={(newPage: AnyPage) => loadPage(newPage.pageNumber)}
								/>
								<NoItemsFound show={!page.items.length}>
									Geen rapporten gevonden
								</NoItemsFound>
							</SpinnerContainer>
						</Grid.Item>
					</DefaultContainer>
				</OverviewContainer>
			</Grid.Item>
		</Grid>
	);
};
