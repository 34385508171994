import styled, { css } from 'styled-components';

const fieldWrapperStyles = () => css`
	>div: first-child {
		margin-bottom: 0.8rem !important;
	}
`;

export const FieldWrapper = styled.div`
	${fieldWrapperStyles};
`;
