import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { UseCasesStore } from '../../types/cases/cases-store';
import {
	CheckboxGroupTypeAll,
	DateRangeType,
	InitialFilterState,
} from '../../types/filters';
import { CASES_STORE_INITIAL_STATE } from '../constants/cases-stores-initial-state';
import { DEFAULT_CASE_SORTING } from '../../constants/case-sortings';

const updateFilter = (
	state: UseCasesStore,
	fieldName: DateRangeType | CheckboxGroupTypeAll | 'query',
	newValue: Date | number | string[] | string | undefined
) => ({
	filters: {
		...state.filters,
		[fieldName]: newValue,
	},
	page: CASES_STORE_INITIAL_STATE().page,
});

export const createCasesStore = (caseTypeSpecificFilters: InitialFilterState) =>
	createWithEqualityFn<UseCasesStore>(
		(set) => ({
			...CASES_STORE_INITIAL_STATE(caseTypeSpecificFilters),
			setActiveCase: (activeCase) => set({ activeCase }),
			setAccordionState: (accordionState) => set({ accordionState }),
			updateDateRange: (fieldName, minOrMaxDate) =>
				set((state) => updateFilter(state, fieldName, minOrMaxDate)),
			clearFilters: () =>
				set((state) => ({
					filters: CASES_STORE_INITIAL_STATE().filters,
					page: { ...state.page, pageNumber: 1 },
					sorting: DEFAULT_CASE_SORTING.value,
				})),
			setPage: (page) => set({ page }),
			setQuery: (query) => set((state) => updateFilter(state, 'query', query)),
			updateCheckboxGroup: (fieldName, newSelection) =>
				set((state) => updateFilter(state, fieldName, newSelection)),
			updateSorting: (sorting): void =>
				set({ sorting, page: CASES_STORE_INITIAL_STATE().page }),
			setNotification: (notification) => set({ notification }),
		}),
		shallow
	);
