import { ChangeEvent } from 'react';

import SortDropdown from '@anwb/poncho/components/sort-dropdown';

import { useInvoiceLinesStore } from '../../../../store/invoiceLinesStore';
import { InvoiceLineSort } from '../../../../types/sort';
import {
	DEFAULT_INVOICE_LINES_SORTING,
	INVOICE_LINES_SORTINGS,
} from '../../../../constants/invoice-lines-sorting';
import { SortBarWrapper } from '../../../Common/SortBar/styles/sort-bar.styled';

type InvoiceLinesSortBarProps = {
	addMargin: boolean;
};

export const InvoiceLinesSortBar = ({
	addMargin,
}: InvoiceLinesSortBarProps) => {
	const { sorting, updateSorting } = useInvoiceLinesStore((state) => ({
		sorting: state.sorting,
		updateSorting: state.updateSorting,
	}));
	return (
		<SortBarWrapper $addMargin={addMargin}>
			<SortDropdown
				value={sorting || DEFAULT_INVOICE_LINES_SORTING.value}
				options={INVOICE_LINES_SORTINGS.map((sort) => sort)}
				onChange={(e: ChangeEvent<Element>) =>
					updateSorting(
						(e.target as HTMLSelectElement).value as InvoiceLineSort
					)
				}
			/>
		</SortBarWrapper>
	);
};
