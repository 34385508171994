import styled, { css } from 'styled-components';

const containerStyles = () => css`
	margin-bottom: 2rem;
`;

export const Container = styled.div`
	${containerStyles}
`;

const mapContainerStyles = () => css`
	padding-top: 1rem;
`;

export const MapContainer = styled.div`
	${mapContainerStyles}
`;
