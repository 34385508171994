import Grid from '@anwb/poncho/components/grid';
import Notification from '@anwb/poncho/components/notification';

import { FilterContainer } from '../../../styles/filter.styled';
import {
	ContentHeader,
	OverviewContainer,
	TablePartContainer,
} from '../../../styles/page-layout.styled';
import { useInvoicesStore } from '../../../store/invoicesStore';
import { INVOICE_SORTINGS } from '../../../constants/invoice-sorting';
import { UseInvoicesStore } from '../../../types/invoices/invoices-store';
import { AnySort } from '../../../types/unions';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { SearchBar } from '../../Common/SearchBar/SearchBar';
import { SortBar } from '../../Common/SortBar/SortBar';
import { INVOICE_FILTER_DATA } from '../../Common/Filter/constants/invoice-filter-data';
import Filter from '../../Common/Filter/Filter';
import { InvoicesTable } from './InvoicesTable/InvoicesTable';
import useProcessInvoices from './hooks/useProcessInvoices';

export const InvoicesOverview = () => {
	const { showSpinner, loadPage } = useProcessInvoices();

	const {
		clearFilters,
		filters,
		sorting,
		notification,
		setQuery,
		updateSorting,
	} = useInvoicesStore((state: UseInvoicesStore) => ({
		filters: state.filters,
		clearFilters: state.clearFilters,
		sorting: state.sorting,
		notification: state.notification,
		setQuery: state.setQuery,
		updateSorting: state.updateSorting,
	}));

	return (
		<Grid columns={1} constrainedWidth>
			<Grid.Item>
				<ContentHeader>Facturen</ContentHeader>
				<OverviewContainer>
					<FilterContainer>
						<Filter
							store={useInvoicesStore}
							filterData={INVOICE_FILTER_DATA}
							filterType="invoices"
							clearFilters={clearFilters}
						/>
					</FilterContainer>
					<TablePartContainer>
						<Grid.Item>
							<SearchBar
								query={filters.query}
								placeHolder="Zoek op factuurnummer, polisnummer, casenummer, kenteken, VIN, geboortedatum, naam"
								searchBarType="invoice"
								setQuery={setQuery}
							/>

							<SortBar
								sortings={INVOICE_SORTINGS}
								updateSorting={updateSorting as (sorting: AnySort) => void}
								value={sorting}
							/>

							{notification && (
								<Notification
									variant={notification.type}
									title={notification.title}
								>
									{notification.description}
								</Notification>
							)}

							<SpinnerContainer
								showSpinner={showSpinner}
								message="Facturen worden geladen"
							>
								<InvoicesTable loadPage={loadPage} />
							</SpinnerContainer>
						</Grid.Item>
					</TablePartContainer>
				</OverviewContainer>
			</Grid.Item>
		</Grid>
	);
};
