import { ContactMedia } from '../../../../../../../types/cases/common-case';

export const getContactMomentIcon = (media: ContactMedia) => {
	switch (media) {
		case 'TELEFOON':
		case 'MOBIEL':
			return 'smartphone';
		case 'SMS':
			return 'smartphone-message';
		case 'EMAIL':
			return 'envelope';
		case 'FAX':
			return 'fax';
		default:
			return 'smartphone';
	}
};
