import { env } from '../../inject_env';

export const API_URL = env.PROXY_API_URL;
export const API_KEY = env.PROXY_API_KEY;

export const API_URL_INVOICES = `${API_URL}/invoices`;
export const API_URL_DOWNLOAD_INVOICE = `${API_URL}/invoices/download`;
export const API_URL_INVOICE_LINES = `${API_URL}/invoiceLines`;

export const API_URL_VHV_CASES = `${API_URL}/vhv`;
export const API_URL_PHV_CASES = `${API_URL}/phv`;

export const API_URL_CONTRACTS = `${API_URL}/business-relation/contracts`;
export const API_URL_LABEL = `${API_URL}/business-relation/label`;

export const API_URL_USERS = `${API_URL}/users`;
export const API_URL_USER = `${API_URL}/users/user`;
export const API_URL_KEY_USERS = `${API_URL}/users/key-users`;

export const API_URL_REPORTS = `${API_URL}/reports`;
