import { ReactNode } from 'react';

import Typography from '@anwb/poncho/components/typography';
import DescriptionList from '@anwb/poncho/components/description-list';
import Link from '@anwb/poncho/components/link';

import { DescriptionListItem } from '../../../../types/poncho';
import { Container, MapContainer } from './styles/description-block.styled';

type DescriptionListBlockProps = {
	title: string;
	items: DescriptionListItem[];
	googleMaps?: string;
	customComponent?: ReactNode;
};

const DescriptionListBlock = ({
	title,
	items,
	googleMaps,
	customComponent,
}: DescriptionListBlockProps) => {
	return (
		<Container>
			<Typography variant="component-subtitle">{title}</Typography>
			{customComponent && <Container>{customComponent}</Container>}
			<DescriptionList items={items} />
			{googleMaps && (
				<MapContainer>
					<Link icon="external-link" href={googleMaps}>
						In Google Maps bekijken
					</Link>
				</MapContainer>
			)}
		</Container>
	);
};

export default DescriptionListBlock;
