import { Page } from '../types/page';

import { API_URL_INVOICES } from '../constants/api';
import {
	Contractor,
	ContractorPageable,
	SearchContractor,
} from '../types/contractors/contractor';
import { mockedContractorApi } from '../mocks/contractors/contractorApi';
import { toPageParams, toQueryParams } from './toQueryParameters';

export const getContractors = (
	searchContractor: SearchContractor,
	pageable: ContractorPageable
): Promise<Page<Contractor>> => {
	const searchParams = toQueryParams<SearchContractor>(searchContractor);
	const pageParams = toPageParams<ContractorPageable>(pageable);
	const url = `${API_URL_INVOICES}?${pageParams}&${searchParams}`;
	console.info('api url', url); // todo: create actual api call
	return Promise.resolve(
		mockedContractorApi.getSelectedContractors(searchContractor, pageable)
	);
};
